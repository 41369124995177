import {
  ApplicationClass,
  ApplicationInterface,
} from '@/utils/models/Application';
import { baseApi } from '../base-api';
import { OrgGenericParams } from '../org/org-endpoints';
import { MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS } from '@/utils/constants';
import { appQuotaEndpoint } from '../quota/quota-endpoints';
import { mockDecrementQuota, mockIncrementQuota } from '../quota/quota-methods';
import {
  mockGetAppAfterEdition,
  mockListAppsAfterAdition,
  mockListAppsAfterDeletion,
  mockListAppsAfterEdition,
} from './application-methods';
import { FieldsData } from '@/utils/models/FieldType';

export interface AppGenericParams extends OrgGenericParams {
  appID: string;
}

export interface ListApplicationsParams extends OrgGenericParams {
  query?: string;
  pageSize?: number;
  marker?: number;
  filters?: FieldsData[];
}

export interface ListApplicationsResponse {
  apps: ApplicationClass[];
  total: number;
}

interface AddApplicationParams extends OrgGenericParams {
  data: {
    name: string;
    owners: string[];
    customer_tags: { k: string; v: string }[];
    propagate_tags: boolean;
    propagate_owners: boolean;
    update_existing_apis: boolean;
  };
}

export interface UpdateAppParams
  extends AppGenericParams,
    AddApplicationParams {}

export const applicationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    listApplications: build.query<
      ListApplicationsResponse,
      ListApplicationsParams
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { orgID, query, pageSize, marker, filters } = args;
        sessionStorage.setItem('listApplicationsArgs', JSON.stringify(args));

        const resource = 'app';
        const params: string[] = [`resource=${resource}`];
        if (pageSize) params.push(`size=${pageSize}`);
        if (marker) params.push(`marker=${marker}`);

        const url = `/organisations/${orgID}/search?${params.join('&')}`;
        const searchResult = await fetchWithBQ({
          url,
          method: 'POST',
          data: {
            search_value: query,
            resource,
            filters,
            sort: { order: 'desc' },
          },
        });

        const { app, total } = searchResult.data as {
          app: ApplicationInterface[];
          total: number;
        };

        return {
          data: {
            apps: app.map((app) => new ApplicationClass(app)),
            total,
          },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.apps.map(({ UUID }) => ({
                type: 'Applications' as const,
                id: UUID,
              })),
              { type: 'Applications', id: 'LIST' },
            ]
          : [{ type: 'Applications', id: 'LIST' }],
    }),
    getApplication: build.query<ApplicationClass, AppGenericParams>({
      query: ({ orgID, appID, supressErrors }) =>
        `/organisations/${orgID}/applications/${appID}${
          supressErrors ? '?ntae' : '' // ntae = not throw an error
        }`,
      transformResponse: (rawResult: { application: ApplicationInterface }) =>
        new ApplicationClass(rawResult.application),
      providesTags: (result, error, { appID }) => [
        { type: 'Applications', id: appID },
      ],
    }),
    addApplication: build.mutation<ApplicationClass, AddApplicationParams>({
      query: ({ orgID, data }) => ({
        url: `/organisations/${orgID}/applications`,
        method: 'POST',
        data,
      }),
      transformResponse: (rawResult: { application: ApplicationInterface }) =>
        new ApplicationClass(rawResult.application),
      async onQueryStarted({ orgID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const listApplicationsArgs = JSON.parse(
            sessionStorage.getItem('listApplicationsArgs') || '{}'
          );

          dispatch(
            applicationEndpoints.util.updateQueryData(
              'listApplications',
              listApplicationsArgs,
              (draft) =>
                mockListAppsAfterAdition({
                  draft,
                  app: response.data,
                })
            )
          );

          dispatch(
            appQuotaEndpoint.util.updateQueryData('app', { orgID }, (draft) =>
              mockIncrementQuota(draft)
            )
          );

          setTimeout(() => {
            dispatch(
              applicationEndpoints.util.invalidateTags([
                'Quotas',
                { type: 'Applications', id: 'LIST' },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    deleteApplication: build.mutation<void, AppGenericParams>({
      query: ({ orgID, appID }) => ({
        url: `/organisations/${orgID}/applications/${appID}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ orgID, appID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          const listApplicationsArgs = JSON.parse(
            sessionStorage.getItem('listApplicationsArgs') || '{}'
          );

          dispatch(
            applicationEndpoints.util.updateQueryData(
              'listApplications',
              listApplicationsArgs,
              (draft) =>
                mockListAppsAfterDeletion({
                  draft,
                  appUUID: appID,
                })
            )
          );

          dispatch(
            appQuotaEndpoint.util.updateQueryData('app', { orgID }, (draft) =>
              mockDecrementQuota(draft)
            )
          );

          setTimeout(() => {
            dispatch(
              applicationEndpoints.util.invalidateTags([
                'Quotas',
                { type: 'Applications', id: 'LIST' },
              ])
            );
          }, MILISECONDS_TO_INVALIDATE_TAGS_THREE_SECONDS);
        });
      },
    }),
    updateApplication: build.mutation<ApplicationClass, UpdateAppParams>({
      query: ({ orgID, appID, data }) => ({
        url: `/organisations/${orgID}/applications/${appID}`,
        method: 'PUT',
        data,
      }),
      transformResponse: (rawResult: { application: ApplicationClass }) =>
        rawResult.application,
      async onQueryStarted({ orgID, appID }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) => {
          const listApplicationsArgs = JSON.parse(
            sessionStorage.getItem('listApplicationsArgs') || '{}'
          );

          dispatch(
            applicationEndpoints.util.updateQueryData(
              'listApplications',
              listApplicationsArgs,
              (draft) =>
                mockListAppsAfterEdition({
                  draft,
                  app: response.data,
                })
            )
          );

          dispatch(
            applicationEndpoints.util.updateQueryData(
              'getApplication',
              { orgID, appID },
              (draft) => {
                return mockGetAppAfterEdition({
                  app: new ApplicationClass(response.data),
                });
              }
            )
          );
        });
      },
    }),
  }),
});

export const {
  useAddApplicationMutation,
  useDeleteApplicationMutation,
  useUpdateApplicationMutation,
  useGetApplicationQuery,
  useLazyGetApplicationQuery,
  useListApplicationsQuery,
  useLazyListApplicationsQuery,
} = applicationEndpoints;

export default applicationEndpoints;
