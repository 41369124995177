import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  dashboardFiltersSelectors,
  filterRemoved,
  filterUpdatedOne,
} from '@/components/Dashboard/DashboardFilters/dashboardFiltersSlice';
import DashboardFiltersCounter from '../DashboardFiltersCounter';
import Dropdown from '@/components/DataDisplay/Dropdown';
import { MdAdd } from 'react-icons/md';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ConditionType, FilterGroupType } from '@/utils/models/Filter';
import DashboardFiltersGroupsConditionalButton from './DashboardFiltersGroupsConditionalButton';
import { Modal } from 'antd';

interface DashboardFiltersGroupsConditionalsRowsProps {
  dark?: boolean;
  handleAddCondition?: (filter: FilterGroupType) => void;
  handleEditCondition?: (
    filter: FilterGroupType,
    condition: ConditionType
  ) => void;
  manuallyAddedFilters?: FilterGroupType[];
  whichLabel?: string;
  disable?: boolean;
  size?: 'small' | 'large';
  maxHeight?: string;
}

const DashboardFiltersGroupsConditionalsRows = ({
  dark = false,
  handleAddCondition,
  handleEditCondition,
  manuallyAddedFilters,
  whichLabel,
  disable = false,
  size = 'large',
  maxHeight = '40vh',
}: DashboardFiltersGroupsConditionalsRowsProps) => {
  const myFilters = useAppSelector(dashboardFiltersSelectors.selectAll);
  const dispatch = useAppDispatch();
  const filtersToUse = manuallyAddedFilters || myFilters;

  const handleFilterUpdate = ({ key: operator }: any, filterID: string) => {
    dispatch(
      filterUpdatedOne({
        id: filterID,
        changes: { operator },
      })
    );
  };

  const showDeleteConfirm = (filter: FilterGroupType) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this filter?',
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteFilter(filter);
        return Promise.resolve();
      },
    });
  };

  const handleDeleteFilter = (filter: FilterGroupType) => {
    dispatch(filterRemoved(filter.id));
  };

  return (
    <div className='overflow-auto p-1' style={{ maxHeight }}>
      {filtersToUse?.map((filter, index) => (
        <div
          key={filter.id}
          className={`flex justify-between items-center ${
            dark ? 'text-anti-flash' : 'text-first'
          } mb-2 rounded-sm`}
          style={{
            outline: `1px solid ${dark ? '#565861' : '#D9D9D9'}`,
          }}
        >
          <div className='flex items-center'>
            <div className='flex items-center px-2 flex-none w-[16rem]'>
              <DashboardFiltersCounter count={index + 1} dark={dark} />
              <div className='flex items-center justify-between flex-1'>
                <p className='ml-3 mr-2 my-0 py-0'>
                  {index === 0
                    ? `${whichLabel || 'Requests'} which`
                    : 'and which'}
                </p>
                <Dropdown
                  disabled={filter?.disabled || disable}
                  dark={dark}
                  menu={{
                    items: [
                      { key: 'exclude', label: 'Exclude' },
                      { key: 'include', label: 'Include' },
                    ],
                    onClick: (item) => handleFilterUpdate(item, filter.id),
                  }}
                  dropdownRender={(menu) => (
                    <div className={dark ? 'dropdown-content-dark' : ''}>
                      {menu}
                    </div>
                  )}
                  label={<span className='capitalize'>{filter.operator}</span>}
                  labelClasses='border-davys px-2 leading-loose focus:outline-none w-24'
                  labelStyle={{
                    borderWidth: '1px',
                    marginTop: '-1px',
                    marginLeft: '-1px',
                    borderRadius: '2px',
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: '-1px' }}>
              {filter?.groups?.map((condition) => (
                <DashboardFiltersGroupsConditionalButton
                  key={condition.id}
                  handleEditCondition={handleEditCondition}
                  dark={dark}
                  filter={filter}
                  condition={condition}
                  disable={disable}
                />
              ))}
            </div>
          </div>
          {!disable && (
            <div
              className={
                size === 'large'
                  ? 'flex items-center flex-none button-container'
                  : 'flex flex-col text-xs gap-1 mx-2 flex-none button-container'
              }
            >
              {!filter?.disabled && handleAddCondition && (
                <button
                  data-testid='add-condition'
                  onClick={(evt) => {
                    evt.preventDefault();
                    handleAddCondition(filter);
                  }}
                  className={`${
                    dark ? 'text-anti-flash' : 'text-first'
                  } focus:outline-none flex items-center ${
                    size === 'large' ? 'ml-2 mr-[20px]' : ''
                  } `}
                >
                  <MdAdd size={size === 'large' ? 16 : 14} className='mr-1' />{' '}
                  Add {size === 'large' && 'Condition'}
                </button>
              )}
              {!filter?.disabled && (
                <button
                  onClick={(evt) => {
                    evt.preventDefault();
                    showDeleteConfirm(filter);
                  }}
                  className={`${
                    dark ? 'text-anti-flash' : 'text-first'
                  } flex items-center mr-[17px] focus:outline-none delete-button`}
                >
                  <span
                    className='material-symbols-outlined mr-1'
                    style={{ fontSize: size === 'large' ? 16 : 14 }}
                  >
                    delete
                  </span>
                  Delete
                </button>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DashboardFiltersGroupsConditionalsRows;
