import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIconDark = (
  <LoadingOutlined
    onPointerEnterCapture={undefined}
    onPointerLeaveCapture={undefined}
    style={{ fontSize: 18, color: '#F2F4F6' }}
    spin
  />
);

const antIconLight = (
  <LoadingOutlined
    onPointerEnterCapture={undefined}
    onPointerLeaveCapture={undefined}
    style={{ fontSize: 18, color: '#1B222D' }}
    spin
  />
);

const Loader = ({
  spinning,
  dark = true,
}: {
  spinning: boolean;
  dark?: boolean;
}) => (
  <Spin spinning={spinning} indicator={dark ? antIconDark : antIconLight} />
);

export default Loader;
