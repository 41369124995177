import useQuery from '@/utils/hooks/useQuery';
import { useEffect } from 'react';
import withAuthenticationRequired from '@/utils/lib/firetail-auth/wrappers/withAuthenticationRequired';
import { INTEGRATION_GITHUB_SETUP_COMPLETE } from '@/utils/constants';
import FullScreenLoader from '@/components/Loader/FullScreenLoader';

const GithubInstallPage = () => {
  const query = useQuery();
  const code = query.get('code');
  const installID = query.get('installation_id');

  useEffect(() => {
    if (!code || !installID) return;

    const postMessageData = {
      key: INTEGRATION_GITHUB_SETUP_COMPLETE,
      message: JSON.stringify({ code, installID }),
    };

    window.opener.postMessage(postMessageData, '*');
  }, [code, installID]);

  return <FullScreenLoader />;
};

export default withAuthenticationRequired(GithubInstallPage);
