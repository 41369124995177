import { Select } from 'antd';

const ListFilterSelectField = ({
  value,
  onChange,
  options,
  label,
  placeholder,
  disabled,
}: {
  value: string | null;
  onChange: (value: string) => void;
  options: { label: string; value: string; disabled?: boolean }[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}) => {
  return (
    <div className='w-full'>
      {label && <p className='pb-2'>{label}</p>}
      <Select
        disabled={disabled}
        value={value}
        showArrow={false}
        onChange={onChange}
        placeholder={placeholder}
        optionFilterProp='children'
        filterOption={(input, option) =>
          (option!.label as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        style={{ width: '100%', minWidth: 210 }}
        options={options}
      />
    </div>
  );
};

export default ListFilterSelectField;
