import {
  useParams,
  useHistory,
  Switch,
  Route,
  Redirect,
  Link,
} from 'react-router-dom';
import { Badge, Breadcrumb, PageHeader, Result, Spin } from 'antd';
import Tokens from './tokens';
import logging from '../../logging';
import Requests from './requests';
import { useGetApplicationQuery } from '@/utils/services/application/application-endpoints';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';
import FindingsTab from './findings';
import { useGetApiQuery } from '@/utils/services/api/api-endpoints';
import List from '@/components/List';
import SpecificationPage from './specifications';
import ApiDashboard from './apiDashboard';
import details from './details';
import useRole from '@/utils/hooks/useRole';
import { ListTabType } from '@/components/List/ListTabs';
import actions from './actions';
import Code from './code';
import { getLogoPathByName } from '@/utils/constants/listOfImagesPaths';
import ApiTagsGroup from '@/components/Apis/ApiTagsGroup';

const Api = () => {
  const {
    hasPermissionToListApiToken,
    hasPermissionToListSpecification,
    hasPermissionToGetSpecification,
    hasPermissionToListFinding,
    hasPermissionToListActions,
  } = useRole();
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
  }>();
  const { data: organisation } = useGetOrganisationQuery({ orgID });
  const { data: application, isLoading: getAppicationIsLoading } =
    useGetApplicationQuery({
      orgID,
      appID,
    });
  const { data: api, isLoading: apiIsLoading } = useGetApiQuery({
    orgID,
    apiID,
  });
  const history = useHistory();

  const tabs: ListTabType[] = [];
  tabs.push({
    key: 'dashboard',
    tooltip: 'Dashboard refers to the API usage and performance.',
  });
  tabs.push({
    key: 'requests',
    tooltip: 'Requests refer to the HTTP requests made to the API.',
  });
  if (hasPermissionToListApiToken) {
    tabs.push({
      key: 'tokens',
      tooltip: `Tokens refer to the API tokens that can be used to connect integrations such as inventory scanning, logging, and FireTail's code libraries to this API.`,
    });
  }
  if (api?.isLoggingAvailable) {
    tabs.push({
      key: 'logging',
      tooltip: 'Logging refers to the AWS API Gateway logging with AWS Lambda.',
    });
  }
  if (hasPermissionToListSpecification) {
    tabs.push({
      key: 'specifications',
      tooltip: 'Specifications refer to the API definition, such as OpenAPI.',
    });
  }
  if (hasPermissionToListFinding) {
    tabs.push({
      key: 'findings',
      tooltip: 'Findings refer to the results of security scans and tests.',
    });
  }
  if (hasPermissionToListActions) {
    tabs.push({
      key: 'actions',
      isBeta: true,
      tooltip:
        'Actions refer to the automated tasks that can be performed on the API.',
    });
  }
  if (organisation?.showCodeToCloudFeature) {
    tabs.push({
      key: 'linked-resources',
      label: 'Linked Resources',
      tooltip:
        'Code refers to the linking of API entities such as Github repos and AWS API gateways.',
    });
  }
  tabs.push({
    key: 'details',
    tooltip:
      'Details refer to the API configuration, such as the API name and description.',
  });

  const isGithubRepo =
    api?.ft_integration?.platform?.toLowerCase() === 'github';

  const isGitlabRepo =
    api?.ft_integration?.platform?.toLowerCase() === 'gitlab';

  const PageHeaderCard = (
    <div className='p-1 bg-white mb-4 border'>
      {organisation && application && api && (
        <div className='pt-3 pl-4 mb-0'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/organisations/${orgID}`}>{organisation?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${orgID}/applications/${appID}`}>
                {application?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/organisations/${orgID}/applications/${appID}/apis/${apiID}`}
              >
                {api?.name}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      )}
      <PageHeader
        ghost={false}
        title={api?.name}
        subTitle={
          isGithubRepo
            ? 'Code Repository'
            : isGitlabRepo
              ? 'Code Repository'
              : 'API'
        }
        avatar={{
          src: isGithubRepo
            ? getLogoPathByName('github_repository')
            : isGitlabRepo
              ? getLogoPathByName('gitlab_repository')
              : api?.avatar,
        }}
        onBack={() => window.history.back()}
        tags={api ? [<ApiTagsGroup key={api.UUID} api={api} />] : []}
      />
    </div>
  );

  if (
    getAppicationIsLoading !== true &&
    apiIsLoading !== true &&
    (!application || !api)
  ) {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
      />
    );
  }

  return (
    <>
      <Spin spinning={apiIsLoading}>
        {api?.isMissingAccessLogging ? (
          <Badge.Ribbon
            text={
              <Link
                className='text-gray-700 hover:underline text-xs font-medium'
                to={`/organisations/${api?.api_orgUUID}/applications/${api?.api_appUUID}/apis/${api?.UUID}/logging`}
              >
                Warning: missing access logging
              </Link>
            }
            color='yellow'
          >
            {PageHeaderCard}
          </Badge.Ribbon>
        ) : (
          PageHeaderCard
        )}
      </Spin>
      <List.Tabs
        onChange={(key) =>
          history.replace(
            `/organisations/${orgID}/applications/${appID}/apis/${apiID}/${key}`
          )
        }
        tabs={tabs}
      />
      <Switch>
        <Route
          path='/organisations/:orgID/applications/:appID/apis/:apiID/dashboard'
          component={ApiDashboard}
        />
        <Route
          path='/organisations/:orgID/applications/:appID/apis/:apiID/requests'
          component={Requests}
        />
        {hasPermissionToListApiToken && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis/:apiID/tokens'
            component={Tokens}
          />
        )}
        <Route
          path='/organisations/:orgID/applications/:appID/apis/:apiID/logging'
          component={logging}
        />
        {hasPermissionToGetSpecification && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis/:apiID/specifications/:specificationID'
            component={SpecificationPage}
          />
        )}
        {hasPermissionToListSpecification && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis/:apiID/specifications'
            component={SpecificationPage}
          />
        )}
        <Route
          path='/organisations/:orgID/applications/:appID/apis/:apiID/findings/:findingID'
          component={FindingsTab}
        />
        {hasPermissionToListFinding && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis/:apiID/findings'
            component={FindingsTab}
          />
        )}
        {hasPermissionToListActions && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis/:apiID/actions/:actionID'
            component={actions}
          />
        )}
        {hasPermissionToListActions && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis/:apiID/actions'
            component={actions}
          />
        )}
        {organisation?.showCodeToCloudFeature && (
          <Route
            path='/organisations/:orgID/applications/:appID/apis/:apiID/linked-resources'
            component={Code}
          />
        )}
        <Route
          path='/organisations/:orgID/applications/:appID/apis/:apiID/details'
          component={details}
        />
        <Redirect
          from='/organisations/:orgID/applications/:appID/apis/:apiID'
          to='/organisations/:orgID/applications/:appID/apis/:apiID/dashboard'
        />
      </Switch>
    </>
  );
};

export default Api;
