import { ApplicationClass } from '@/utils/models/Application';
import { Descriptions, PageHeader, Tag } from 'antd';
import Item from '../List/Item';

const ApplicationDetails = ({
  application,
  extra,
}: {
  application?: ApplicationClass;
  extra?: React.ReactNode;
}) => {
  return (
    <div className='flex flex-col gap-6 bg-white'>
      {application && (
        <PageHeader
          subTitle='Application'
          title={
            <div className='flex flex-row'>
              <div>{application.name}</div>
              <Tag
                color={application?.status === 'active' ? 'green' : 'yellow'}
                className='uppercase ml-3 self-center'
              >
                {application?.status}
              </Tag>
            </div>
          }
          extra={extra}
        >
          <Descriptions bordered size='small' column={{ xs: 1, sm: 2 }}>
            <Descriptions.Item label='UUID'>
              <Item.CopyButton value={application.UUID} />
            </Descriptions.Item>
            <Descriptions.Item label='Created by'>
              {application.createdBy}
            </Descriptions.Item>
            <Descriptions.Item label='Creation Time'>
              {application.created}
            </Descriptions.Item>
            <Descriptions.Item label='Status'>
              <span className='capitalize'>{application.status}</span>
            </Descriptions.Item>
            {application.owners && application.owners.length > 0 && (
              <Descriptions.Item label='Owners'>
                {application.owners.map((owner) => {
                  return <Tag>{owner}</Tag>;
                })}
              </Descriptions.Item>
            )}
            {application.customer_tags &&
              application.customer_tags.length > 0 && (
                <Descriptions.Item label='Custom Tags'>
                  <div className='overflow-x-auto'>
                    <table className='min-w-full bg-white max-w-56'>
                      <thead>
                        <tr className='relative' style={{ top: '1px' }}>
                          <th className='pl-4 font-semibold text-gray-600 text-left border-b border-gray-400'>
                            Key
                          </th>
                          <th className='pl-4 font-semibold text-gray-600 text-left border-b border-gray-400'>
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {application.customer_tags?.map((tag, index) => (
                          <tr key={index} className='border-b'>
                            <td className='py-2 px-4 text-gray-700 border-r'>
                              {tag.k}
                            </td>
                            <td className='py-2 px-4 text-gray-700'>{tag.v}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Descriptions.Item>
              )}
          </Descriptions>
        </PageHeader>
      )}
    </div>
  );
};

export default ApplicationDetails;
