import { Button, Form, Modal as ModalAntd } from 'antd';
import { useEffect, useState } from 'react';
import Modal from '../Modal';
import { VscGithubAction } from 'react-icons/vsc';
import useRole from '@/utils/hooks/useRole';
import { RxTimer } from 'react-icons/rx';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  useDeleteActionMutation,
  useLazyGetActionQuery,
  useListActionAvailableIntegrationsQuery,
} from '@/utils/services/actions/actions-endpoints';
import { ActionClass } from '@/utils/models/Action';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DecisionItemType } from '../Modal/ModalDecision';
import ActionForm from './ActionForm';
import FullScreenLoader from '../Loader/FullScreenLoader';
import './ActionModal.css';
import ModalDocsLink from '../Modal/ModalDocsLink';

const ActionModal = ({
  open,
  onCloseModal,
}: {
  open: boolean;
  onCloseModal: () => void;
}) => {
  const {
    hasPermissionToDeleteActions,
    hasPermissionToCreateActions,
    hasPermissionToUpdateActions,
  } = useRole();
  const history = useHistory();
  const location = useLocation();
  const { apiID, orgID, actionID, appID } = useParams<{
    apiID: string;
    orgID: string;
    actionID?: string;
    appID: string;
  }>();

  const [actionTypeState, setActionTypeState] = useState<DecisionItemType>();

  const [actionDataState, setActionDataState] = useState<
    undefined | ActionClass
  >();

  const [form] = Form.useForm();

  const [
    getAction,
    { isLoading: isGetActionLoading, isFetching: isGetActionFetching },
  ] = useLazyGetActionQuery();

  const [deleteAction] = useDeleteActionMutation();

  const { data: actionIntegrations } = useListActionAvailableIntegrationsQuery(
    {
      orgID,
      appID,
      apiID,
    },
    { skip: !actionTypeState }
  );

  const actionsTypes: DecisionItemType[] = [
    {
      title: 'Event Driven Action',
      description: 'Set up an Action to trigger from a FireTail Event',
      value: 'api_event_action',
      icon: <VscGithubAction size={28} />,
    },
    {
      title: 'Scheduled Action',
      description: 'Set up an Action to trigger on a schedule',
      value: 'api_schedule_action',
      icon: <RxTimer size={28} />,
    },
  ];

  const handleCloseModal = () => {
    removeActionIDFromPathname();
    setActionTypeState(undefined);
    setActionDataState(undefined);
    onCloseModal();
  };

  const removeActionIDFromPathname = () => {
    if (actionID) {
      const pathname = location.pathname.split('/');
      pathname.pop(); // remove id
      history.replace({ ...location, pathname: pathname.join('/') });
    }
  };

  useEffect(() => {
    if (actionID) {
      getAction({ orgID, actionID })
        .unwrap()
        .then((action) => {
          setActionDataState(action);

          const actionType = actionsTypes.find(
            (at) => at.value === action.actionType
          );
          setActionTypeState(actionType);
        })
        .catch(() => removeActionIDFromPathname());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID, actionID, getAction]);

  const handleDeleteAction = (action: ActionClass) => {
    ModalAntd.confirm({
      title: `Are you sure you want to delete this action?`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: `Name: ${action?.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return deleteAction({ orgID, actionID: action.UUID })
          .unwrap()
          .then(() => {
            handleCloseModal();
          });
      },
    });
  };

  const handleDecisionMade = (decision: DecisionItemType) =>
    setActionTypeState(decision);

  return (
    <>
      <FullScreenLoader loading={isGetActionFetching || isGetActionLoading} />

      <Modal.Decision
        open={open}
        onClose={onCloseModal}
        handleDecisionMade={handleDecisionMade}
        title={'Choose an Action Type'}
        options={actionsTypes}
      />

      <ModalAntd
        maskStyle={{ backdropFilter: 'blur(5px)' }}
        style={{ top: 15 }}
        open={!!actionTypeState}
        wrapClassName='customActionModal'
        title={
          <div className='col-span-4 flex gap-3 items-center'>
            {actionTypeState?.icon}
            <div className='w-full'>
              <div className='flex flex-row items-center justify-between'>
                <p className='font-semibold text-lg'>
                  {actionTypeState?.title}
                </p>
                <ModalDocsLink link='https://www.firetail.io/docs/actions' />
              </div>
              <div className='flex justify-between items-center w-full'>
                <p className='text-gray-600 text-sm'>
                  {actionTypeState?.description}
                </p>
              </div>
            </div>
          </div>
        }
        okText='Submit'
        cancelText='Cancel'
        onCancel={handleCloseModal}
        width={'40%'}
        onOk={() => form.submit()}
        footer={
          <div className='flex justify-between my-2'>
            {actionDataState && hasPermissionToDeleteActions && (
              <Button
                onClick={() => handleDeleteAction(actionDataState)}
                danger
              >
                Delete
              </Button>
            )}
            <div className='w-full text-right'>
              <Button data-testid='close-button' onClick={handleCloseModal}>
                Close
              </Button>
              {((actionDataState && hasPermissionToUpdateActions) ||
                (!actionDataState && hasPermissionToCreateActions)) && (
                <Button
                  data-testid='submit-button'
                  onClick={() => form.submit()}
                  type='primary'
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        }
      >
        <ActionForm
          formInstance={form}
          action={actionDataState}
          customActionIntegrations={actionIntegrations?.custom}
          managedActionIntegrations={actionIntegrations?.managed}
          onSubmitted={handleCloseModal}
          actionType={actionTypeState}
        />
      </ModalAntd>
    </>
  );
};

export default ActionModal;
