import FieldType from '@/utils/models/FieldType';
import {
  Button,
  Empty,
  Form,
  Input,
  Modal as ModalAntd,
  Select,
  Spin,
  message,
} from 'antd';
import Modal from '../Modal';
import { isValidElement, useEffect, useState } from 'react';
import { FieldBuilder } from '../Form/Fields';
import { useHistory, useParams } from 'react-router-dom';
import {
  GeneratedSpec,
  useAddSpecificationMutation,
  useGenerateSpecificationMutation,
} from '@/utils/services/specification/specification-endpoints';
import prepareSpecificationToUpload from '@/utils/methods/prepareSpecificationToUpload';
import GenericDateTimePicker from '@/components/Generic/GenericDateTimePicker';
import moment from 'moment';
import yaml from 'js-yaml';
import { DecisionItemType } from './ModalDecision';
import { RxCode, RxUpload } from 'react-icons/rx';
import { Editor } from '@monaco-editor/react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { DownloadOutlined } from '@ant-design/icons';
import { useLazyGetApiQuery } from '@/utils/services/api/api-endpoints';
import { ApiClass } from '@/utils/models/Api';
import Split from 'react-split';
import useDynamicDateTimeQueryParam from '@/utils/hooks/QueryParam/useDynamicDateTimeQueryParam';

const SpecificationModal = ({
  showModal,
  confirmLoading,
  handleCloseModal,
  initValues,
  okText,
  disableGeneration,
}: {
  showModal: boolean;
  confirmLoading?: boolean;
  handleCloseModal: () => void;
  initValues?: object;
  okText?: string;
  disableGeneration?: boolean;
}) => {
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID?: string;
  }>();
  const ModalSteps = {
    DECISION: 'decision',
    GENERATE: 'generate',
    UPLOAD_FILE: 'upload_file',
  };
  const [form] = Form.useForm();
  const [uploadForm] = Form.useForm();
  const history = useHistory();
  const fieldBuilder = new FieldBuilder(form);
  const [currentSpecifcationPreview, setCurrentSpecificationPreview] =
    useState<GeneratedSpec | null>(null);
  const [resetUpload, setResetUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [api, setApi] = useState<ApiClass | null>(null);
  const [apiSpec, setApiSpec] = useState('');
  const [specFormat, setSpecFormat] = useState<'json' | 'yaml'>('json');
  const [currentStep, setCurrentStep] = useState(ModalSteps.DECISION);
  const [modalState, setModalState] = useState<DecisionItemType | null>(null);
  const [generatedSpecName, setGeneratedSpecName] = useState<string>('');
  const [generateSpec, { isLoading: generateSpecificationIsLoading }] =
    useGenerateSpecificationMutation();
  const [addSpecification, { isLoading: addSpecificationIsLoading }] =
    useAddSpecificationMutation();
  const [getApi, { data: apiData, isUninitialized }] = useLazyGetApiQuery();
  const { dateTime, loggingDurationQuery, setDateTime } =
    useDynamicDateTimeQueryParam();
  const { data: durationData } = loggingDurationQuery;

  useEffect(() => {
    form.resetFields();

    if (initValues && showModal) {
      form.setFieldsValue(initValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValues, showModal]);

  useEffect(() => {
    form.setFieldsValue({ specName: generatedSpecName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedSpecName]);

  useEffect(() => {
    const fetchApi = async () => {
      if (apiID && orgID && isUninitialized) {
        try {
          await getApi({ orgID, apiID }).unwrap();
        } catch (error) {
          console.error('Failed to fetch API:', error);
        }
      }
    };

    if (showModal) {
      fetchApi();
    }
  }, [apiID, orgID, getApi, isUninitialized, showModal]);

  useEffect(() => {
    if (apiData) {
      setApi(apiData);
    }
  }, [apiData]);

  const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';

  const getDatesFromMode = (value: number, retentionDays: number) => {
    const now = moment();
    let start = moment();
    let end = moment();

    if (value > 0) {
      start = now.subtract(value, 'seconds');
    }

    if (value === -1) {
      start = moment.max(start, now.subtract(1, 'day'));
    }

    const formattedStart = start.utc().format(dateFormat);
    const formattedEnd = end.utc().format(dateFormat);

    if (start.isBefore(now.subtract(retentionDays, 'days'))) {
      throw new Error(
        `Start date must be within the last ${retentionDays} days.`
      );
    }

    return {
      start: formattedStart,
      end: formattedEnd,
    };
  };

  const getDefaultSpecName = (apiName: string, start: any, end: any) => {
    const formatDate = (date: any) => moment(date).format('YYYY-MM-DD');
    return `OpenAPI spec ${apiName} ${formatDate(start)}-${formatDate(end)}`;
  };

  const prettifySpec = () => {
    try {
      let formattedSpec;
      if (specFormat === 'json') {
        const specObject = JSON.parse(apiSpec);
        formattedSpec = JSON.stringify(specObject, null, 2);
        setApiSpec(formattedSpec);
      }
    } catch (error: any) {
      message.error('Error prettifying specification: ' + error.message);
    }
  };

  const modalStates: DecisionItemType[] = [
    {
      title: 'Upload Specification',
      description: 'Upload a specification in .yml or .json format.',
      value: 'uploadFile',
      icon: <RxUpload size={28} />,
    },
  ];

  modalStates.push({
    title: 'Generate Specification',
    description: 'Generate a specification based on API request logs.',
    value: 'generate',
    icon: <RxCode size={28} />,
    isBeta: true,
    disabled: disableGeneration,
    disabledText: 'There are no request logs for this API.',
  });

  const downloadFile = () => {
    const filename = `specification.${specFormat}`;
    const mimeType = specFormat === 'json' ? 'application/json' : 'text/yaml';
    const blob = new Blob([apiSpec], { type: mimeType });
    const href = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(href);
  };

  const handleFormatChange = (newFormat: 'json' | 'yaml') => {
    if (newFormat === specFormat) return;

    try {
      const updatedSpec =
        newFormat === 'json'
          ? JSON.stringify(yaml.load(apiSpec), null, 2)
          : yaml.dump(JSON.parse(apiSpec));
      setApiSpec(updatedSpec);
      setSpecFormat(newFormat);
    } catch (error) {
      if (newFormat === 'json') {
        try {
          yaml.dump(JSON.parse(apiSpec));
        } catch (error) {
          if (error instanceof Error) {
            message.error('Error converting formats: ' + error.message);
          } else {
            message.error('An unknown error occurred while converting formats');
          }
        }
      }
      if (newFormat === 'yaml') {
        try {
          JSON.stringify(yaml.load(apiSpec), null, 2);
        } catch (error) {
          if (error instanceof Error) {
            message.error('Error converting formats: ' + error.message);
          } else {
            message.error('An unknown error occurred while converting formats');
          }
        }
      }
    }
  };

  const handleAntdClose = () => {
    uploadForm.resetFields();
    form.resetFields();
    setResetUpload(true);
    setModalState(null);
    setApiSpec('');
    setDateTime();
    handleCloseModal();
    setCurrentSpecificationPreview(null);
  };

  const handleDecisionClose = () => {
    uploadForm.resetFields();
    form.resetFields();
    setResetUpload(true);
    handleCloseModal();
  };

  const handleGenerateSpec = async () => {
    let startDate = dateTime?.start;
    let endDate = dateTime?.end;

    if (dateTime?.value && dateTime?.value !== -1) {
      const dates = getDatesFromMode(
        dateTime.value,
        durationData?.retention_days || 7
      );
      startDate = dates.start;
      endDate = dates.end;
    }

    if (apiID && startDate && endDate && api) {
      const specification = await generateSpec({
        apiID,
        appID,
        orgID,
        specData: {
          startDate,
          endDate,
        },
      }).unwrap();

      if (specification) {
        const defaultName = getDefaultSpecName(api?.name, startDate, endDate);
        setGeneratedSpecName(defaultName);
        setApiSpec(JSON.stringify(specification, null, 2));
        setCurrentSpecificationPreview(specification);
      }
    }
  };

  useEffect(() => {
    if (modalState?.value === 'generate' && dateTime) {
      handleGenerateSpec();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState, dateTime]);

  const handleUploadSpecification = async ({
    api_uuid,
    name,
    file,
  }: {
    api_uuid?: string;
    name: string;
    file: { fileList: { originFileObj: File }[] };
  }) => {
    if (file.fileList.length === 0) {
      message.error('File is required. Please select a file.');
      return;
    }

    const callback = async (specData: any) => {
      const specification = await addSpecification({
        orgID,
        appID,
        apiID: api_uuid || (apiID as string),
        name,
        specData,
      }).unwrap();
      if (specification) {
        history.push(
          `/organisations/${specification.collection_orgUUID}/applications/${specification.collection_appUUID}/apis/${specification.collection_apiUUID}/specifications/${specification.UUID}`
        );
      }
    };

    const { originFileObj } = file.fileList[0];
    setIsLoading(true);
    prepareSpecificationToUpload(originFileObj, callback).then(() => {
      setIsLoading(false);
      handleAntdClose();
      handleDecisionClose();
    });
  };

  const handleUploadGeneratedSpec = async () => {
    const mimeType =
      specFormat === 'json' ? 'application/json' : 'application/x-yaml';
    const fileExtension = specFormat === 'json' ? 'json' : 'yaml';
    const blob = new Blob([apiSpec], { type: mimeType });
    const file = new File([blob], `specification.${fileExtension}`, {
      type: mimeType,
    });

    const callback = async (specData: any) => {
      const specification = await addSpecification({
        orgID,
        appID,
        apiID: apiID as string,
        name: generatedSpecName,
        specData,
      }).unwrap();
      if (specification) {
        history.push(
          `/organisations/${specification.collection_orgUUID}/applications/${specification.collection_appUUID}/apis/${specification.collection_apiUUID}/specifications/${specification.UUID}`
        );
      }
    };

    setIsLoading(true);
    prepareSpecificationToUpload(file, callback).then(() => {
      setIsLoading(false);
      handleAntdClose();
      handleDecisionClose();
    });
  };

  const uploadSpecificationFormFields: FieldType[] = [
    {
      attribute: 'api_uuid',
      inputType: 'select',
      placeholder: 'my-api',
      name: 'API',
      sourceFrom: { resource: 'api' },
      validation: {
        required: true,
      },
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'name',
      name: 'Name',
      placeholder: 'my-specification',
      validation: { required: true },
      inputType: 'text',
      fieldSize: 'small',
      colSize: 'small',
    },
    {
      attribute: 'file',
      name: 'File',
      validation: { required: true },
      inputType: 'upload-file',
      description: 'JSON or YAML',
      resetUpload,
    },
  ];

  const formatSpecificationPreview = (
    currentSpecifcationPreview: GeneratedSpec | null
  ): GeneratedSpec => {
    if (!currentSpecifcationPreview || !currentSpecifcationPreview.paths) {
      return {} as GeneratedSpec;
    }

    const formattedSpec: GeneratedSpec = {
      components: currentSpecifcationPreview.components,
      info: currentSpecifcationPreview.info,
      openapi: currentSpecifcationPreview.openapi,
      paths: {},
      security: currentSpecifcationPreview.security,
      servers: currentSpecifcationPreview.servers,
    };

    Object.entries(currentSpecifcationPreview.paths).forEach(
      ([path, details]) => {
        formattedSpec.paths[path] = details;
      }
    );

    return formattedSpec;
  };

  useEffect(() => {
    const formattedApiSpec = formatSpecificationPreview(
      currentSpecifcationPreview
    );
    setApiSpec(JSON.stringify(formattedApiSpec, null, 2));
  }, [currentSpecifcationPreview]);

  const isApiSpecification = !apiID;

  if (!isApiSpecification) uploadSpecificationFormFields.shift();

  const formItems = uploadSpecificationFormFields.map((field) => {
    if (isValidElement(field)) return field;
    return fieldBuilder.getFormItem(field as FieldType);
  });

  const loading = addSpecificationIsLoading || generateSpecificationIsLoading;

  const uploadState = (
    <Form
      layout='vertical'
      form={uploadForm}
      onFinish={handleUploadSpecification}
    >
      <div
        className='flex flex-col gap-4 overflow-auto p-6'
        style={{
          maxHeight: '78vh',
          paddingLeft: '7rem',
          paddingRight: '7rem',
        }}
      >
        {formItems.map((field) => field)}
      </div>
    </Form>
  );

  const generateState = (
    <Spin spinning={loading}>
      <Form
        layout='vertical'
        form={form}
        onFinish={handleUploadGeneratedSpec}
        style={{ height: '75vh' }}
      >
        {currentSpecifcationPreview !== null ? (
          <>
            <div className='flex items-center space-x-2 mt-2 ml-7 mb-2'>
              <Input
                id='specName'
                onChange={(e) => setGeneratedSpecName(e.target.value)}
                value={generatedSpecName}
                placeholder='Enter specification name'
                className='w-1/4'
              />
              <Select
                defaultValue={specFormat}
                className='w-24 mr-2 self-center'
                onChange={handleFormatChange}
              >
                <Select.Option value='json'>JSON</Select.Option>
                <Select.Option value='yaml'>YAML</Select.Option>
              </Select>
              <Button
                icon={
                  <DownloadOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                onClick={downloadFile}
                className='self-center'
              />
              {specFormat === 'json' && (
                <Button onClick={prettifySpec}>Prettify</Button>
              )}
            </div>
            <Split
              sizes={[50, 50]}
              minSize={100}
              expandToMin={false}
              gutterSize={7}
              gutterAlign='center'
              snapOffset={30}
              dragInterval={1}
              direction='horizontal'
              cursor='col-resize'
              className='split'
            >
              <div style={{ width: '100%' }}>
                <Editor
                  height='100%'
                  width='100%'
                  language={specFormat}
                  value={apiSpec}
                  onChange={(newValue) => setApiSpec(newValue || '')}
                  theme='Active4D'
                />
              </div>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                }}
                onClick={(e) => e.preventDefault()}
              >
                <SwaggerUI spec={apiSpec} />
              </div>
            </Split>
          </>
        ) : (
          !generateSpecificationIsLoading && (
            <div className='py-6'>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span className='text-gray-500'>
                    There are no available logs to generate an OpenAPI
                    Specification.
                  </span>
                }
              />
            </div>
          )
        )}
      </Form>
    </Spin>
  );

  const handleUploadClick = () => {
    if (currentStep === ModalSteps.UPLOAD_FILE) {
      uploadForm.submit();
    }

    if (currentStep === ModalSteps.GENERATE) {
      form.submit();
    }
  };

  const getModalContent = () => {
    switch (currentStep) {
      case ModalSteps.DECISION:
        return null;
      case ModalSteps.GENERATE:
        return generateState;
      case ModalSteps.UPLOAD_FILE:
        return uploadState;
      default:
        return null;
    }
  };

  const generateModalTitle = () => {
    switch (modalState?.value) {
      case 'generate':
        return 'Generate API Spec from Traffic Logs';
      case 'upload':
        return 'Upload API Spec';
      default:
        return 'Upload/Generate Spec';
    }
  };

  const modalTitle = (
    <div className='flex flex-row items-center'>
      <div className='mr-4'>{generateModalTitle()}</div>
      {modalState?.value === 'generate' && (
        <GenericDateTimePicker
          dateFormat={dateFormat}
          excludeDurationOptions={['last-3-months']}
          hideTitle
          disabled={generateSpecificationIsLoading}
        />
      )}
    </div>
  );

  const modalFooter = (
    <div className='flex justify-end h-8'>
      <Button
        type='primary'
        onClick={handleUploadClick}
        disabled={
          loading ||
          isLoading ||
          (currentStep === ModalSteps.GENERATE &&
            currentSpecifcationPreview === null)
        }
      >
        Upload Spec
      </Button>
    </div>
  );

  const handleDecisionMade = (decision: DecisionItemType) => {
    setModalState(decision);

    switch (decision.value) {
      case 'generate':
        setCurrentStep(ModalSteps.GENERATE);
        break;
      case 'uploadFile':
        setCurrentStep(ModalSteps.UPLOAD_FILE);
        break;
    }
  };
  return (
    <>
      <Modal.Decision
        title={`${generateModalTitle()}`}
        options={modalStates}
        open={showModal}
        handleDecisionMade={handleDecisionMade}
        onClose={handleDecisionClose}
      />
      <ModalAntd
        maskStyle={{ backdropFilter: 'blur(5px)' }}
        style={{ top: 15 }}
        title={modalTitle}
        open={!!modalState}
        okText={okText}
        onOk={() => form.submit()}
        width={'100%'}
        confirmLoading={confirmLoading}
        onCancel={handleAntdClose}
        forceRender
        footer={modalFooter}
        bodyStyle={{
          height: '80vh',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {getModalContent()}
      </ModalAntd>
    </>
  );
};

export default SpecificationModal;
