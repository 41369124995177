import { ApiClass } from '@/utils/models/Api';
import Item from '../List/Item';
import { useHistory, useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import ApiTagsGroup from './ApiTagsGroup';

const ApisItemCard = ({ api }: { api: ApiClass }) => {
  const { appID } = useParams<{
    orgID: string;
    appID: string;
    apiID?: string;
  }>();
  const history = useHistory();

  const redirectTo = (subpage?: string) => {
    const baseUrl = `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}/apis/${api.UUID}`;
    history.push(subpage ? `${baseUrl}/${subpage}` : baseUrl);
  };

  return (
    <Item.Root key={api.key} onClick={() => redirectTo()} id={api.key}>
      {api.scanSource?.logo && (
        <span
          className='absolute top-1 right-1 border border-gray-100 shadow-md 
        rounded-full'
        >
          <Tooltip title={`Scanned via ${api.scanSource.label}`}>
            <img
              className='h-7 p-[3px] rounded-full bg-white'
              alt={api.scanSource.label}
              src={api.scanSource.logo}
            />
          </Tooltip>
        </span>
      )}

      <Item.Header>
        <div className='flex gap-1 items-center mb-1'>
          {!appID && api.application && (
            <div
              onClick={(evt) => {
                evt.stopPropagation();
                history.push(
                  `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}`
                );
              }}
            >
              <Tooltip title={api.application?.name}>
                <span
                  className='text-gray-600 text-xs font-normal 
      leading-tight tracking-wide break-all line-clamp-1 hover:underline'
                >
                  {api.application?.name}
                </span>
              </Tooltip>
            </div>
          )}
          <Item.Title tooltipTitle={api.name}>
            {!appID && api.application ? `/ ${api.name}` : api.name}
          </Item.Title>
        </div>

        <Item.SeveritiesOverview
          showAllSeverities={false}
          severities={api.severities}
          onClick={() => redirectTo('findings')}
        />
      </Item.Header>

      <ApiTagsGroup api={api} />

      <Item.Footer>
        <Item.LogoAndName
          logo={api.avatar}
          name={
            api.isAwsResource
              ? `${api.typeLabel} (${api.region_name})`
              : api.typeLabel
          }
          tooltipTitle={api.typeLabel}
        />

        <div className='self-center mt-2 flex flex-row'>
          <Item.Date
            createdFromNow={api.createdFromNow}
            created={api.created}
          />
        </div>
      </Item.Footer>
    </Item.Root>
  );
};

export default ApisItemCard;
