import { useEffect, useState } from 'react';
import { Modal, Button, Form, Select } from 'antd';
import SelectField from '../Form/FieldComponents/SelectField';
import { useForm } from 'antd/lib/form/Form';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import {
  useAddLinkedRepoMutation,
  useLazyListAvailableLinksQuery,
} from '@/utils/services/api/api-endpoints';
import DebounceSelectField from '../Form/DebounceSelectField';
import { getLogoPathByName } from '@/utils/constants/listOfImagesPaths';
import './linking.css';

const EntityLinkingModal = ({
  isModalOpen,
  onModalClose,
  onOk,
  isApi,
}: {
  isModalOpen: boolean;
  onModalClose: () => void;
  onOk: () => void;
  isApi?: boolean;
}) => {
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
  }>();
  const [form] = useForm();
  const [linkSelected, setLinkSelected] = useState<string[] | undefined>();
  const [linkRepos] = useAddLinkedRepoMutation();
  const [listLinks] = useLazyListAvailableLinksQuery();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    if (apiID) {
      form.setFieldsValue({ currentApi: apiID });
    }
  }, [apiID, form]);

  const handleSubmit = (data: any) => {
    linkRepos({
      apiID,
      appID,
      orgID,
      destinationID: data.apisToLink,
    })
      .unwrap()
      .then(() => {
        form.resetFields();
        setLinkSelected(undefined);
      });
  };

  const handleFetchOptions = async (query: string) => {
    const options = await listLinks(
      {
        orgID,
        appID,
        apiID,
        data: {
          search_value: query,
          filters: [{ field: 'appUUID', values: [appID] }],
        },
      },
      true
    ).unwrap();

    if (options.apis && options.apis.length > 0) {
      return options.apis.map((api) => ({
        label: api.name,
        avatar:
          api?.ft_integration?.platform?.toLowerCase() === 'github'
            ? getLogoPathByName('github_repository')
            : api?.ft_integration?.platform?.toLowerCase() === 'gitlab'
              ? getLogoPathByName('gitlab_repository')
              : api.avatar,
        value: api.UUID,
      }));
    }

    return [];
  };

  return (
    <Modal
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      style={{ top: 150 }}
      title='Link Resources'
      onCancel={() => {
        onModalClose();
        form.resetFields();
        setLinkSelected(undefined);
      }}
      footer={[
        <Button
          key='onok'
          onClick={() => {
            form.submit();
            onOk();
          }}
          type='primary'
          disabled={!linkSelected || linkSelected.length === 0}
        >
          OK
        </Button>,
      ]}
      width='80%'
      open={isModalOpen}
      className='entity-linking-modal'
    >
      <Form form={form} layout='vertical' onFinish={handleSubmit}>
        <div className='flex flex-row items-center justify-between'>
          <Form.Item
            name='currentApi'
            label={`${isApi ? 'API' : 'Code Repository'} Selected`}
            style={{ flex: 1, marginBottom: '10px' }}
          >
            <SelectField
              formInstance={form}
              field={{
                sourceFrom: { resource: 'api' },
                attribute: 'currentApi',
                disabled: true,
                colSize: 'extralarge',
                fieldSize: 'extralarge',
              }}
            />
          </Form.Item>

          <FaLongArrowAltRight size={30} className='mx-4 my-auto' />

          <Form.Item
            name='apisToLink'
            label={`${isApi ? 'Code Repository' : 'API'} to Link`}
            style={{ flex: 1, marginTop: '-10px' }}
          >
            <DebounceSelectField
              fetchOptions={handleFetchOptions}
              renderOptionLabel={(options) =>
                options.map((opt) => (
                  <Select.Option key={opt.value} value={opt.value}>
                    <div className='flex flex-row'>
                      <img
                        alt='API Avatar'
                        className='h-5 mr-2 self-center'
                        src={opt.avatar}
                      />
                      <div>{opt.label}</div>
                    </div>
                  </Select.Option>
                ))
              }
              mode='multiple'
              onChange={(selectedValues: any) => {
                if (selectedValues.length > 1) {
                  form.setFieldValue('apisToLink', [
                    selectedValues[selectedValues.length - 1],
                  ]);
                  setLinkSelected(selectedValues[selectedValues.length - 1]);
                } else {
                  form.setFieldValue('apisToLink', selectedValues);
                  setLinkSelected(selectedValues);
                }
              }}
              allowClear
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default EntityLinkingModal;
