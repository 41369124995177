import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ListApisPagination } from './api-endpoints';
import { ApiClass } from '@/utils/models/Api';
import { FieldsData } from '@/utils/models/FieldType';

export const getMockCacheListing = ({
  draft,
  api,
  filters,
}: {
  draft: MaybeDrafted<ListApisPagination>;
  api: ApiClass;
  filters?: FieldsData[] | undefined;
}) => {
  if (
    !filters ||
    filters.length === 0 ||
    filters.some((f) => f.field === 'appUUID')
  ) {
    draft.apis.unshift(api);
    draft.total += 1;
    draft.isEmpty = false;
  }

  return draft;
};

export const mockListApisAfterDeletion = ({
  draft,
  apiUUID,
}: {
  draft: MaybeDrafted<ListApisPagination>;
  apiUUID: string;
}) => {
  draft.apis = draft.apis.filter((api) => api.UUID !== apiUUID);
  draft.total -= 1;
  draft.isEmpty = draft.total === 0;
  return draft;
};

export const mockGetApiAfterEdition = ({
  api,
}: {
  api: ApiClass;
}): MaybeDrafted<ApiClass> => {
  return {
    ...api,
    created: api.created,
    createdFromNow: api.createdFromNow,
    mapDataToDownload: api.mapDataToDownload,
    type: api.type,
    avatar: api.avatar,
    typeLabel: api.typeLabel,
    isMissingAccessLogging: api.isMissingAccessLogging,
    isAwsResource: api.isAwsResource,
    isActive: api.isActive,
    isLoggingAvailable: api.isLoggingAvailable,
    scannedViaAWS: api.scannedViaAWS,
    scannedViaWiz: api.scannedViaWiz,
    scannedViaGitHub: api.scannedViaGitHub,
    scannedViaGCloud: api.scannedViaGCloud,
    scannedViaAzure: api.scannedViaAzure,
    scannedViaGitlab: api.scannedViaGitlab,
    scanSource: api.scanSource,
    tagsGroup: api.tagsGroup,
  };
};

export const mockListApisAfterEdition = ({
  draft,
  api,
}: {
  draft: MaybeDrafted<ListApisPagination>;
  api: ApiClass;
}) => {
  const index = draft.apis.findIndex((a) => a.UUID === api.UUID);
  if (index !== -1) {
    draft.apis[index] = api;
  }
  return draft;
};
