import { Link, useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Modal, PageHeader, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  useDeleteApplicationMutation,
  useGetApplicationQuery,
  useUpdateApplicationMutation,
} from '@/utils/services/application/application-endpoints';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';
import useRole from '@/utils/hooks/useRole';
import { useEffect, useState } from 'react';
import FormsModal from '@/components/Modal/FormsModal';
import FieldType from '@/utils/models/FieldType';
import ApplicationDetails from '@/components/Applications/ApplicationDetails';

const Settings = () => {
  const { hasPermissionToDeleteApplication, hasPermissionToUpdateApplication } =
    useRole();
  const { appID, orgID } = useParams<{ appID: string; orgID: string }>();
  const { data: application, isLoading: appicationIsLoading } =
    useGetApplicationQuery({
      orgID,
      appID,
    });
  const { data: organisation } = useGetOrganisationQuery({ orgID });
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [updateApp] = useUpdateApplicationMutation();
  const [deleteApplication] = useDeleteApplicationMutation();
  const history = useHistory();

  useEffect(() => {
    if ((!title || title === '') && application?.name && isEditOpen) {
      setTitle(application?.name);
    }

    if (!isEditOpen) {
      setTitle('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.name, isEditOpen]);

  const handleDeleteApplication = async () => {
    await deleteApplication({ orgID, appID });
    history.push(`/organisations/${orgID}/applications`);
  };

  const handleSubmit = (data: any) => {
    let dataToSubmit = { ...data };

    if (data.customer_tags && data.customer_tags.length > 0) {
      const tagsKV: { k: string; v: string }[] = [];

      data.customer_tags.forEach((obj: { key: string; value: string }) => {
        tagsKV.push({ k: obj.key, v: obj.value });
      });

      dataToSubmit.customer_tags = tagsKV;
    }

    updateApp({ appID, orgID, data: dataToSubmit }).unwrap();
    setIsEditOpen(false);
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this application?`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: `Name: ${application?.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleDeleteApplication();
      },
    });
  };

  const extra = [];
  if (hasPermissionToUpdateApplication) {
    extra.push(
      <Button
        key={(extra.length + 1).toString()}
        onClick={() => setIsEditOpen(true)}
      >
        Edit Application
      </Button>
    );
  }

  if (hasPermissionToDeleteApplication) {
    extra.push(
      <Button
        key={(extra.length + 1).toString()}
        onClick={() => showDeleteConfirm()}
        danger
      >
        Delete Application
      </Button>
    );
  }

  const editAppFormFields: FieldType[] = [
    {
      attribute: 'name',
      name: 'Application Name',
      placeholder: 'my-app',
      validation: {
        required: true,
        regex: `^[a-zA-Z0-9][a-zA-Z0-9-\\s]+[a-zA-Z0-9]$`,
        regex_description:
          'The name must be alphanumeric and between 3 and 128 characters.',
      },
      inputType: 'text',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'owners',
      name: 'Owners',
      inputType: 'multitext',
      validation: { maxSize: 20 },
    },
    {
      attribute: 'customer_tags',
      name: 'Custom Tags',
      inputType: 'map',
      validation: { max: 10 },
      update: true,
      value: {},
      addString: 'Tag',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'propagate_tags',
      name: 'Propagate Custom Tags',
      inputType: 'switch',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'propagate_owners',
      name: 'Propagate Owners',
      inputType: 'switch',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'update_existing_apis',
      name: 'Update Existing APIs',
      inputType: 'switch',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
  ];

  return (
    <Spin spinning={appicationIsLoading}>
      <FormsModal
        title='Edit Application'
        handleCloseModal={() => setIsEditOpen(false)}
        showModal={isEditOpen}
        handleSubmit={handleSubmit}
        fields={editAppFormFields}
        initValues={{
          name: application?.name,
          owners: application?.owners,
          customer_tags: application?.customer_tags,
          propagate_tags: application?.propagate_tags,
          propagate_owners: application?.propagate_owners,
        }}
      />
      <div className='p-1 bg-white mb-4 border'>
        {organisation && application && (
          <div className='pt-3 pl-4 mb-0'>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={`/organisations/${orgID}`}>{organisation?.name}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/organisations/${orgID}/applications/${appID}`}>
                  {application?.name}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        )}
        <PageHeader ghost={false} onBack={() => window.history.back()}>
          <ApplicationDetails application={application} extra={extra} />
        </PageHeader>
      </div>
    </Spin>
  );
};

export default Settings;
