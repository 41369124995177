import { useState } from 'react';
import { Form, Switch, Modal } from 'antd';
import FieldType from '@/utils/models/FieldType';
import { getFieldSize } from './const/functions';
import { FormInstance, Rule } from 'antd/lib/form';
import { HiOutlineSparkles } from 'react-icons/hi';
import './consent.css';

const ConsentField = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance: FormInstance<any>;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      setIsModalVisible(true);
    } else {
      formInstance.setFieldsValue({ [field.attribute]: false });
      setIsModalVisible(false);
    }
  };

  const handleOk = () => {
    formInstance.setFieldsValue({ [field.attribute]: true });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    formInstance.setFieldsValue({ [field.attribute]: false });
    setIsModalVisible(false);
  };

  const rules: Rule[] = [];

  return (
    <>
      <Form.Item
        key={field.attribute}
        name={field.attribute}
        label={
          <span className='flex flex-row'>
            <HiOutlineSparkles
              className='self-center mr-1 font-extralight'
              size={20}
            />
            {field.name}
          </span>
        }
        rules={rules}
        valuePropName='checked'
        tooltip={field.description}
        className={`${getFieldSize(field.fieldSize || 'small')}`}
      >
        <Switch
          className={`flex justify-start items-start`}
          onChange={handleSwitchChange}
          checked={formInstance.getFieldValue(field.attribute)}
        />
      </Form.Item>

      <Modal
        title='Terms of Service'
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Agree'
        cancelText='Cancel'
        className='consentModal'
        style={{ top: 15 }}
      >
        <span className='text-xl font-semibold'>
          Important Notice Regarding FireTail's AI Feature
        </span>

        <h2 className='text-lg my-2'>
          By enabling the AI feature, you acknowledge and agree to the
          following:
        </h2>

        <ul>
          <li className='my-3 ml-3 custom-bullets'>
            <span className='font-semibold'>Data Usage:</span> The AI feature
            will use customer data, which may include personal and sensitive
            information, to provide its services. This data will be processed by
            the provider of the AI feature in accordance with our privacy
            policy.
          </li>

          <li className='my-3 ml-3 custom-bullets'>
            <span className='font-semibold'>Accuracy of Results:</span> While we
            strive to provide accurate and reliable results, the AI feature may
            produce erroneous or inaccurate outcomes. The AI is a tool designed
            to assist with and enhance your use of FireTail's SaaS platform, but
            it should not be solely relied upon for critical functionality.
          </li>

          <li className='my-3 ml-3 custom-bullets'>
            <span className='font-semibold'>Liability:</span> We do not accept
            any liability for any errors or omissions in the information
            provided by the AI feature. You agree to use this tool at your own
            risk and understand that it is your responsibility to verify any
            results or information generated.
          </li>

          <li className='my-3 ml-3 custom-bullets'>
            <span className='font-semibold'>Consent:</span> By enabling this AI
            feature, you consent to the collection, processing, and use of your
            data as described in this disclaimer and our privacy policy. You can
            withdraw consent later by disabling the AI feature.
          </li>

          <li className='my-3 ml-3 custom-bullets'>
            <span className='font-semibold'>
              Modification and Termination:{' '}
            </span>
            We reserve the right to modify or terminate the AI feature service
            at any time without prior notice. Any changes to the disclaimer will
            be communicated to you.
          </li>
        </ul>
      </Modal>
    </>
  );
};

export default ConsentField;
