import {
  useAddTokenMutation,
  useDeleteTokenMutation,
  useListTokensQuery,
} from '@/utils/services/token/token-endpoints';
import List from '../List';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { TokenClass } from '@/utils/models/Token';
import Item from '../List/Item';
import FormsModal from '@/components/Modal/FormsModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import NewTokenModal from '@/components/Modal/NewTokenModal';
import Fields from '../Form/FieldComponents';
import useRole from '@/utils/hooks/useRole';
import { MdWarning } from 'react-icons/md';
import Billing from '@/components/Billing';
import {
  useGetApiTokenQuotasQuery,
  useGetAppTokenQuotasQuery,
} from '@/utils/services/quota/quota-endpoints';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';

const TokensListings = () => {
  const {
    hasPermissionToCreateApiToken,
    hasPermissionToCreateApplicationToken,
    hasPermissionToDeleteApiToken,
    hasPermissionToDeleteApplicationToken,
  } = useRole();
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID?: string;
  }>();
  const { query } = useSearchQueryParam();
  const { data: listTokensData, isLoading: isListTokensDataLoading } =
    useListTokensQuery({ orgID, appID, apiID, query });
  const [addToken, { isLoading: addTokenIsLoading }] = useAddTokenMutation();
  const [deleteToken, { isLoading: deleteTokenIsLoading }] =
    useDeleteTokenMutation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [newToken, setNewToken] = useState<TokenClass>();

  const isApiTokens = !!apiID;

  const { data: apiTokenQuota, isLoading: isApiQuotaLoading } =
    useGetApiTokenQuotasQuery({ orgID });
  const { data: appTokenQuota, isLoading: isAppQuotaLoading } =
    useGetAppTokenQuotasQuery({ orgID });

  const upgradeRequired =
    (isApiTokens &&
      apiTokenQuota &&
      (apiTokenQuota.current as number) >= (apiTokenQuota?.max as number)) ||
    (!isApiTokens &&
      appTokenQuota &&
      (appTokenQuota.current as number) >= (appTokenQuota?.max as number));

  const handleCreate = async (data: { name: string }) => {
    await addToken({ orgID, appID, apiID, data })
      .unwrap()
      .then((token) => setNewToken(token));
    handleCloseModal();
  };

  const handleCloseModal = () => setShowCreateModal(false);

  const handleOpenModal = () => {
    if (upgradeRequired) {
      setIsPlanModalOpen(true);
      return;
    }

    setShowCreateModal(true);
  };

  const handleDeleteToken = (token: TokenClass) =>
    deleteToken({ orgID, appID, apiID, tokenID: token.UUID });

  const showDeleteConfirm = (token: TokenClass) => {
    Modal.confirm({
      title: `Are you sure you want to delete this token?`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: `Name: ${token.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleDeleteToken(token);
      },
    });
  };

  const isLoading =
    deleteTokenIsLoading || isApiQuotaLoading || isAppQuotaLoading;

  const hasPermissionToCreate =
    (isApiTokens && hasPermissionToCreateApiToken) ||
    (!isApiTokens && hasPermissionToCreateApplicationToken);

  const hasPermissionToDelete =
    (isApiTokens && hasPermissionToDeleteApiToken) ||
    (!isApiTokens && hasPermissionToDeleteApplicationToken);

  const columns: ColumnsType<TokenClass> = [
    {
      title: 'Name',
      key: 'name',
      render: (_, token) => (
        <span className='text-base text-left'>{token.name}</span>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (_, token) => (
        <Tooltip title={token.created}>
          <span className='whitespace-nowrap'>{token.createdFromNow}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Actions',
      width: 100,
      render: (_, token) => (
        <Button onClick={() => showDeleteConfirm(token)} danger>
          Delete
        </Button>
      ),
    },
  ];

  if (!hasPermissionToDelete) {
    columns.pop();
  }

  return (
    <>
      <NewTokenModal token={newToken} onOk={() => setNewToken(undefined)} />

      <Billing.Modal
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
        titleText={`You have reached your limit of ${
          isApiTokens ? 'API' : 'app'
        } tokens`}
      />

      <FormsModal
        title={`Create ${isApiTokens ? 'API' : 'APP'} Token`}
        confirmLoading={addTokenIsLoading}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleCreate}
        showModal={showCreateModal}
        fields={[
          <Fields.InputText
            key='name'
            field={{
              attribute: 'name',
              name: 'Name',
              placeholder: 'my-token',
              validation: { required: true },
              inputType: 'text',
              fieldSize: 'extralarge',
              colSize: 'extralarge',
            }}
          />,
        ]}
      />

      <List.Root
        renderEmpty={
          hasPermissionToCreate
            ? {
                isEmpty: listTokensData?.length === 0 && !query,
                loading: isListTokensDataLoading,
                element: (
                  <List.Empty
                    title='Create a Token'
                    subtitle={
                      isApiTokens
                        ? `Create an API token to connect integrations such as inventory scanning, logging, and FireTail's code libraries to this API.`
                        : `Create an application token to connect integrations such as inventory scanning, logging, and FireTail's code libraries to this application.`
                    }
                    buttonTxt='Create Token'
                    handleClick={handleOpenModal}
                  />
                ),
              }
            : undefined
        }
      >
        <List.Header>
          <List.Search placeholder='Search tokens' />
          <List.Settings>
            {hasPermissionToCreate && (
              <Button onClick={handleOpenModal} type='primary'>
                <div className='flex flex-row'>
                  {`Create ${isApiTokens ? 'API' : 'APP'} Token`}
                  {upgradeRequired && (
                    <Tooltip title='Upgrade Required'>
                      <MdWarning
                        style={{ color: '#FFE25E' }}
                        size={16}
                        className='ml-2 self-center'
                      />
                    </Tooltip>
                  )}
                </div>
              </Button>
            )}

            <List.QuotaIndicator
              quota={isApiTokens ? apiTokenQuota : appTokenQuota}
              tooltipText={`You are currently using ${
                isApiTokens
                  ? (apiTokenQuota?.current as number)
                  : (appTokenQuota?.current as number)
              } out of your ${
                isApiTokens
                  ? (apiTokenQuota?.max as number)
                  : (appTokenQuota?.max as number)
              } allocated tokens.`}
            />
          </List.Settings>
          <List.Segmented />
        </List.Header>

        <List.Pagination dataSource={listTokensData}>
          <List.Grid
            item={{
              render: (token: TokenClass) => (
                <Item.Root
                  key={token.key}
                  id={token.key}
                  deleteOnClick={
                    hasPermissionToDelete
                      ? () => showDeleteConfirm(token)
                      : undefined
                  }
                  backgroundColor={'bg-white'}
                >
                  <Item.Header>
                    <Item.Title>{token.name}</Item.Title>
                    <Item.Date
                      createdFromNow={token.createdFromNow}
                      created={token.created}
                    />
                  </Item.Header>
                </Item.Root>
              ),
            }}
            loading={isLoading}
          />

          <List.Table columns={columns} loading={isLoading} />
        </List.Pagination>
      </List.Root>
    </>
  );
};

export default TokensListings;
