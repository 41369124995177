import { Form } from 'antd';
import useRole from '@/utils/hooks/useRole';
import { FormInstance } from 'antd/es/form';
import { IntegrationClass } from '@/utils/models/Integration';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import { ReactNode, useEffect, useMemo } from 'react';
import { FieldBuilder } from '../Form/Fields';
import FieldType from '@/utils/models/FieldType';
import {
  ListMyIntegrationParams,
  useAddIntegrationMutation,
  useEditMyIntegrationMutation,
} from '@/utils/services/integration/integration-endpoints';
import { useParams } from 'react-router-dom';

interface IntegrationsModalProps {
  formInstance: FormInstance<any>;
  onSubmit: (
    myIntegrationID: string,
    myIntegrationData: IntegrationClass
  ) => void;
  myIntegrationData?: IntegrationClass;
  integrationAvailableManually?: IntegrationAvailableClass;
  params?: Partial<ListMyIntegrationParams>;
}

const IntegrationForm = ({
  formInstance,
  onSubmit,
  myIntegrationData,
  integrationAvailableManually,
  params,
}: IntegrationsModalProps) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { hasPermissionToUpdateIntegration } = useRole();

  const isEditing = !!myIntegrationData;

  const [addIntegration] = useAddIntegrationMutation({
    fixedCacheKey: 'addIntegration',
  });

  const [editIntegration] = useEditMyIntegrationMutation({
    fixedCacheKey: 'editIntegration',
  });

  const integrationAvailable = useMemo(() => {
    const available =
      integrationAvailableManually || myIntegrationData?.available;

    if (!available) return available;

    // TODO: Fix the issue on the backend and remove this workaround
    // This is a workaround to fix the issue with the map input type
    // The backend is returning the map input type as an object, should be an array of objects
    const availableClone = structuredClone(available);
    if (availableClone) {
      available.fields?.forEach((field: FieldType, index) => {
        if (field.inputType === 'map' && availableClone.fields) {
          availableClone.fields[index].attribute = `map::${field.attribute}`;

          if (typeof field.value === 'object') {
            const values = [];
            for (const key in field.value) {
              values.unshift({ key, value: field.value[key] });
            }
            availableClone.fields[index].value = values;
          }
        }
      });
    }

    return availableClone;
  }, [integrationAvailableManually, myIntegrationData?.available]);

  const formFields = useMemo(() => {
    const fieldBuilder = new FieldBuilder(formInstance);
    const formFields: ReactNode[] = [];
    if (!integrationAvailable) return formFields;

    if (integrationAvailable.has_submit) {
      formFields.push(
        fieldBuilder.getFormItem({
          attribute: 'integration_name',
          inputType: 'text',
          name: 'Name of Integration',
          placeholder: 'Integration Name',
          fieldSize: integrationAvailable.has_enabled_toggle
            ? 'extralarge'
            : 'middle',
          colSize: integrationAvailable.has_enabled_toggle
            ? 'middle'
            : 'extralarge',
          validation: {
            required: true,
          },
          disabled: !hasPermissionToUpdateIntegration,
        })
      );
    }

    if (integrationAvailable.has_enabled_toggle) {
      formFields.push(
        fieldBuilder.getFormItem({
          attribute: 'enabled',
          name: 'Enabled',
          validation: {
            required: true,
          },
          value: true,
          fieldSize: 'middle',
          inputType: 'switch',
          values: [],
          disabled: !hasPermissionToUpdateIntegration,
        })
      );
    }

    integrationAvailable.fields?.forEach((field: FieldType) => {
      if ((isEditing && field.show_on_update !== false) || !isEditing) {
        const disabled =
          field.disabled === true ||
          !hasPermissionToUpdateIntegration ||
          (isEditing && field.update === false);

        formFields.push(fieldBuilder.getFormItem({ ...field, disabled }));
      }
    });

    return formFields;
  }, [
    integrationAvailable,
    isEditing,
    hasPermissionToUpdateIntegration,
    formInstance,
  ]);

  useEffect(() => {
    if (!integrationAvailable) return;
    formInstance.resetFields();
    const values: any = {};
    integrationAvailable.fields?.forEach((field: FieldType) => {
      values[field.attribute] = field.value;
    });
    const integration_name = myIntegrationData?.name;
    const enabled =
      myIntegrationData?.enabled === undefined
        ? true
        : myIntegrationData.enabled;
    const initialValues = {
      ...values,
      integration_name,
      enabled,
    };
    formInstance.setFieldsValue(initialValues);
  }, [integrationAvailable, myIntegrationData, formInstance]);

  const handleSubmit = async (values: any) => {
    const input_values = structuredClone(values);

    for (const key in values) {
      if (typeof values[key] === 'string')
        input_values[key] = values[key].trim();
      // TODO: Fix the issue on the backend and remove this workaround
      // This is a workaround to fix the issue with the map input type
      // The backend is saving the map input type as an object, should be an array of objects
      if (key.startsWith('map::') && Array.isArray(values[key])) {
        const attributeName = key.slice(5);
        input_values[attributeName] = {};
        values[key].forEach((item: { key: string; value: string }) => {
          input_values[attributeName] = {
            ...input_values[attributeName],
            [item.key]: item.value,
          };
        });
        delete input_values[key];
      }
    }

    const { integration_name, enabled } = values;
    delete input_values.integration_name;
    delete input_values.enabled;
    const body = {
      name: integration_name.trim(),
      input_values,
      enabled,
    };

    if (isEditing) {
      const editedIntegration = await editIntegration({
        ...params,
        orgID,
        data: body,
        myIntegrationID: myIntegrationData.UUID,
      }).unwrap();

      onSubmit(editedIntegration.UUID, editedIntegration);
      return;
    }

    if (integrationAvailableManually) {
      const newIntegration = await addIntegration({
        ...params,
        orgID,
        data: {
          ...body,
          integration_uuid: integrationAvailableManually.UUID,
        },
      }).unwrap();

      onSubmit(newIntegration.UUID, newIntegration);
      return;
    }
  };

  return (
    <Form
      disabled={!hasPermissionToUpdateIntegration}
      layout='vertical'
      form={formInstance}
      onFinish={handleSubmit}
      scrollToFirstError
      className='grid grid-cols-4 gap-4'
    >
      {formFields}
    </Form>
  );
};

export default IntegrationForm;
