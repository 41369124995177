import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ListApplicationsResponse } from './application-endpoints';
import { ApplicationClass } from '@/utils/models/Application';

export const mockListAppsAfterAdition = ({
  draft,
  app,
}: {
  draft: MaybeDrafted<ListApplicationsResponse>;
  app: ApplicationClass;
}) => {
  draft.apps.unshift(app);
  draft.total += 1;
};

export const mockListAppsAfterDeletion = ({
  draft,
  appUUID,
}: {
  draft: MaybeDrafted<ListApplicationsResponse>;
  appUUID: string;
}) => {
  draft.apps = draft.apps.filter((app) => app.UUID !== appUUID);
  draft.total -= 1;
  return draft;
};

export const mockGetAppAfterEdition = ({
  app,
}: {
  app: ApplicationClass;
}): MaybeDrafted<ApplicationClass> => {
  return {
    ...app,
    created: app.created,
    createdFromNow: app.createdFromNow,
  };
};

export const mockListAppsAfterEdition = ({
  draft,
  app,
}: {
  draft: MaybeDrafted<ListApplicationsResponse>;
  app: ApplicationClass;
}) => {
  const index = draft.apps.findIndex((a) => a.UUID === app.UUID);
  if (index !== -1) {
    draft.apps[index] = app;
  }
  return draft;
};
