import { ApiClass } from '@/utils/models/Api';
import Item from '../List/Item';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Modal, Tooltip } from 'antd';
import { getLogoPathByName } from '@/utils/constants/listOfImagesPaths';
import {
  LinkedRepo,
  useDeleteLinkedRepoMutation,
} from '@/utils/services/api/api-endpoints';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GoLinkExternal } from 'react-icons/go';
import { MdWarning } from 'react-icons/md';

const LinksItemCard = ({
  linkApi,
  link,
}: {
  linkApi: ApiClass;
  link?: LinkedRepo;
}) => {
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID?: string;
  }>();
  const history = useHistory();
  const [deleteLinkedRepo] = useDeleteLinkedRepoMutation();

  const redirectTo = (subpage?: string) => {
    const baseUrl = `/organisations/${linkApi.api_orgUUID}/applications/${linkApi.api_appUUID}/apis/${linkApi.UUID}`;
    history.push(subpage ? `${baseUrl}/${subpage}` : baseUrl);
  };

  const handleDeleteLink = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this link?`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: `Repo: ${linkApi?.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return deleteLinkedRepo({
          orgID,
          appID,
          apiID: apiID || '',
          destinationID: link?.UUID || '',
        }).unwrap();
      },
    });
  };

  return (
    <Item.Root key={linkApi.key} onClick={() => redirectTo()} id={linkApi.key}>
      <Item.Header>
        <div className='flex flex-row'>
          {linkApi.ft_integration?.platform?.toLowerCase() === 'github' ? (
            <img
              className='h-7 w-7 p-[2px] mr-1 rounded-full grayscale self-center'
              alt='GitHub logo'
              src={getLogoPathByName('github_repository')}
            />
          ) : linkApi.ft_integration?.platform?.toLowerCase() === 'gitlab' ? (
            <img
              className='h-7 w-7 p-[2px] mr-1 rounded-full grayscale self-center'
              alt='GitLab logo'
              src={getLogoPathByName('gitlab_repository')}
            />
          ) : (
            <img
              className='h-7 w-7 p-[2px] mr-1 rounded-full self-center'
              alt='GitHub logo'
              src={linkApi.avatar}
            />
          )}
          <div className='pr-6 self-center'>
            <Item.Title tooltipTitle={linkApi.name}>
              {!appID && linkApi.application
                ? `/ ${linkApi.name}`
                : linkApi.name}
            </Item.Title>
          </div>
        </div>
        {linkApi.metadata?.visibility ? (
          <>
            <div className='ml-1 flex flex-row'>
              <span
                className={`text-xs self-center rounded-lg ${linkApi.metadata?.visibility === 'public' ? 'bg-[#ffae52]' : 'bg-gray-200'} p-1 capitalize flex items-center gap-1 w-fit mb-3 mt-5`}
              >
                {linkApi.metadata.visibility === 'public' ? (
                  <Tooltip
                    className='flex flex-row'
                    title='Your API is linked to a public repository.'
                  >
                    <MdWarning size={16} className='mr-1 self-center' />
                    {linkApi.metadata?.visibility} Repo
                  </Tooltip>
                ) : (
                  `${linkApi.metadata.visibility} Repo`
                )}
              </span>
              <Tooltip
                title={
                  link?.automated
                    ? 'A link automatically created by FireTail'
                    : 'A link manually created by a user.'
                }
              >
                <span className='text-xs self-center rounded-lg bg-gray-200 p-1 capitalize flex items-center gap-1 w-fit mb-3 mt-5 ml-2'>
                  {link?.automated
                    ? 'Automatically Created'
                    : 'Manually Created'}
                </span>
              </Tooltip>
            </div>
          </>
        ) : (
          <div className='mt-4 -mr-1'>
            <div>
              {linkApi.isAwsResource
                ? `${linkApi.typeLabel} (${linkApi.region_name})`
                : linkApi.typeLabel.toLowerCase() === 'rest'
                  ? 'REST'
                  : linkApi.typeLabel}
            </div>
            <Tooltip
              title={
                link?.automated
                  ? 'A link automatically created by FireTail'
                  : 'A link manually created by a user.'
              }
            >
              <span className='text-xs self-center rounded-lg bg-gray-200 p-1 capitalize flex items-center gap-1 w-fit mb-3 mt-5'>
                {link?.automated ? 'Automated' : 'Manually Created'}
              </span>
            </Tooltip>
          </div>
        )}
        {linkApi.metadata?.html_url && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
            }}
            className='border-none hover:text-second shadow-none hover:bg-transparent flex-item-fixed -ml-2'
          >
            <a
              href={linkApi.metadata.html_url}
              className='flex flex-row items-center'
              rel='noreferrer'
              target='_blank'
            >
              <span>View On {linkApi.ft_integration?.platform}</span>
              <GoLinkExternal className='ml-2' size={16} />
            </a>
          </Button>
        )}
      </Item.Header>

      <Item.Footer style={{ marginTop: '35px' }}>
        <div className='self-center mt-5 flex flex-row'>
          {link?.dateAddedInMicroSeconds && (
            <Item.Date
              createdFromNow={moment(
                link?.dateAddedInMicroSeconds / 1000
              ).fromNow()}
              created={linkApi.created}
            />
          )}
        </div>
        <Button
          danger
          onClick={(e) => {
            e.stopPropagation();

            handleDeleteLink();
          }}
          className='mt-5'
        >
          Delete Link
        </Button>
      </Item.Footer>
    </Item.Root>
  );
};

export default LinksItemCard;
