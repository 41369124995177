import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import { IntegrationFooterButtonProps } from '../IntegrationModalFooter';
import { IntegrationModalTabType } from '../IntegrationModal';
import { Button, Form, FormInstance, Modal } from 'antd';
import {
  useAddIntegrationMutation,
  useEditMyIntegrationMutation,
} from '@/utils/services/integration/integration-endpoints';
import { IntegrationClass } from '@/utils/models/Integration';
import {
  INTEGRATION_GITLAB_SETUP_COMPLETE,
  INTEGRATION_GITLAB_SETUP_ERROR,
} from '@/utils/constants';
import { useParams } from 'react-router-dom';
import useRole from '@/utils/hooks/useRole';

const SubmitButton = ({
  integrationAvailable,
  page,
  formInstance,
  myIntegrationData,
}: {
  integrationAvailable?: IntegrationAvailableClass;
  page: IntegrationModalTabType;
  formInstance: FormInstance<any>;
  myIntegrationData?: IntegrationClass;
}) => {
  const { myIntegrationID } = useParams<{ myIntegrationID: string }>();
  const isCreate = !myIntegrationID;
  const isEdit = !!myIntegrationID;

  const warchedFieldValues = Form.useWatch([], formInstance);

  const { hasPermissionToUpdateIntegration, hasPermissionToCreateIntegration } =
    useRole();

  const [, { isLoading: addIntegrationIsLoading }] = useAddIntegrationMutation({
    fixedCacheKey: 'addIntegration',
  });

  const [, { isLoading: editIntegrationIsLoading }] =
    useEditMyIntegrationMutation({
      fixedCacheKey: 'editIntegration',
    });

  const isUpgradeRequired =
    integrationAvailable?.plan_upgrade_required ||
    integrationAvailable?.hasReachedQuotaLimit;

  let requiredFields =
    integrationAvailable?.fields?.filter(
      (f) => f.validation?.required && f.attribute !== 'gitlab_code' // ignore gitlab_code field
    ) || [];

  if (integrationAvailable?.has_submit) {
    requiredFields.push({ attribute: 'integration_name' });
  }

  if (isEdit) {
    requiredFields = requiredFields.filter(
      (f) => f.validation?.required_on_update !== false
    );
  }

  let hasRequiredFieldsEmpty = false;
  if (warchedFieldValues) {
    hasRequiredFieldsEmpty = requiredFields.some(
      (f) => !warchedFieldValues[f.attribute]
    );
  }

  const submitButton: IntegrationFooterButtonProps = {
    isVisible:
      !isUpgradeRequired &&
      page !== 'notificationHistory' &&
      integrationAvailable?.has_submit,
    onClick: () => formInstance.submit(),
    isLoading: addIntegrationIsLoading || editIntegrationIsLoading,
    disabled:
      (page === 'edit' && !hasPermissionToUpdateIntegration) ||
      (page === 'add' && !hasPermissionToCreateIntegration) ||
      hasRequiredFieldsEmpty,
  };

  if (!submitButton.isVisible) return <></>;

  const isGitLabIntegration = IntegrationAvailableClass.isGitLabIntegration(
    integrationAvailable?.UUID
  );

  if (isGitLabIntegration && formInstance) {
    return (
      <GitLabSubmitButton
        formInstance={formInstance}
        submitButton={submitButton}
        integrationAvailable={integrationAvailable}
        myIntegrationData={myIntegrationData}
        warchedFieldValues={warchedFieldValues}
        isCreate={isCreate}
        isEdit={isEdit}
      />
    );
  }

  return (
    <Button
      data-testid='integration-submit-button'
      disabled={submitButton.disabled}
      onClick={submitButton.onClick}
      type='primary'
      loading={submitButton.isLoading}
    >
      Submit
    </Button>
  );
};

const GitLabSubmitButton = ({
  formInstance,
  submitButton,
  integrationAvailable,
  myIntegrationData,
  warchedFieldValues,
  isCreate,
  isEdit,
}: {
  formInstance: FormInstance<any>;
  submitButton: IntegrationFooterButtonProps;
  integrationAvailable?: IntegrationAvailableClass;
  myIntegrationData?: IntegrationClass;
  warchedFieldValues: any;
  isCreate: boolean;
  isEdit: boolean;
}) => {
  const { orgID } = useParams<{ orgID: string }>();

  const authenticateGitLabField = integrationAvailable?.fields?.find(
    (f) => f.inputType === 'authenticate-gitlab'
  );

  const dependsOnFields =
    authenticateGitLabField?.validation?.depends_on_fields;

  const handleGitLabAuthentication = () => {
    formInstance
      .validateFields()
      .then((allFields) => {
        if (authenticateGitLabField?.auth_url === undefined) {
          throw new Error('Auth URL not found');
        }

        let openURL = authenticateGitLabField?.auth_url;

        dependsOnFields?.forEach((fieldName) => {
          openURL = openURL.replace(
            new RegExp('\\{' + fieldName + '}', 'g'),
            allFields[fieldName]
          );
        });

        openURL = openURL.replace(new RegExp('org_uuid', 'g'), orgID);

        const newWindow = window.open(
          openURL,
          '_blank',
          'location=yes,height=720,width=1020,scrollbars=yes,status=yes'
        );
        window.addEventListener(
          'message',
          (event) => handleWindowMessages(event, newWindow),
          false
        );
      })
      .catch(() => {});
  };

  const handleWindowMessages = async (
    event: MessageEvent<any>,
    newWindow: Window | null
  ) => {
    if (event.source === newWindow) {
      newWindow?.close();

      if (event.data.key === INTEGRATION_GITLAB_SETUP_ERROR) {
        Modal.error({
          title: 'Error',
          content:
            event.data.message ||
            'An error occurred while authenticating with GitLab',
        });
      }

      if (event.data.key === INTEGRATION_GITLAB_SETUP_COMPLETE) {
        formInstance?.setFieldsValue({ gitlab_code: event.data.message });
        submitButton?.onClick && submitButton.onClick();
      }
    }
  };

  let isAuthenticateRequired = isCreate;

  if (
    isEdit &&
    dependsOnFields &&
    warchedFieldValues &&
    myIntegrationData?.inputs
  ) {
    isAuthenticateRequired = dependsOnFields.some(
      (fieldName) =>
        warchedFieldValues[fieldName] !== myIntegrationData.inputs[fieldName]
    );
  }

  if (isAuthenticateRequired) {
    return (
      <Button
        data-testid='integration-authenticate-button'
        disabled={submitButton.disabled}
        onClick={handleGitLabAuthentication}
        type='primary'
        loading={submitButton.isLoading}
      >
        Authenticate
      </Button>
    );
  }

  return (
    <Button
      data-testid='integration-submit-button'
      disabled={submitButton.disabled}
      onClick={submitButton.onClick}
      type='primary'
      loading={submitButton.isLoading}
    >
      Submit
    </Button>
  );
};

export default SubmitButton;
