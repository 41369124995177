import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  Result,
  Upload,
  Drawer as DrawerAntd,
  Alert,
  message,
} from 'antd';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import FormsModal from '@/components/Modal/FormsModal';
import { UPLOAD_SPEC_PROPS } from '@/utils/constants';
import prepareSpecificationToUpload from '@/utils/methods/prepareSpecificationToUpload';
import Fields from '@/components/Form/FieldComponents';
import { useAddSpecificationVersionMutation } from '@/utils/services/specification/version-endpoints';
import List from '@/components/List';
import useQuery from '@/utils/hooks/useQuery';
import OverviewPage from '../../pages/organisations/orgID/applications/appID/apis/apiID/specificationID/overview';
import Specification from '@/components/Specification';
import {
  useDeleteSpecificationMutation,
  useLazyGetSpecificationQuery,
  useUpdateSpecificationNameMutation,
} from '@/utils/services/specification/specification-endpoints';
import Item from '@/components/List/Item';
import { useLazyGetApiQuery } from '@/utils/services/api/api-endpoints';
import useRole from '@/utils/hooks/useRole';
import SwaggerPage from '../../pages/organisations/orgID/applications/appID/apis/apiID/specificationID/swagger';
import { MdFileDownload, MdOutlineFileUpload } from 'react-icons/md';
import downloadJSON from '@/utils/methods/downloadJSON';
import useHubspot from '@/utils/hooks/useHubspot';

const SpecificationDrawer = () => {
  const {
    hasPermissionToUpdateSpecification,
    hasPermissionToCreateSpecificationVersion,
    hasPermissionToDeleteSpecification,
  } = useRole();
  const { orgID, appID, apiID, specificationID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
    specificationID?: string;
  }>();
  const { addHubspotWidget, removeHubspotWidget } = useHubspot();
  const [
    getSpec,
    { data: specificationData, error: getSpecificationDataError },
  ] = useLazyGetSpecificationQuery();
  const [getApi, { data: apiData }] = useLazyGetApiQuery();
  const [
    updateSpecificationName,
    { isLoading: updateSpecificationNameIsLoading },
  ] = useUpdateSpecificationNameMutation();
  const [deleteSpecification] = useDeleteSpecificationMutation();
  const [
    addSpecificationVersion,
    { isLoading: addSpecificationVersionIsLoading },
  ] = useAddSpecificationVersionMutation();
  const [showEditSpecificationNameModal, setShowEditSpecificationNameModal] =
    useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const activeTab = query.get('activeTab') || 'overview';
  const open = specificationID !== undefined;

  useEffect(() => {
    if (specificationID) {
      getApi({ orgID, apiID });
      getSpec({ orgID, specificationID: specificationID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificationID, apiID, orgID]);

  useEffect(() => {
    if (open) removeHubspotWidget();
    else {
      addHubspotWidget();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDeleteSpecification = async (specificationID: string) => {
    await deleteSpecification({ orgID, specificationID }).unwrap();
    handleClose();
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this specification?`,
      content: `Name: ${specificationData?.name}`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        if (specificationID) return handleDeleteSpecification(specificationID);
      },
    });
  };

  const handleSaveSpecification = async (specData: any) => {
    if (specificationID)
      await addSpecificationVersion({
        orgID,
        appID,
        apiID,
        specificationID: specificationID,
        specData,
      }).unwrap();
  };

  const handleCloseEditSpecificationNameModal = () =>
    setShowEditSpecificationNameModal(false);

  const handleUpdateSpecificationName = async ({ name }: { name: string }) => {
    if (specificationID) {
      await updateSpecificationName({
        orgID,
        specificationID: specificationID,
        name,
      }).unwrap();
      handleCloseEditSpecificationNameModal();
    }
  };

  if (getSpecificationDataError) {
    const error = getSpecificationDataError as {
      message: string | undefined;
    };

    return (
      <div className='pt-16' data-testid='show-unauthorized'>
        <Result status='error' title={error?.message || 'Bad request'} />
      </div>
    );
  }

  const handleClose = () => {
    const pathname = location.pathname.split('/');
    pathname.pop(); // remove id
    history.replace({ pathname: pathname.join('/') });
  };

  return (
    <DrawerAntd
      width={'100%'}
      placement='right'
      onClose={handleClose}
      size='large'
      open={open}
      closable={false}
    >
      <FormsModal
        title='Specification Name'
        showModal={showEditSpecificationNameModal}
        handleSubmit={handleUpdateSpecificationName}
        handleCloseModal={handleCloseEditSpecificationNameModal}
        initValues={{ name: specificationData?.name }}
        confirmLoading={updateSpecificationNameIsLoading}
        fields={[
          <Fields.InputText
            key='name'
            field={{
              attribute: 'name',
              name: 'Name',
              validation: {
                required: true,
              },
              inputType: 'text',
              fieldSize: 'extralarge',
              colSize: 'extralarge',
            }}
          />,
        ]}
      />

      <section>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-2 text-lg'>
            <ArrowLeftOutlined
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              className='hover:text-second'
              onClick={handleClose}
            />
            <Link
              to={`/organisations/${orgID}/applications/${appID}/apis/${apiID}/specifications`}
              className='hover:text-second line-clamp-1'
            >
              {apiData?.name}
            </Link>
            <span>/</span>
            {hasPermissionToUpdateSpecification ? (
              <div
                className='flex focus:outline-none items-center gap-1 '
                onClick={() => setShowEditSpecificationNameModal(true)}
              >
                <span className='text-lg font-bold text-first line-clamp-1'>
                  {specificationData?.name}
                </span>
                <EditOutlined
                  className='p-1'
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
              </div>
            ) : (
              <span className='text-lg font-bold text-first flex items-center'>
                {specificationData?.name}
              </span>
            )}
          </div>

          <div className='ml-2 flex gap-2 items-center'>
            {specificationData && (
              <Item.UUID uuid={`ID: ${specificationData?.UUID}`} />
            )}
            {specificationData && (
              <Button
                className='flex items-center gap-2'
                onClick={() => {
                  if (specificationData.version) {
                    downloadJSON(
                      specificationData.version.spec,
                      `${specificationData.version.UUID}.json`
                    );
                  } else message.error('No specification version found');
                }}
              >
                <span>Download</span>

                <MdFileDownload />
              </Button>
            )}
            {hasPermissionToCreateSpecificationVersion && (
              <Upload
                {...UPLOAD_SPEC_PROPS}
                action={(file) => {
                  prepareSpecificationToUpload(file, handleSaveSpecification);
                  return Promise.resolve('');
                }}
              >
                <Button
                  className='flex items-center gap-2'
                  loading={addSpecificationVersionIsLoading}
                >
                  <span>Upload a new version</span>
                  <MdOutlineFileUpload />
                </Button>
              </Upload>
            )}
            {hasPermissionToDeleteSpecification && (
              <Button
                className='flex items-center'
                danger
                onClick={showDeleteConfirm}
              >
                <span>Delete</span>
                <DeleteOutlined
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
              </Button>
            )}
          </div>
        </div>

        <div className='py-4'>
          <List.Tabs
            onChange={(key) => {
              query.set('activeTab', key);
              history.replace({
                pathname: `/organisations/${orgID}/applications/${appID}/apis/${apiID}/specifications/${specificationID}`,
                search: query.toString(),
              });
            }}
            tabs={[
              {
                label: 'Overview',
                key: 'overview',
              },
              {
                label: 'Swagger UI',
                key: 'swagger',
              },
            ]}
            selectedTab={activeTab}
          />

          <div
            className={`grid grid-cols-12 -mt-4 ${activeTab === 'overview' ? 'visible' : 'hidden'}`}
          >
            <div className='col-span-3 border-r mr-6 pt-4 overflow-x-auto'>
              {specificationID && (
                <Specification.Tree specID={specificationID} />
              )}
            </div>

            <div className='col-span-9 pt-4'>
              {specificationData && !specificationData?.version && (
                <Alert
                  description='Latest version of the specification was unable to be fetched, please upload a new version.'
                  showIcon
                  type='warning'
                  className='mb-4'
                />
              )}

              <OverviewPage specificationID={specificationID} />
            </div>
          </div>

          <div className={`${activeTab === 'swagger' ? 'visible' : 'hidden'}`}>
            <SwaggerPage specificationID={specificationID} />
          </div>
        </div>
      </section>
    </DrawerAntd>
  );
};

export default SpecificationDrawer;
