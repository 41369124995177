import { Button, Form, Modal, Select, Space, Tag, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MemberClass } from '@/utils/models/Member';
import {
  useUpsertMembersMutation,
  useDeleteMembersMutation,
  useResendEmailMutation,
  useListMemberRolesQuery,
  useListMembersQuery,
} from '@/utils/services/member/member-endpoints';
import List from '@/components/List';
import FormsModal from '@/components/Modal/FormsModal';
import Fields from '@/components/Form/FieldComponents';
import useRole from '@/utils/hooks/useRole';
import { MdWarning } from 'react-icons/md';
import Billing from '@/components/Billing';
import { useGetOrganisationQuotasQuery } from '@/utils/services/quota/quota-endpoints';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';

const Members = () => {
  const {
    hasPermissionToUpdateMember,
    hasPermissionToDeleteMember,
    hasPermissionToCreateMember,
    hasPermissionToResendEmail,
  } = useRole();
  const { orgID } = useParams<{ orgID: string }>();

  const { data: memberRoles } = useListMemberRolesQuery(
    { orgID },
    { skip: !hasPermissionToUpdateMember }
  );
  const { data: organizationQuota } = useGetOrganisationQuotasQuery({
    orgID,
  });

  const upgradeRequired =
    (organizationQuota?.current_users as number) >=
    (organizationQuota?.max_users as number);

  const { query } = useSearchQueryParam();

  const {
    data: membersData,
    isLoading: membersDataIsLoading,
    isFetching: membersDataIsFetching,
  } = useListMembersQuery({ orgID, query });
  const [upsertMembers, { isLoading: addMemberIsLoading }] =
    useUpsertMembersMutation();
  const [deleteMembers, { isLoading: deleteMemberIsLoading }] =
    useDeleteMembersMutation();
  const [resendEmail, { isLoading: resendEmailIsLoading }] =
    useResendEmailMutation();
  const [members, setMembers] = useState<MemberClass[]>([]);
  const [roleID, setRoleID] = useState<number>();
  const [email, setEmail] = useState<string | undefined>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (membersData) setMembers(membersData);
  }, [membersData]);

  const handleResendEmail = async (member: MemberClass) => {
    await resendEmail({
      orgID,
      data: {
        email: member.org_email,
      },
    }).unwrap();
    setMembers(
      members.map((m) => {
        if (m.dateAddedInMicroSeconds === member.dateAddedInMicroSeconds)
          return { ...m, status: 'resend_email' };
        return m;
      })
    );
  };

  const handleCloseModal = () => {
    setEmail(undefined);
    if (Array.isArray(memberRoles) && memberRoles.length > 0) {
      setRoleID(memberRoles[0].id);
    }
    setShowCreateModal(false);
  };

  const handleCreate = (values: { email: string; role: number }) => {
    upsertMembers({
      orgID,
      data: {
        members: [{ email: values.email, roleID: values.role }],
      },
    })
      .unwrap()
      .then(() => handleCloseModal());
  };

  const handleDeleteMember = async (member: MemberClass) =>
    deleteMembers({
      orgID,
      data: {
        members: [
          {
            email: member.org_email,
          },
        ],
      },
    });

  const showDeleteConfirm = (member: any) => {
    Modal.confirm({
      title: `Are you sure you want to delete this member?`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: member.org_email,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleDeleteMember(member);
      },
    });
  };

  const columns: ColumnsType<MemberClass> = [
    {
      title: 'Email',
      dataIndex: 'org_email',
      key: 'org_email',
      render: (email) => <span>{email}</span>,
    },
    {
      title: 'Role',
      key: 'roleName',
      render: (_, member) => {
        if (member.roleID === 0) return <Tag key='OWNER'>OWNER</Tag>;
        return <Tag key={member.roleName}>{member.roleName.toUpperCase()}</Tag>;
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        if (status !== 'active')
          return (
            <Tag color='orange' key={status}>
              PENDING
            </Tag>
          );
        return (
          <Tag key={status} color='green'>
            ACTIVE
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, member) => (
        <Space size='middle'>
          {hasPermissionToResendEmail && (
            <>
              {member.status === 'pending_accept' && (
                <span
                  onClick={() => {
                    handleResendEmail(member);
                  }}
                  className='rounded-sm py-1 px-2 text-yellow-600 
              hover:bg-yellow-100 cursor-pointer text-xs uppercase'
                >
                  Resend email
                </span>
              )}
              {member.status === 'resend_email' && (
                <span className='text-green-600 uppercase text-xs'>
                  Email sent
                </span>
              )}
            </>
          )}
          {hasPermissionToUpdateMember && (
            <span
              onClick={() => {
                setEmail(member.org_email);
                setRoleID(member.roleID);
                setShowCreateModal(true);
              }}
              className='rounded-sm py-1 px-2 text-blue-600 hover:bg-blue-100 
            cursor-pointer text-xs uppercase'
            >
              Edit
            </span>
          )}
          {hasPermissionToDeleteMember && member.roleID !== 0 && (
            <span
              onClick={() => showDeleteConfirm(member)}
              className='rounded-sm py-1 px-2 text-red-600 hover:bg-red-100 
            cursor-pointer text-xs uppercase'
            >
              Delete
            </span>
          )}
        </Space>
      ),
    },
  ];

  if (
    !hasPermissionToDeleteMember &&
    !hasPermissionToUpdateMember &&
    !hasPermissionToResendEmail
  ) {
    columns.pop();
  }

  const isLoading =
    membersDataIsLoading ||
    membersDataIsFetching ||
    addMemberIsLoading ||
    deleteMemberIsLoading ||
    resendEmailIsLoading;

  return (
    <>
      <Billing.Modal
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
        titleText='You have reached your maximum amount of members.'
      />
      <FormsModal
        title={`${email !== undefined ? 'Edit' : 'Invite'} Team Member`}
        confirmLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleCreate}
        showModal={showCreateModal}
        initValues={{ role: roleID, email }}
        fields={[
          <Fields.InputText
            key='email'
            field={{
              attribute: 'email',
              name: 'Email',
              placeholder: 'someone@yourcompany.com',
              validation: {
                required: true,
                regex:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              },
              inputType: 'text',
              htmlType: 'email',
              disabled: email !== undefined,
              fieldSize: 'extralarge',
              colSize: 'extralarge',
            }}
          />,
          <Form.Item
            key='role'
            name='role'
            label='Select a Role'
            rules={[{ required: true, message: 'Role is required' }]}
          >
            <Select className='w-1/2'>
              {roleID === 0 && (
                <Select.Option key='owner' value={0}>
                  Owner
                </Select.Option>
              )}
              {memberRoles?.map((role, index: number) => (
                <Select.Option key={index.toString()} value={role.id}>
                  {role.friendly}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>,
        ]}
      />

      <List.Root>
        <List.Header>
          <List.Search placeholder='Search members' />
          {hasPermissionToCreateMember && (
            <List.Settings>
              <Button
                onClick={() => {
                  if (
                    (organizationQuota?.current_users as number) >=
                    (organizationQuota?.max_users as number)
                  ) {
                    setIsPlanModalOpen(true);
                    return;
                  }

                  if (!Array.isArray(memberRoles) || memberRoles.length === 0)
                    return;
                  setRoleID(memberRoles[0].id);
                  setShowCreateModal(true);
                }}
                type='primary'
              >
                <div className='flex flex-row'>
                  Invite Team Member
                  {upgradeRequired && (
                    <Tooltip title='Upgrade Required'>
                      <MdWarning
                        style={{ color: '#FFE25E' }}
                        size={16}
                        className='ml-2 self-center'
                      />
                    </Tooltip>
                  )}
                </div>
              </Button>
              <List.QuotaIndicator
                quota={{
                  current: organizationQuota?.current_users,
                  max: organizationQuota?.max_users,
                }}
                tooltipText={`You currently have ${organizationQuota?.current_users} out of your ${organizationQuota?.max_users} allocated members.`}
              />
            </List.Settings>
          )}
        </List.Header>

        <List.Pagination dataSource={members}>
          <List.Table columns={columns} loading={isLoading} ignoreMode />
        </List.Pagination>
      </List.Root>
    </>
  );
};

export default Members;
