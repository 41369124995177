import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Empty, Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import FormsModal from '@/components/Modal/FormsModal';
import LIST_OF_API_TYPES from '@/utils/constants/listOfApiTypes';
import Container from '@/components/Section/Container';
import List from '@/components/List';
import Heading from '@/components/Section/Heading';
import {
  useAddApiMutation,
  useGetApiQuery,
  useLazyListApisQuery,
  useListApisQuery,
  useListLinkedReposQuery,
} from '@/utils/services/api/api-endpoints';
import { ApiClass } from '@/utils/models/Api';
import Item from '@/components/List/Item';
import { ColumnsType } from 'antd/lib/table';
import useFiltersQueryParam from '@/utils/hooks/QueryParam/useFiltersQueryParam';
import FieldType from '@/utils/models/FieldType';
import ApisItemCard from './ApisItemCard';
import useRole from '@/utils/hooks/useRole';
import { MdWarning } from 'react-icons/md';
import Billing from '@/components/Billing';
import { useGetApiQuotasQuery } from '@/utils/services/quota/quota-endpoints';
import usePaginationQueryParam, {
  PaginationDataType,
} from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import LinksItemCard from './LinksItemCard';
import { GoLinkExternal } from 'react-icons/go';
import ApiTagsGroup from './ApiTagsGroup';
import { getLogoPathByName } from '@/utils/constants/listOfImagesPaths';
import FullScreenLoader from '../Loader/FullScreenLoader';
import {
  useGetApplicationQuery,
  useLazyGetApplicationQuery,
} from '@/utils/services/application/application-endpoints';
import { ApplicationClass } from '@/utils/models/Application';

const initValues = {
  api_type: 'rest',
};

const ApisListing = () => {
  const { hasPermissionToCreateApi, hasPermissionToListActions } = useRole();
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID?: string;
    apiID?: string;
  }>();
  const location = useLocation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState<ApplicationClass | null>(null);
  const [initValuesCreateAPI, setInitValuesCreateAPI] =
    useState<any>(initValues);
  const history = useHistory();
  const isOrgAPIs = !appID;
  const [addApi, { isLoading: addApiIsLoading }] = useAddApiMutation();
  const [fetchApis, { isLoading: isApisLoading, isFetching: isApisFetching }] =
    useLazyListApisQuery();
  const [
    getApp,
    { isLoading: isCurrentAppLoading, isFetching: isCurrentAppFetching },
  ] = useLazyGetApplicationQuery();
  const { data: app } = useGetApplicationQuery(
    { orgID, appID: appID || '' },
    { skip: !appID }
  );
  const isLinkedRepos = location.pathname.endsWith('/linked-resources');
  const { data: apiData } = useGetApiQuery(
    { orgID, apiID: apiID || '' },
    { skip: !isLinkedRepos || !apiID }
  );
  const isApi = apiData?.ft_integration?.platform?.toLowerCase() !== 'github';

  const { pagination } = usePaginationQueryParam();
  const { filters } = useFiltersQueryParam();
  const { query } = useSearchQueryParam();

  const listLinksParams = {
    orgID,
    appID: appID || '',
    apiID: apiID || '',
    data: {
      filters,
      search_value: query,
    },
    ...pagination,
  };

  const { data: linkedReposApis, isFetching: isFetchingLinkedRepos } =
    useListLinkedReposQuery(listLinksParams, {
      skip: !isLinkedRepos || !apiID || !appID,
    });

  const getListingFilters = () => {
    if (isLinkedRepos) {
      if (linkedReposApis?.links && linkedReposApis?.links.length > 0) {
        return [
          {
            field: 'apiUUID',
            values: linkedReposApis?.links.map((link) =>
              isApi ? link.destination_apiUUID : link.source_apiUUID
            ),
          },
        ];
      }

      return [];
    }
    return filters;
  };

  useEffect(() => {
    if (!apiID && appID && app) {
      if (app?.propagate_owners)
        setInitValuesCreateAPI((prev: any) => ({
          ...prev,
          owners: app?.owners,
        }));
      if (app?.propagate_tags)
        setInitValuesCreateAPI((prev: any) => ({
          ...prev,
          customer_tags: app?.customer_tags,
        }));
    }
  }, [apiID, appID, app]);

  const listApisParams = {
    orgID,
    filters: getListingFilters(),
    fetchSeverities: true,
    fetchApplication: isOrgAPIs,
    query,
    skipBulk: isLinkedRepos,
    ...pagination,
  };

  const isLinksListEmpty = isLinkedRepos && linkedReposApis?.links.length === 0;

  const {
    data: listApisData,
    isLoading: isListApisLoading,
    isFetching: isListApisFetching,
  } = useListApisQuery(listApisParams, {
    skip: isLinksListEmpty,
    pollingInterval: 30000,
  });

  const { data: apiQuota, isLoading: isApiQuotaLoading } = useGetApiQuotasQuery(
    {
      orgID,
    }
  );

  const upgradeRequired =
    apiQuota && (apiQuota.current as number) >= (apiQuota?.max as number);

  const apis = isLinksListEmpty ? [] : listApisData?.apis;
  const total = isLinksListEmpty ? 0 : listApisData?.total;

  const isLinksRefetching = useSelector(
    (state: RootState) => state.apis.loadingStates['listLinkedRepos']
  );

  const isListRefetching = useSelector(
    (state: RootState) => state.apis.loadingStates['listAlerts']
  );

  const isListLoading =
    isListApisLoading ||
    isListApisFetching ||
    isApisLoading ||
    isApisFetching ||
    isListRefetching ||
    isApiQuotaLoading;

  const isLinksLoading = !!isLinksRefetching || isFetchingLinkedRepos;

  const redirectTo = (api: ApiClass, subpage?: string) => {
    const baseUrl = `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}/apis/${api.UUID}`;
    history.push(subpage ? `${baseUrl}/${subpage}` : baseUrl);
  };

  const handleCreate = async (values: {
    name: string;
    api_type: string;
    app_uuid: string;
    api_endpoints: string[];
    owners: string[];
    customer_tags: any[];
  }) => {
    let dataToSubmit = { ...values };

    if (values.customer_tags && values.customer_tags.length > 0) {
      const tagsKV: { k: string; v: string }[] = [];

      values.customer_tags.forEach((obj) => {
        tagsKV.push({ k: obj.key, v: obj.value });
      });

      dataToSubmit.customer_tags = tagsKV;
    }

    await addApi({
      orgID,
      appID: appID || values.app_uuid,
      data: dataToSubmit,
      filters,
    }).unwrap();
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
    setSelectedApp(null);
    setInitValuesCreateAPI(initValues);
  };

  const handleOpenModal = () => {
    if (apiQuota) {
      if (upgradeRequired) {
        setIsPlanModalOpen(true);
        return;
      }
    }

    setShowCreateModal(true);
  };

  let columns: ColumnsType<ApiClass> = isLinkedRepos
    ? [
        {
          title: 'Name',
          key: 'name',
          render: (_, api) => (
            <div className='flex gap-3'>
              <button
                onClick={() => redirectTo(api)}
                className='text-base text-blue-600 font-semibold text-left whitespace-nowrap'
              >
                {api.name}
              </button>

              <ApiTagsGroup api={api} />
            </div>
          ),
        },
        {
          title: 'Created',
          dataIndex: 'created',
          render: (_, api) => (
            <Item.Date
              createdFromNow={api.createdFromNow}
              created={api.created}
            />
          ),
        },
        {
          title: 'Application',
          key: 'application',
          render: (_, api) => (
            <div
              className='flex gap-1 items-center'
              style={{ maxWidth: '120px' }}
              onClick={(evt) => {
                evt.stopPropagation();
                history.push(
                  `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}`
                );
              }}
            >
              <Tooltip title='Application'>
                <div className='truncate hover:underline cursor-pointer'>
                  <Item.Span text={api.application?.name} />
                </div>
              </Tooltip>
            </div>
          ),
        },
      ]
    : [
        {
          title: 'Name',
          key: 'name',
          render: (_, api) => (
            <div className='flex gap-3'>
              <button
                onClick={() => redirectTo(api)}
                className='text-base text-blue-600 font-semibold text-left whitespace-nowrap'
              >
                {api.name}
              </button>

              <ApiTagsGroup api={api} />
            </div>
          ),
        },
        {
          title: 'Source',
          key: 'avatar',
          render: (_, api) => (
            <div className='whitespace-nowrap pr-4'>
              <Item.LogoAndName logo={api.avatar} name={api.typeLabel} />
            </div>
          ),
        },
        {
          title: 'Created',
          dataIndex: 'created',
          render: (_, api) => (
            <Item.Date
              createdFromNow={api.createdFromNow}
              created={api.created}
            />
          ),
        },
        {
          title: 'Application',
          key: 'application',
          render: (_, api) => (
            <div
              className='flex gap-1 items-center'
              style={{ maxWidth: '120px' }}
              onClick={(evt) => {
                evt.stopPropagation();
                history.push(
                  `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}`
                );
              }}
            >
              <Tooltip title='Application'>
                <div className='truncate hover:underline cursor-pointer'>
                  <Item.Span text={api.application?.name} />
                </div>
              </Tooltip>
            </div>
          ),
        },
        {
          title: 'Findings',
          key: 'findings',
          width: 500,
          render: (_, api) => (
            <Item.SeveritiesOverview
              severities={api.severities}
              onClick={() => redirectTo(api, 'findings')}
            />
          ),
        },

        {
          title: 'Resources',
          key: 'resources',
          render: (_, api) => {
            const baseURL = `/organisations/${api.api_orgUUID}/applications/${api.api_appUUID}/apis/${api.UUID}`;
            return (
              <Space size='middle'>
                <Link
                  to={`${baseURL}/requests`}
                  className='text-first-light px-2 py-2 hover:text-first hover:underline'
                >
                  Requests
                </Link>
                <Link
                  to={`${baseURL}/tokens`}
                  className='text-first-light px-2 py-2 hover:text-first hover:underline'
                >
                  Tokens
                </Link>
                <Link
                  to={`${baseURL}/specifications`}
                  className='text-first-light px-2 py-2 hover:text-first hover:underline'
                >
                  Specifications
                </Link>
                <Link
                  to={`${baseURL}/findings`}
                  className='text-first-light px-2 py-2 hover:text-first hover:underline'
                >
                  Findings
                </Link>
                {hasPermissionToListActions && (
                  <Link
                    to={`${baseURL}/actions`}
                    className='text-first-light px-2 py-2 hover:text-first hover:underline'
                  >
                    Actions
                  </Link>
                )}
                <Link
                  to={`${baseURL}/details`}
                  className='text-first-light px-2 py-2 hover:text-first hover:underline'
                >
                  Details
                </Link>
              </Space>
            );
          },
        },
      ];

  if (!isApi) {
    columns.push({
      title: 'API Type',
      dataIndex: 'created',
      render: (_, api) => (
        <Item.LogoAndName
          logo={api.scanSource.logo}
          name={api.scanSource.label}
        />
      ),
    });
  }

  if (isApi) {
    columns.push(
      {
        title: 'Repo Type',
        key: 'avatar',
        render: (_, api) => (
          <div className='whitespace-nowrap pr-4'>
            <Item.LogoAndName
              logo={
                api.ft_integration?.platform?.toLowerCase() === 'github'
                  ? getLogoPathByName('github_repository')
                  : api.ft_integration?.platform?.toLowerCase() === 'gitlab'
                    ? getLogoPathByName('gitlab_repository')
                    : api.avatar
              }
              name={api.ft_integration?.platform}
            />
          </div>
        ),
      },
      {
        title: 'Visibility',
        render: (_, api) =>
          api?.metadata?.visibility && (
            <span className='text-xs self-center rounded-lg bg-gray-200 p-1 capitalize flex items-center gap-1 w-fit'>
              {api.metadata?.visibility} Repo
            </span>
          ),
      }
    );
    columns.push({
      title: 'Repository Link',
      render: (_, api) =>
        api?.metadata?.visibility && (
          <span className=''>
            <a
              href={api.metadata?.html_url}
              className='hover:text-second flex flex-row'
              target='_blank'
              rel='noreferrer'
            >
              View On {api.ft_integration?.platform}{' '}
              <GoLinkExternal className='self-center ml-2' />
            </a>
          </span>
        ),
    });
  }

  if (!isOrgAPIs) columns = columns.filter((c) => c.key !== 'application');

  const fetchDataToDownload = async (
    pagination?: PaginationDataType
  ): Promise<any[]> => {
    const { apis } = await fetchApis(
      { ...listApisParams, ...pagination },
      true
    ).unwrap();
    return apis?.map((api) => api.mapDataToDownload) || [];
  };

  const Listing = (
    <List.Root
      renderEmpty={{
        isEmpty: isLinksListEmpty ? true : listApisData?.isEmpty && !query,
        loading: isListLoading,
        element: !isLinkedRepos ? (
          <List.Empty
            title='Create an API'
            subtitle={`Create an API to group together a specific set of API endpoints.`}
            buttonTxt='Create API'
            handleClick={handleOpenModal}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No data</span>}
          />
        ),
      }}
    >
      <List.Header isCode={isLinkedRepos}>
        {!isLinkedRepos && <List.Search placeholder='Search APIs' />}
        <List.Settings>
          {hasPermissionToCreateApi && !isLinkedRepos && (
            <Button
              onClick={handleOpenModal}
              disabled={isApiQuotaLoading}
              type='primary'
            >
              <div className='flex flex-row'>
                Create API
                {upgradeRequired && (
                  <Tooltip title='Upgrade Required'>
                    <MdWarning
                      style={{ color: '#FFE25E' }}
                      size={16}
                      className='ml-2 self-center'
                    />
                  </Tooltip>
                )}
              </div>
            </Button>
          )}

          {!isLinkedRepos && (
            <>
              <List.QuotaIndicator
                quota={apiQuota}
                tooltipText={`You are currently using ${
                  apiQuota?.current as number
                } out of your ${apiQuota?.max as number} allocated APIs.`}
              />

              <List.Filter
                isLinkedRepos={isLinkedRepos}
                resource={isLinkedRepos ? 'linkedRepository' : 'api'}
              />

              <List.Download
                numberOfItems={total}
                fetchData={fetchDataToDownload}
                loading={isApisLoading || isApisFetching}
              />
            </>
          )}
        </List.Settings>
        <List.Segmented />
      </List.Header>

      <List.Pagination dataSource={apis} useURI total={total}>
        <List.Grid
          item={{
            render: (api: ApiClass) =>
              !isLinkedRepos ? (
                <ApisItemCard key={api.key} api={api} />
              ) : (
                <LinksItemCard
                  linkApi={api}
                  key={api.key}
                  link={linkedReposApis?.links.find((link) =>
                    isApi
                      ? link.destination_apiUUID === api.UUID
                      : link.source_apiUUID === api.UUID
                  )}
                />
              ),
          }}
          loading={isListLoading}
        />

        <List.Table columns={columns} loading={isListLoading} />
      </List.Pagination>
    </List.Root>
  );

  useEffect(() => {
    if (selectedApp) {
      const newInitValues = {
        ...initValuesCreateAPI,
        api_uuid: selectedApp.UUID,
      };

      if (selectedApp.propagate_owners) {
        newInitValues.owners = selectedApp.owners;
      }
      if (selectedApp.propagate_tags) {
        newInitValues.customer_tags = selectedApp.customer_tags;
      }

      setInitValuesCreateAPI(newInitValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp]);

  const onAppChange = async (value: string) => {
    const app = await getApp({
      orgID,
      appID: value,
      supressErrors: true,
    }).unwrap();

    const newInitValues: {
      app_uuid: string;
      owners?: string[];
      customer_tags?: any[];
    } = { ...initValues, app_uuid: app.UUID };

    if (app.propagate_owners) {
      newInitValues.owners = app.owners;
    }
    if (app.propagate_tags) {
      newInitValues.customer_tags = app.customer_tags;
    }

    setSelectedApp(app);
    setInitValuesCreateAPI(newInitValues);
  };

  const createAPIFormFields: FieldType[] = [
    {
      attribute: 'app_uuid',
      inputType: 'select',
      placeholder: 'my-application',
      name: 'Application',
      sourceFrom: { resource: 'app' },
      validation: {
        required: true,
      },
      values: [],
      fieldSize: 'extralarge',
      colSize: 'extralarge',
      onValueChange: (value) => onAppChange(value),
    },
    {
      attribute: 'name',
      name: 'API Name',
      placeholder: 'my-api',
      validation: {
        required: true,
        regex: `^[a-zA-Z0-9][a-zA-Z0-9-\\s/]+[a-zA-Z0-9]$`,
        regex_description:
          'The name must start and end with a letter or number, be between 3 and 128 characters long, and in between, it can contain letters, numbers, hyphens, spaces, or slashes.',
      },
      inputType: 'text',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'api_type',
      name: 'API Type',
      validation: { required: true },
      inputType: 'select',
      values: LIST_OF_API_TYPES.map((t) => ({ name: t.type, value: t.value })),
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'api_endpoints',
      name: 'API Endpoints',
      inputType: 'multitext',
      validation: {
        regex: `^https?://.*`,
        regex_description:
          'Endpoints must be a valid https addresses, starting with https://',
        maxSize: 10,
      },
    },
    {
      attribute: 'owners',
      name: 'Owners',
      inputType: 'multitext',
      validation: { maxSize: 20 },
      disabled: isCurrentAppFetching || isCurrentAppLoading,
      prefilledValues: initValuesCreateAPI.owners || [],
    },
    {
      attribute: 'customer_tags',
      name: 'Custom Tags',
      inputType: 'map',
      validation: { max: 10 },
      update: true,
      value: {},
      addString: 'Tag',
      disabled: isCurrentAppFetching || isCurrentAppLoading,
      prefilledValues: initValuesCreateAPI.customer_tags
        ? initValuesCreateAPI.customer_tags.map(
            (tag: { k: string; v: string }) => tag.k
          )
        : [],
    },
  ];

  if (!isOrgAPIs) createAPIFormFields.shift();

  return (
    <>
      {isOrgAPIs && <Heading text='APIs' />}

      <Billing.Modal
        titleText='You have reached your maximum amount of created APIs.'
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
      />

      <FormsModal
        title='Create API'
        confirmLoading={addApiIsLoading}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleCreate}
        showModal={showCreateModal}
        initValues={initValuesCreateAPI}
        fields={createAPIFormFields}
      />

      {isLinksLoading ? (
        <FullScreenLoader />
      ) : isOrgAPIs ? (
        <Container>{Listing}</Container>
      ) : (
        Listing
      )}
    </>
  );
};

export default ApisListing;
