import { useGetFindingsOverviewQuery } from '@/utils/services/finding/finding-endpoints';
import { useParams } from 'react-router-dom';
import { counterFormat } from '@/utils/methods/displayFormat';
import { Pie } from '@ant-design/plots';
import { FindingClass, SeverityFindingType } from '@/utils/models/Finding';
import { Spin } from 'antd';
import { useMemo } from 'react';
import useFiltersQueryParam from '@/utils/hooks/QueryParam/useFiltersQueryParam';
import { FindingsOverviewInterface } from '@/utils/services/finding/findings-methods';
import useStaticDateTimeQueryParam from '@/utils/hooks/QueryParam/useStaticDateTimeQueryParam';
import { LoadingOutlined } from '@ant-design/icons';

const FindingsOverview = () => {
  const { orgID } = useParams<{ orgID: string }>();

  const { filters, getFilterValue, setFilterValue } = useFiltersQueryParam();

  const { dateTime } = useStaticDateTimeQueryParam();

  const {
    data: counts,
    isLoading: getFindingIsLoading,
    isFetching: getFindingIsFetching,
  } = useGetFindingsOverviewQuery({
    orgID,
    dateTime,
    filters,
  });

  const severitiesLevels: SeverityFindingType[] = [
    'critical',
    'high',
    'medium',
    'low',
    'information',
  ];

  const isLoading = getFindingIsLoading || getFindingIsFetching;

  const antIcon = (
    <LoadingOutlined
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      style={{ fontSize: 18 }}
      spin
    />
  );

  const handleOnClick = (level: SeverityFindingType) => {
    const activeSeverityValues = getFilterValue('severity') || [];
    const isActive = activeSeverityValues?.includes(level);

    if (isActive) {
      setFilterValue({
        field: 'severity',
        values: activeSeverityValues.filter(
          (severityValue) => severityValue !== level
        ),
      });
    } else {
      setFilterValue({
        field: 'severity',
        values: [...activeSeverityValues, level],
      });
    }
  };

  return (
    <div
      className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 
    gap-2 2xl:gap-4 gap-y-5'
    >
      {severitiesLevels.map((level) => (
        <div
          key={level}
          className={`${
            counts && counts[level].value === 0
              ? 'grayscale opacity-60'
              : 'grayscale-0'
          } bg-white px-3 py-2 border-b-4
          rounded-sm shadow-sm h-[68px] cursor-pointer hover:scale-105 transition-all`}
          style={{ borderColor: FindingClass.getSeverityColor(level) }}
          onClick={() => handleOnClick(level)}
        >
          <Spin indicator={antIcon} spinning={isLoading}>
            <p className='text-gray-400 capitalize font-light'>{level}</p>

            <div className='flex justify-between items-center'>
              <span className='text-lg font-semibold'>
                {counts ? counterFormat(counts[level].value) : 0}
              </span>

              <DonutChart counts={counts} level={level} />
            </div>
          </Spin>
        </div>
      ))}
    </div>
  );
};

const DonutChart = ({
  counts,
  level,
}: {
  counts?: FindingsOverviewInterface;
  level: SeverityFindingType;
}) => {
  const DonutChart = useMemo(
    () => (
      <Pie
        className='text-left'
        height={20}
        width={20}
        data={[
          {
            key: 'others',
            title: 'others',
            value: counts ? 100 - counts[level].percentage : 100,
          },
          {
            key: level,
            title: level,
            value: counts ? counts[level].percentage : 0,
          },
        ]}
        angleField={'value'}
        colorField={'key'}
        radius={1}
        innerRadius={0.7}
        legend={false}
        label={{
          type: 'inner',
          content: () => '',
        }}
        pieStyle={{
          lineWidth: 0,
        }}
        statistic={{
          title: false,
          content: {
            customHtml: () => '',
          },
        }}
        tooltip={false}
        color={['#ededed', FindingClass.getSeverityColor(level)]}
      />
    ),
    [counts, level]
  );

  return DonutChart;
};

export default FindingsOverview;
