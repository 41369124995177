import { DownOutlined } from '@ant-design/icons';
import { Empty, Spin, Tree } from 'antd';
import { TreeProps } from 'antd/lib/tree';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useGetSpecificationQuery } from '@/utils/services/specification/specification-endpoints';
import useQuery from '@/utils/hooks/useQuery';
import { useAppSelector } from '@/app/hooks';
import { dashboardFiltersSelectors } from '../Dashboard/DashboardFilters/dashboardFiltersSlice';
import {
  getActiveFilterGroupKey,
  getTreeData,
} from '@/utils/methods/specification';

const ACTIVE_ENDPOINT_KEY = 'activeEndpointKey';

const SpecificationTree = ({ specID }: { specID?: string }) => {
  const { orgID, specificationID } = useParams<{
    orgID: string;
    specificationID: string;
  }>();
  const { data: specification } = useGetSpecificationQuery({
    orgID,
    specificationID: specID || specificationID,
  });
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const filters = useAppSelector(dashboardFiltersSelectors.selectAll);
  const activeEndpointKey = getActiveFilterGroupKey(filters);

  const endpoints = specification?.version?.endpoints || [];
  const groupPaths = specification?.version?.groupPaths || [];

  const treeData = getTreeData(endpoints, groupPaths);

  const onSelect: TreeProps['onSelect'] = (_selected, { node }) => {
    const activeEndpointKeySelected = node.selected
      ? undefined
      : String(node.key);
    setActiveEndpointKey(activeEndpointKeySelected);
  };

  const setActiveEndpointKey = (key?: string) => {
    if (key) query.set(ACTIVE_ENDPOINT_KEY, key);
    else query.delete(ACTIVE_ENDPOINT_KEY);
    history.replace({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  if (treeData.length > 0) {
    return (
      <Tree
        showLine
        showIcon
        selectedKeys={activeEndpointKey ? [activeEndpointKey] : []}
        switcherIcon={
          <DownOutlined
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
        }
        expandedKeys={groupPaths}
        autoExpandParent={false}
        onSelect={onSelect}
        treeData={treeData}
      />
    );
  }

  return (
    <Spin spinning={!specification}>
      <section data-testid='show-empty' className='my-28'>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </section>
    </Spin>
  );
};

export default SpecificationTree;
