import { useEffect, useState } from 'react';
import {
  useParams,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import dashboard from './dashboard';
import applications from './applications';
import apis from './apis';
import Integrations from './integrations';
import applicationID from './applications/appID';
import orgSettings from './../orgID/settings';
import {
  setActiveTabKey,
  setTabNavbarItems,
} from '@/components/Navbar/navSlice';
import PostureManagement from './posture-management';
import { useGetOrganisationQuery } from '@/utils/services/organisation/organisation-endpoints';
import useRole from '@/utils/hooks/useRole';
import Reporting from './reporting';
import Billing from '@/components/Billing';
import githubIntegration from './github-integration';

const Org = () => {
  const {
    hasPermissionToListApplication,
    hasPermissionToGetApplication,
    hasPermissionToListApi,
    hasPermissionToListIntegration,
    hasPermissionToListAlerting,
    hasPermissionToListReports,
  } = useRole();

  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);

  const { orgID } = useParams<{ orgID: string }>();
  const { data: organisation } = useGetOrganisationQuery({ orgID });

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.pathname) {
      const pathSplited = location.pathname.split('/');
      const pathLenght = pathSplited.length;
      let key;
      if (pathLenght >= 4 && pathLenght <= 6) {
        key = pathSplited[3];
      } else if (pathLenght <= 8) {
        key = pathSplited[5];
      }

      key = key === 'monitor-alerting' ? 'posture-management' : key;
      dispatch(setActiveTabKey(key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const tabs = [];
    tabs.push({
      key: 'dashboard',
      redirectTo: `/organisations/${orgID}/dashboard`,
      label: 'Dashboard',
    });
    if (hasPermissionToListApplication) {
      tabs.push({
        key: 'applications',
        redirectTo: `/organisations/${orgID}/applications`,
        label: 'Applications',
      });
    }
    if (hasPermissionToListApi) {
      tabs.push({
        key: 'apis',
        redirectTo: `/organisations/${orgID}/apis`,
        label: 'APIs',
      });
    }
    tabs.push({
      key: 'posture-management',
      redirectTo: `/organisations/${orgID}/posture-management`,
      label: 'Posture Management',
    });
    if (hasPermissionToListIntegration) {
      tabs.push({
        key: 'integrations',
        redirectTo: `/organisations/${orgID}/integrations`,
        label: 'Integrations',
      });
    }
    if (hasPermissionToListReports) {
      tabs.push({
        key: 'reporting',
        redirectTo: `/organisations/${orgID}/reporting`,
        label: 'Reporting',
      });
    }
    dispatch(setTabNavbarItems(tabs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  return (
    <>
      <Billing.Modal open={isPlanModalOpen} setIsOpen={setIsPlanModalOpen} />

      <Switch>
        <Route path='/organisations/:orgID/dashboard' component={dashboard} />
        {hasPermissionToGetApplication && (
          <Route
            path='/organisations/:orgID/applications/:appID'
            component={applicationID}
          />
        )}
        <Route path='/organisations/:orgID/settings' component={orgSettings} />
        <Redirect
          from='/organisations/:orgID/subscription/success'
          to='/organisations/:orgID/settings/subscription'
        />
        <Redirect
          from='/organisations/:orgID/subscription/cancelled'
          to='/organisations/:orgID/settings/subscription'
        />
        <Redirect
          from='/organisations/:orgID/billing'
          to='/organisations/:orgID/settings/subscription'
        />
        {hasPermissionToListApplication && (
          <Route
            path='/organisations/:orgID/applications'
            component={applications}
          />
        )}
        {hasPermissionToListApi && (
          <Route path='/organisations/:orgID/apis' component={apis} />
        )}
        {hasPermissionToListIntegration && (
          <Route
            path='/organisations/:orgID/integrations'
            component={Integrations}
          />
        )}
        <Route
          path='/organisations/:orgID/posture-management'
          component={PostureManagement}
        />
        {hasPermissionToListReports && (
          <Route path='/organisations/:orgID/reporting' component={Reporting} />
        )}
        {hasPermissionToListAlerting && (
          <Route
            path='/organisations/:orgID/monitor-alerting'
            component={PostureManagement}
          />
        )}
        <Route
          path='/organisations/:orgID/github-integration'
          component={githubIntegration}
        />
        <Redirect
          from='/organisations/:orgID'
          to='/organisations/:orgID/dashboard'
        />
      </Switch>
    </>
  );
};

export default Org;
