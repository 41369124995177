import FormsModal from '@/components/Modal/FormsModal';
import { Button, Form, FormInstance, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useAddApplicationMutation } from '@/utils/services/application/application-endpoints';
import FieldType from '@/utils/models/FieldType';
import useRole from '@/utils/hooks/useRole';
import { MdWarning } from 'react-icons/md';
import Billing from '@/components/Billing';
import { useGetApplicationQuotasQuery } from '@/utils/services/quota/quota-endpoints';

const CreateApplicationButton = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance: FormInstance<any>;
}) => {
  const { hasPermissionToCreateApplication } = useRole();
  const { orgID } = useParams<{ orgID: string }>();
  const [openCreateAppModal, setOpenCreateAppModal] = useState<boolean>(false);
  const [isBillingOpen, setIsBillingOpen] = useState<boolean>(false);
  const [addApplication, { isLoading: addApplicationIsLoading }] =
    useAddApplicationMutation();
  const { data: appQuota } = useGetApplicationQuotasQuery(
    { orgID },
    { skip: field.sourceFrom?.resource !== 'app' }
  );

  const handleCloseCreateAppModal = () => setOpenCreateAppModal(false);

  const upgradeRequired =
    (appQuota?.current as number) >= (appQuota?.max as number);

  const handleOpenCreateAppModal = () => {
    if (upgradeRequired) {
      setIsBillingOpen(true);
      return;
    }

    setOpenCreateAppModal(true);
  };

  const onSubmitCreateApp = async (data: any) => {
    let dataToSubmit = { ...data };

    if (data.customer_tags && data.customer_tags.length > 0) {
      const tagsKV: { k: string; v: string }[] = [];

      data.customer_tags.forEach((obj: { key: string; value: string }) => {
        tagsKV.push({ k: obj.key, v: obj.value });
      });

      dataToSubmit.customer_tags = tagsKV;
    }

    const app = await addApplication({
      orgID,
      data: dataToSubmit,
    }).unwrap();

    if (formInstance) {
      formInstance.setFieldValue('app_uuid', app.UUID);
      formInstance.validateFields(['app_uuid']);
    }

    handleCloseCreateAppModal();
  };

  const createAppFields: FieldType[] = [
    {
      attribute: 'name',
      name: 'Application Name',
      placeholder: 'my-app',
      validation: {
        required: true,
        regex: `^[a-zA-Z0-9][a-zA-Z0-9-\\s/]+[a-zA-Z0-9]$`,
        regex_description:
          'The name must start and end with a letter or number, be between 3 and 128 characters long, and in between, it can contain letters, numbers, hyphens, spaces, or slashes.',
      },
      inputType: 'text',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'owners',
      name: 'Owners',
      inputType: 'multitext',
      validation: { maxSize: 20 },
    },
    {
      attribute: 'customer_tags',
      name: 'Custom Tags',
      inputType: 'map',
      validation: { max: 10 },
      update: true,
      value: {},
      addString: 'Tag',
    },
    {
      attribute: 'propagate_tags',
      name: 'Propagate Custom Tags',
      inputType: 'switch',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'propagate_owners',
      name: 'Propagate Owners',
      inputType: 'switch',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
  ];

  const showCreateApplicationButton =
    hasPermissionToCreateApplication &&
    ((field.sourceFrom?.resource === 'app' && !field.disabled) ||
      field.allowCreate);

  if (showCreateApplicationButton) {
    return (
      <>
        <Billing.Modal
          open={isBillingOpen}
          setIsOpen={() => setIsBillingOpen(false)}
          titleText='You have reached your maximum amount of created applications.'
        />

        <FormsModal
          title='Create Application'
          confirmLoading={addApplicationIsLoading}
          handleCloseModal={handleCloseCreateAppModal}
          handleSubmit={onSubmitCreateApp}
          showModal={openCreateAppModal}
          fields={createAppFields}
        />

        <Form.Item
          label={field.name === undefined ? field.name : ' '}
          className='ml-1'
        >
          <Button
            onClick={handleOpenCreateAppModal}
            disabled={field.disabled}
            className='flex gap-2 items-center'
          >
            Create{' '}
            {upgradeRequired && (
              <Tooltip title='Upgrade Required'>
                <MdWarning style={{ color: '#FFE25E' }} size={16} />
              </Tooltip>
            )}
          </Button>
        </Form.Item>
      </>
    );
  }

  return <></>;
};

export default CreateApplicationButton;
