/* eslint-disable no-template-curly-in-string */
import FieldType from '@/utils/models/FieldType';
import { Rule } from 'antd/lib/form';
import { AutoComplete, Form, Spin } from 'antd';
import { getColSize, getFieldSize } from './const/functions';
import { useGetApiQuery } from '@/utils/services/api/api-endpoints';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLazyAutocompleteFilterOptionsQuery } from '@/utils/services/resource/resource-endpoints';
import { useDebounceValue } from 'usehooks-ts';

/* eslint-disable no-template-curly-in-string */
const AutoCompleteField = ({ field }: { field: FieldType }) => {
  const [search, setSearch] = useState<string>();
  const [debouncedValue, setDebouncedValue] = useDebounceValue('', 500);
  const [options, setOptions] = useState<
    { label: string; value: string; avatar?: string }[]
  >([]);
  const { orgID, apiID, appID } = useParams<{
    orgID: string;
    apiID?: string;
    appID?: string;
  }>();

  const [
    autocompleteFilterOptions,
    { isLoading: isAutocompleteLoading, isFetching: isAutocompleteFetching },
  ] = useLazyAutocompleteFilterOptionsQuery();

  const { data: apiData, isLoading: isApiLoading } = useGetApiQuery(
    { orgID, apiID: apiID || '' },
    { skip: !apiID || field.sourceFrom?.resource !== 'api-endpoints' }
  );

  useEffect(() => setSearch(debouncedValue), [debouncedValue, setSearch]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        let data: any[] = [];
        if (
          field.sourceFrom?.resource === 'api-tag-keys' ||
          field.sourceFrom?.resource === 'api-tag-values'
        ) {
          if (field.key_attr) {
            data = await autocompleteFilterOptions(
              {
                orgID,
                resource: 'api',
                field: field.key_attr,
                query: search || '',
                apiID,
                appID,
              },
              true
            ).unwrap();
          } else if (field.value_attr) {
            data = await autocompleteFilterOptions(
              {
                orgID,
                resource: 'api',
                field: field.value_attr,
                query: search || '',
                apiID,
                appID,
              },
              true
            ).unwrap();
          }
        } else if (field.sourceFrom?.resource === 'api-endpoints') {
          data = apiData?.ft_integration?.discovered_endpoints || [];
        }
        setOptions(
          data.map((opt) => ({
            label: opt.label || opt,
            value: String(opt.value || opt),
          }))
        );
      } catch (error) {
        console.error('Failed to fetch autocomplete options:', error);
      }
    };

    fetchOptions();
  }, [orgID, apiID, appID, search, field, autocompleteFilterOptions, apiData]);

  const rules: Rule[] = field.rules || [];

  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }
  if (field?.validation?.regex) {
    rules.push({
      pattern:
        typeof field.validation.regex === 'string'
          ? new RegExp(`${field.validation.regex}`, 'g')
          : field.validation.regex,
      message: field?.validation?.regex_description || '${label} is invalid',
    });
  }

  const loading =
    isApiLoading || isAutocompleteFetching || isAutocompleteLoading;

  return (
    <div className={`${getColSize(field.colSize || 'extralarge')}`}>
      <Form.Item
        name={field.attribute}
        label={field.name}
        rules={rules}
        tooltip={field.description}
        className={getFieldSize(field.fieldSize || 'middle')}
      >
        <AutoComplete
          maxLength={field.validation?.max || 128}
          placeholder={field.placeholder}
          disabled={field.disabled}
          options={options}
          notFoundContent={loading ? <Spin size='small' /> : undefined}
          onSearch={(v) => setDebouncedValue(v)}
          dropdownRender={(menu) => (
            <Spin spinning={options.length > 0 && loading}>{menu}</Spin>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default AutoCompleteField;
