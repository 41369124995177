import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Divider, Form, Modal, Select, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import {
  FieldByResource,
  ResourceType,
  useLazyAutocompleteFilterOptionsQuery,
  useListFieldsByResourceQuery,
} from '@/utils/services/resource/resource-endpoints';
import useFiltersQueryParam from '@/utils/hooks/QueryParam/useFiltersQueryParam';
import DebounceSelectField, {
  ValueType,
} from '@/components/Form/DebounceSelectField';
import ListFilterButton from './ListFilterButton';
import ListFilterSelectField from './ListFilterSelectField';
import { MdClose } from 'react-icons/md';
import ListFilterDateTimeSelector from './ListFilterDateTimeSelector';
import { FieldsData } from '@/utils/models/FieldType';
import useStaticDateTimeQueryParam, {
  StaticDateTime,
} from '@/utils/hooks/QueryParam/useStaticDateTimeQueryParam';
import MapField from '@/components/Form/FieldComponents/MapField';
import { nanoid } from '@reduxjs/toolkit';

interface ListFilterProps {
  resource: ResourceType;
  showDateTimeSelector?: boolean;
  onAdd?: (filters: { [key: string]: string[] }) => void;
  prevDef?: boolean;
  manuallyAddedFilters?: FieldsData[];
  renderOptionLabel?: (options: ValueType[]) => JSX.Element[];
  renderTags?: boolean;
  isLinkedRepos?: boolean;
}

type SelectedField = FieldByResource | { field: FieldByResource; id: string };

function isApiField(
  field: SelectedField
): field is { field: FieldByResource; id: string } {
  return (field as { field: FieldByResource; id: string }).id !== undefined;
}

const ListFilter = ({
  resource,
  showDateTimeSelector = false,
  onAdd,
  prevDef,
  manuallyAddedFilters,
  renderOptionLabel,
  renderTags,
  isLinkedRepos = false,
}: ListFilterProps) => {
  const { orgID, appID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
  }>();

  const [open, setOpen] = useState<boolean>(false);

  const { data: fieldsData, isLoading: isListFieldsLoading } =
    useListFieldsByResourceQuery(
      {
        orgID,
        resource,
      },
      { skip: !open }
    );

  const [selectedOperators, setSelectedOperators] = useState<
    Record<string, Record<string, string>>
  >({});
  const [form] = Form.useForm();
  const [selectedFields, setSelectedFields] = useState<SelectedField[]>([]);
  const [type, setType] = useState<string | null>(null);
  const [autocompleteFilterOptions] = useLazyAutocompleteFilterOptionsQuery();
  const [dateTimeTemporary, setDateTimeTemporary] = useState<StaticDateTime>();

  const { dateTime, setDateTime } = useStaticDateTimeQueryParam();
  const { filters, setFilters } = useFiltersQueryParam();

  const resetFiltersResource = useCallback(() => {
    setFilters();
    setDateTime();
  }, [setFilters, setDateTime]);

  const counter = useMemo(() => {
    const filtersCounter = filters.reduce((total, filter) => {
      const valuesCount = filter?.values?.length || 0;
      const kvValuesCount = filter?.kv_values?.length || 0;
      return total + valuesCount + kvValuesCount;
    }, 0);

    return dateTime && Object.keys(dateTime).length > 0
      ? filtersCounter + 1
      : filtersCounter;
  }, [filters, dateTime]);

  const disableConfirmButton =
    dateTimeTemporary?.mode === 'fixed' &&
    (!dateTimeTemporary.start || !dateTimeTemporary.end);

  useEffect(() => {
    if (open) {
      handleFillFormValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, fieldsData, filters]);

  const openModal = () => {
    setOpen(true);
  };

  const handleFillFormValue = () => {
    const tempFields: SelectedField[] = [];
    const formData: any = {};

    const filtersToLoop =
      manuallyAddedFilters && manuallyAddedFilters?.length > 0
        ? manuallyAddedFilters
        : filters;

    filtersToLoop.forEach((filter) => {
      const field = fieldsData?.find((f) => f.value === filter.field);

      if (field) {
        if (resource === 'api') {
          const uniqueId = nanoid();
          const selectedField = { field, id: uniqueId };
          tempFields.push(selectedField);

          if (filter.kv_values && filter.kv_values.length > 0) {
            formData[`${field.value}-${uniqueId}`] = filter.kv_values.map(
              (kv) => ({
                key: kv.k,
                value: kv.v,
              })
            );
          } else if (filter.values) {
            formData[`${field.value}-${uniqueId}`] = filter.values;
          }

          if (filter.operator) {
            formData[`${field.value}-${uniqueId}?operator`] = {
              value: filter.operator,
              label: generateOperatorLabel(filter.operator),
            };

            setSelectedOperators((prev) => ({
              ...prev,
              [field.value]: {
                ...prev[field.value],
                [uniqueId]: filter.operator || '',
              },
            }));
          } else if (field.operators && field.operators.length > 0) {
            const defaultOperator = field.operators[0];
            formData[`${field.value}-${uniqueId}?operator`] = {
              value: defaultOperator,
              label: generateOperatorLabel(defaultOperator),
            };

            setSelectedOperators((prev) => ({
              ...prev,
              [field.value]: {
                ...prev[field.value],
                [uniqueId]: defaultOperator,
              },
            }));
          }
        } else {
          tempFields.push(field);

          if (filter.kv_values && filter.kv_values.length > 0) {
            formData[field.value] = filter.kv_values.map((kv) => ({
              key: kv.k,
              value: kv.v,
            }));
          } else {
            formData[field.value] = filter.values;
          }

          if (filter.operator) {
            formData[`${field.value}?operator`] = {
              value: filter.operator,
              label: generateOperatorLabel(filter.operator),
            };
          } else if (field.operators && field.operators.length > 0) {
            formData[`${field.value}?operator`] = {
              value: field.operators[0],
              label: generateOperatorLabel(field.operators[0]),
            };
          }
        }
      }
    });

    setSelectedFields(tempFields);
    form.setFieldsValue(formData);
    setDateTimeTemporary(dateTime);
  };

  const closeModal = () => {
    setOpen(false);
    clearFields();
  };

  const clearFields = () => {
    form.resetFields();
    setSelectedFields([]);
    setSelectedOperators({});
  };

  const clearFilter = () => {
    resetFiltersResource();
  };

  const confirmFilters = (values: any) => {
    const transformedFilters = Object.keys(values).reduce((acc: any, key) => {
      if (key.includes('?operator')) return acc;

      let baseKey = key;
      let operator: string | undefined;

      if (resource === 'api') {
        const parts = key.split('-');
        baseKey = parts[0];
        const idPart = parts.slice(1).join('-');
        const operatorKey = `${baseKey}-${idPart}?operator`;
        operator = values[operatorKey]?.value || values[operatorKey];
      } else {
        baseKey = key.replace(/-$/, '');
        operator = 'is-one-of';
      }

      if (!operator) {
        console.warn(`Operator for field ${key} is undefined or invalid`);
        return acc;
      }

      if (Array.isArray(values[key]) && values[key][0]?.key) {
        const kvValues = values[key].map((item: any) => ({
          k: item.key,
          v: item.value,
        }));
        acc.push({ field: baseKey, kv_values: kvValues, operator });
      } else {
        acc.push({ field: baseKey, operator, values: values[key] });
      }

      return acc;
    }, []);

    setFilters(transformedFilters);
    setDateTime(dateTimeTemporary);
    closeModal();
  };

  const onAddAndClose = (values: any) => {
    if (onAdd) {
      onAdd(values);
      closeModal();
    }
  };

  const handleFetchOptions = async (field: string, query: string) => {
    const options = await autocompleteFilterOptions(
      {
        orgID,
        resource,
        field,
        query,
        apiID,
        appID,
      },
      true
    ).unwrap();

    return options;
  };

  const addField = (newFieldValue: string) => {
    const field = fieldsData?.find((f) => f.value === newFieldValue);
    if (field) {
      if (resource === 'api') {
        const usedOperators = selectedOperators[field.value] || {};
        const availableOperators = field.operators?.filter(
          (op) => !Object.values(usedOperators).includes(op)
        );
        const selectedOperator =
          availableOperators && availableOperators.length > 0
            ? availableOperators[0]
            : null;

        if (selectedOperator) {
          const uniqueId = nanoid();
          form.setFieldValue(`${field.value}-${uniqueId}?operator`, {
            value: selectedOperator,
            label: generateOperatorLabel(selectedOperator),
          });
          setSelectedOperators((prev) => ({
            ...prev,
            [field.value]: {
              ...usedOperators,
              [uniqueId]: selectedOperator,
            },
          }));

          setSelectedFields((prev) => [...prev, { field, id: uniqueId }]);
        }
      } else {
        form.setFieldValue(`${field.value}?operator`, {
          value: field.operators?.[0],
          label: generateOperatorLabel(field.operators?.[0] || ''),
        });
        setSelectedFields((prev) => [...prev, field]);
      }
    }
  };

  const editField = (selectedField: SelectedField, newFieldValue: string) => {
    if (isApiField(selectedField)) {
      const uniqueId = selectedField.id;
      const oldFieldValue = selectedField.field.value;
      const field = fieldsData?.find((f) => f.value === newFieldValue);

      if (field) {
        const copy = [...selectedFields] as {
          field: FieldByResource;
          id: string;
        }[];
        const oldFieldIndexSelected = copy.findIndex((f) => f.id === uniqueId);
        if (oldFieldIndexSelected > -1) {
          copy[oldFieldIndexSelected] = { field, id: uniqueId };

          // Clean up the selected operators for the old field
          const updatedOperators = { ...selectedOperators };
          delete updatedOperators[oldFieldValue][uniqueId];
          if (Object.keys(updatedOperators[oldFieldValue]).length === 0) {
            delete updatedOperators[oldFieldValue];
          }

          const usedOperators = updatedOperators[field.value] || {};
          const availableOperators = field.operators?.filter(
            (op) => !Object.values(usedOperators).includes(op)
          );
          const defaultOperator =
            availableOperators && availableOperators.length > 0
              ? availableOperators[0]
              : field.operators?.[0];

          if (defaultOperator) {
            form.setFieldValue(`${field.value}-${uniqueId}?operator`, {
              value: defaultOperator,
              label: generateOperatorLabel(defaultOperator),
            });
            updatedOperators[field.value] = {
              ...usedOperators,
              [uniqueId]: defaultOperator,
            };
          }

          setSelectedOperators(updatedOperators);
          setSelectedFields(copy);
        }
      }
    } else {
      const oldFieldValue = selectedField.value;
      const field = fieldsData?.find((f) => f.value === newFieldValue);

      if (field) {
        const copy = [...selectedFields] as FieldByResource[];
        const oldFieldIndexSelected = copy.findIndex(
          (f) => f.value === oldFieldValue
        );
        if (oldFieldIndexSelected > -1) {
          copy[oldFieldIndexSelected] = field;

          // Clean up the selected operators for the old field
          const updatedOperators = { ...selectedOperators };
          delete updatedOperators[oldFieldValue];

          const usedOperators = updatedOperators[field.value] || {};
          const availableOperators = field.operators?.filter(
            (op) => !Object.values(usedOperators).includes(op)
          );
          const defaultOperator =
            availableOperators && availableOperators.length > 0
              ? availableOperators[0]
              : field.operators?.[0];

          if (defaultOperator) {
            form.setFieldValue(`${field.value}?operator`, {
              value: defaultOperator,
              label: generateOperatorLabel(defaultOperator),
            });
            updatedOperators[field.value] = {
              ...usedOperators,
            };
          }

          setSelectedOperators(updatedOperators);
          setSelectedFields(copy);
        }
      }
    }

    setType(null);
  };

  const deleteField = (selectedField: SelectedField) => {
    if (isApiField(selectedField)) {
      const uniqueId = selectedField.id;
      const updatedOperators = { ...selectedOperators };
      if (updatedOperators[selectedField.field.value]) {
        delete updatedOperators[selectedField.field.value][uniqueId];
      }
      setSelectedOperators(updatedOperators);
      form.setFieldValue(`${selectedField.field.value}-${uniqueId}`, undefined);
      const copy = [...selectedFields] as {
        field: FieldByResource;
        id: string;
      }[];
      const oldFieldIndexSelected = copy.findIndex((f) => f.id === uniqueId);
      if (oldFieldIndexSelected > -1) {
        copy.splice(oldFieldIndexSelected, 1);
      }
      setSelectedFields(copy);
    } else {
      form.setFieldValue(selectedField.value, undefined);
      const copy = [...selectedFields] as FieldByResource[];
      const oldFieldIndexSelected = copy.findIndex(
        (f) => f.value === selectedField.value
      );
      if (oldFieldIndexSelected > -1) {
        copy.splice(oldFieldIndexSelected, 1);
      }
      setSelectedFields(copy);

      setSelectedOperators((prev) => {
        const updated = { ...prev };
        delete updated[selectedField.value];
        return updated;
      });
    }
  };

  const generateOperatorLabel = (operator: string) => {
    const cleanString = operator.replace(/-/g, ' ');

    if (cleanString.endsWith(' kv')) return cleanString.replace(' kv', '');

    return cleanString;
  };

  const handleOperatorChange = (
    fieldValue: string,
    fieldId: string,
    operator: string
  ) => {
    setSelectedOperators((prev) => {
      const updated = { ...prev };
      if (!updated[fieldValue]) {
        updated[fieldValue] = {};
      }
      updated[fieldValue][fieldId] = operator;
      return updated;
    });
  };

  const getAvailableOperators = (field: FieldByResource, id: string) => {
    const usedOperators = Object.values(selectedOperators[field.value] || {});

    return (
      field.operators?.map((operator) => ({
        value: operator,
        label: generateOperatorLabel(operator),
        disabled: usedOperators.includes(operator),
      })) || []
    );
  };

  const availableFieldOptions = useMemo(() => {
    if (!fieldsData) return [];

    const selectedFieldValues = selectedFields.map((sf) =>
      isApiField(sf) ? sf.field.value : sf.value
    );

    if (resource === 'api') {
      return fieldsData.map((f) => {
        const usedOperators = selectedOperators[f.value] || {};
        const remainingOperators = f.operators?.filter(
          (op) => !Object.values(usedOperators).includes(op)
        );

        return {
          label: f.key,
          value: f.value,
          disabled: !remainingOperators || remainingOperators.length === 0,
        };
      });
    } else {
      return fieldsData.map((f) => ({
        label: f.key,
        value: f.value,
        disabled: selectedFieldValues.includes(f.value),
      }));
    }
  }, [fieldsData, selectedOperators, resource, selectedFields]);

  return (
    <>
      <Modal
        style={{ top: 15 }}
        maskClosable={false}
        maskStyle={{ backdropFilter: 'blur(5px)' }}
        forceRender
        width={1000}
        okText='Confirm'
        open={open}
        title={
          <div className='flex items-center gap-x-4'>
            <span className='material-symbols-outlined text-lg'>tune</span>
            <span>Filters</span>
          </div>
        }
        onCancel={closeModal}
        footer={
          <div className='flex justify-between'>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              disabled={disableConfirmButton}
              type='primary'
              onClick={() => form.submit()}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <Spin spinning={isListFieldsLoading}>
          <div
            className='flex flex-col overflow-auto -m-6 p-6'
            style={{
              maxHeight: '78vh',
              paddingLeft: '7rem',
              paddingRight: '7rem',
            }}
          >
            <Form
              form={form}
              layout='horizontal'
              onFinish={onAdd ? onAddAndClose : confirmFilters}
              className={`flex flex-col gap-8 ${
                selectedFields.length > 0 ? 'mb-4' : ''
              }`}
            >
              {showDateTimeSelector && (
                <ListFilterDateTimeSelector
                  dateTime={dateTimeTemporary}
                  setDateTime={setDateTimeTemporary}
                />
              )}

              {selectedFields.map((selectedField) => {
                const field = isApiField(selectedField)
                  ? selectedField.field
                  : selectedField;
                const id = isApiField(selectedField) ? selectedField.id : '';

                return (
                  <div
                    key={id || field.value}
                    className='flex gap-x-4 items-center'
                  >
                    <div className='flex gap-x-2 items-center w-full self-baseline justify-between'>
                      <div className='flex items-center gap-1 w-1/3'>
                        <button
                          onClick={() => deleteField(selectedField)}
                          className='hover:bg-gray-400 hover:text-white 
                          transition-all rounded-full'
                        >
                          <MdClose size={18} />
                        </button>

                        <ListFilterSelectField
                          disabled={isLinkedRepos ? false : undefined}
                          value={field.value}
                          onChange={(value) => editField(selectedField, value)}
                          options={availableFieldOptions.map((opt) => ({
                            label: opt.label,
                            value: opt.value,
                            disabled: opt.disabled,
                          }))}
                        />
                      </div>

                      {resource === 'api' ? (
                        <Form.Item
                          name={`${field.value}-${id}?operator`}
                          label=''
                          className='mb-0 w-1/3'
                        >
                          <Select
                            className='w-full'
                            labelInValue
                            options={getAvailableOperators(field, id)}
                            onChange={(value) =>
                              handleOperatorChange(
                                field.value,
                                id,
                                value?.value
                              )
                            }
                          />
                        </Form.Item>
                      ) : (
                        <span className='font-semibold whitespace-nowrap'>
                          is one of
                        </span>
                      )}
                    </div>

                    {(field.operators?.includes('is-one-of-kv') ||
                      field.operators?.includes('is-not-one-of-kv')) && (
                      <MapField
                        field={{
                          attribute: isApiField(selectedField)
                            ? `${field.value}-${id}`
                            : field.value,
                          autocomplete: true,
                          key_attr: field.key_attr,
                          value_attr: field.value_attr,
                          validation: {
                            min: 1,
                            max: 10,
                          },
                        }}
                        formInstance={form}
                      />
                    )}

                    {!field.operators?.includes('is-one-of-kv') &&
                      !field.operators?.includes('is-not-one-of-kv') && (
                        <Form.Item
                          className='w-full col-span-2 mb-0'
                          key={
                            isApiField(selectedField)
                              ? `${field.value}-${id}`
                              : field.value
                          }
                          name={
                            isApiField(selectedField)
                              ? `${field.value}-${id}`
                              : field.value
                          }
                          rules={[
                            {
                              required: true,
                              message: `Please select a value for ${field.key}`,
                            },
                          ]}
                        >
                          <DebounceSelectField
                            showSearch
                            mode='tags'
                            placeholder={`Select ${field.key}`}
                            fetchOptions={(value) =>
                              handleFetchOptions(field.value, value)
                            }
                          />
                        </Form.Item>
                      )}
                  </div>
                );
              })}
            </Form>

            {availableFieldOptions.length > 0 && (
              <div>
                {(selectedFields.length > 0 || showDateTimeSelector) && (
                  <Divider />
                )}

                <div className='grid grid-cols-3 gap-x-4'>
                  <ListFilterSelectField
                    label='Select Field'
                    placeholder='Select field'
                    value={type}
                    onChange={(value) => {
                      addField(value);
                      setType(null);
                    }}
                    options={availableFieldOptions.map((opt) => ({
                      label: opt.label,
                      value: opt.value,
                      disabled: opt.disabled,
                    }))}
                  />
                </div>
              </div>
            )}
          </div>
        </Spin>
      </Modal>

      <ListFilterButton
        openModal={openModal}
        counter={counter}
        clearFilter={clearFilter}
        prevDef={prevDef}
      />
    </>
  );
};

export default ListFilter;
