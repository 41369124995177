import { FormInstance } from 'antd';
import FieldType from '@/utils/models/FieldType';
import Fields from './FieldComponents';

export class FieldBuilder {
  constructor(private formInstance: FormInstance<any>) {}

  getFormItem = (field: FieldType) => {
    switch (field.inputType) {
      case 'tab': {
        return (
          <Fields.Tabs
            key={field.attribute}
            field={field}
            fieldBuilder={this}
          />
        );
      }
      case 'step-by-step': {
        return (
          <Fields.StepByStep
            key={field.attribute}
            field={field}
            fieldBuilder={this}
          />
        );
      }
      case 'select': {
        return (
          <Fields.SelectField
            mode={field.mode}
            formInstance={this.formInstance}
            key={field.attribute}
            field={field}
          />
        );
      }
      case 'multiselect': {
        return (
          <Fields.SelectField
            mode='multiple'
            formInstance={this.formInstance}
            key={field.attribute}
            field={field}
          />
        );
      }
      case 'text': {
        return (
          <Fields.InputText
            formInstance={this.formInstance}
            key={field.attribute}
            field={field}
          />
        );
      }
      case 'autocomplete': {
        return <Fields.AutoComplete key={field.attribute} field={field} />;
      }
      case 'multitext': {
        return (
          <Fields.MultiInputText
            key={field.attribute}
            field={field}
            formInstance={this.formInstance}
          />
        );
      }
      case 'textarea': {
        return <Fields.TextArea key={field.attribute} field={field} />;
      }
      case 'number': {
        return <Fields.InputNumber key={field.attribute} field={field} />;
      }
      case 'seconds': {
        return (
          <Fields.InputSeconds
            key={field.attribute}
            inputField={field}
            formInstance={this.formInstance}
          />
        );
      }
      case 'toggle': {
        return <Fields.Toggle key={field.attribute} field={field} />;
      }
      case 'switch': {
        return <Fields.Switch key={field.attribute} field={field} />;
      }
      case 'button': {
        return (
          <Fields.Button
            key={field.name}
            field={field}
            formInstance={this.formInstance}
          />
        );
      }
      case 'label': {
        return <Fields.Label key={field.attribute} field={field} />;
      }
      case 'header': {
        return <Fields.Header key={field.attribute} field={field} />;
      }
      case 'code-block': {
        return <Fields.CodeBlock key={field.attribute} field={field} />;
      }
      case 'code-editor': {
        return (
          <Fields.CodeEditor
            key={field.attribute}
            field={field}
            formInstance={this.formInstance}
          />
        );
      }
      case 'map': {
        return (
          <Fields.Map
            key={field.attribute}
            field={field}
            formInstance={this.formInstance}
          />
        );
      }
      case 'input_app_token': {
        return (
          <Fields.CreateSecretToken
            key={field.attribute}
            field={field}
            formInstance={this.formInstance}
          />
        );
      }
      case 'upload-file': {
        return <Fields.UploadFile key={field.attribute} field={field} />;
      }
      case 'radio-group': {
        return <Fields.RadioGroup key={field.attribute} field={field} />;
      }
      case 'detailed-radio-group': {
        return (
          <Fields.DeatiledRadioGroup key={field.attribute} field={field} />
        );
      }
      case 'connect-slack': {
        return <Fields.ConnectSlack key={field.attribute} field={field} />;
      }
      case 'authenticate-gitlab': {
        return (
          <Fields.AuthenticateGitLab
            key={field.attribute}
            field={field}
            formInstance={this.formInstance}
          />
        );
      }
      case 'ai-consent': {
        return (
          <Fields.Consent
            key={field.attribute}
            field={field}
            formInstance={this.formInstance}
          />
        );
      }
    }
    return <></>;
  };
}
