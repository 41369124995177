import moment from 'moment';
import { getLogoPathByName } from '../constants/listOfImagesPaths';
import { ApiClass } from './Api';

export interface RequestIPDetails {
  as?: string;
  asname?: string;
  city?: string;
  continent?: string;
  continentCode?: string;
  country?: string;
  countryCode?: string;
  currency?: string;
  district?: string;
  geo_point?: string;
  hosting?: true;
  isp?: string;
  lat?: number;
  lon?: number;
  mobile?: boolean;
  offset?: number;
  org?: string;
  proxy?: false;
  region?: string;
  regionName?: string;
  timezone?: string;
  zip?: string;
}

interface RequestBody {
  body: string;
  domain: string;
  headers: {
    'Accept-Encoding'?: string[];
    Connection?: string[];
    Host?: string[];
    'User-Agent'?: string[];
  };
  httpProtocol: string;
  ip: string;
  ipDetails: RequestIPDetails;
  method: string;
  path: string;
  pathParameters: any;
  queryStrings: any;
  resource: string;
  schema: string;
  uri: string;
  userAgentExtract: {
    browser?: string;
    browser_version?: string;
    device?: string;
    device_brand?: null;
    device_model?: null;
    os?: string;
    os_verison?: string;
  };
}

interface RequestMetrics {
  combined_header_size?: number;
  combined_payload_size?: number;
  combined_size?: number;
  request_header_size?: number;
  request_count?: number;
  request_payload_size?: number;
  response_header_size?: number;
  response_payload_size?: number;
}

interface RequestResponse {
  body: string;
  headers: {
    'Content-Length'?: string[];
    'Content-Type'?: string[];
  };
  statusCode: number;
}

interface RequestMetadata {
  accountId?: string;
  apiId?: string;
  api_version?: string;
  caller?: string;
  customDomainBasePath?: string;
  domainPrefix?: string;
  integrationStatus?: string;
  region?: string;
  requestId?: string;
  stage?: string;
  status?: string;
  user?: string;
}

export type RequestSequencesType = {
  ip?: {
    history_length?: number;
    history?: string[];
    interval?: number;
    sequence_id?: string;
    previous_log?: {
      UUID?: string;
      dateCreated?: number;
      method?: string;
      path?: string;
      resource?: string;
    };
  };
};

export interface RequestInterface {
  UUID: string;
  apiUUID: string;
  appUUID: string;
  associatedUser: string;
  dateCreated: number;
  dateCreatedInDatetime: string;
  executionTime: number;
  itemType: string;
  logSizeBytes: number;
  metrics: RequestMetrics;
  log_store_ver?: string;
  orgUUID: string;
  plan: string;
  processType: string;
  request: RequestBody;
  response: RequestResponse;
  source: string;
  tags: string[];
  tokenUUID: string;
  version: string;
  metadata?: RequestMetadata;
  key_list?: string[];
  observations?: string[];
  logAction?: string;
  sequences?: RequestSequencesType;
}

export type RequestExtraDataType = {
  api?: ApiClass;
};

export class RequestClass implements RequestInterface {
  UUID: string;
  apiUUID: string;
  appUUID: string;
  associatedUser: string;
  dateCreated: number;
  dateCreatedInDatetime: string;
  executionTime: number;
  itemType: string;
  logSizeBytes: number;
  metrics: RequestMetrics;
  orgUUID: string;
  plan: string;
  processType: string;
  request: RequestBody;
  response: RequestResponse;
  source: string;
  tags: string[];
  tokenUUID: string;
  version: string;
  metadata?: RequestMetadata;
  key_list?: string[];
  observations?: string[];
  logAction?: string;
  sequences?: RequestSequencesType;

  key?: string;
  originalData: RequestInterface;
  api?: ApiClass;

  constructor(data: RequestInterface, extraData?: RequestExtraDataType) {
    this.originalData = data;
    this.key = data.UUID;
    this.UUID = data.UUID;
    this.apiUUID = data.apiUUID;
    this.appUUID = data.appUUID;
    this.associatedUser = data.associatedUser;
    this.dateCreated = data.dateCreated;
    this.dateCreatedInDatetime = data.dateCreatedInDatetime;
    this.executionTime = data.executionTime;
    this.itemType = data.itemType;
    this.logSizeBytes = data.logSizeBytes;
    this.metrics = data.metrics;
    this.orgUUID = data.orgUUID;
    this.plan = data.plan;
    this.processType = data.processType;
    this.request = data.request;
    this.response = data.response;
    this.source = data.source;
    this.tags = data.tags;
    this.tokenUUID = data.tokenUUID;
    this.version = data.version;
    this.metadata = data.metadata;
    this.key_list = data.key_list;
    this.logAction = data.logAction;
    this.sequences = data.sequences;

    this.api = extraData?.api;
  }

  get mapDataToDownload() {
    return {
      ID: this.UUID,
      Request: this.request,
      Response: this.response,
      Metadata: this.metadata,
      'Application ID': this.appUUID,
      'Organization ID': this.orgUUID,
      'Execution Time': this.executionTime,
      'Creation Time': this.created,
      'Date Added in Microseconds': this.dateCreatedInDatetime,
      'Key List': this.key_list,
      'Log Size Bites': this.logSizeBytes,
      Observations: this.observations,
      Plan: this.plan,
      Source: this.source,
      Tags: this.tags,
      'Token ID': this.tokenUUID,
      'Associated User': this.associatedUser,
      'Log Action': this.logAction,
      Metrics: this.metrics,
      'Process Type': this.processType,
      Version: this.version,
    };
  }

  get type(): string {
    return this?.itemType || this.source || 'firetail';
  }

  get typeLabel(): string {
    return ApiClass.getTypeLabel(this.type);
  }

  get avatar(): string {
    return getLogoPathByName(this.type);
  }

  get created(): string {
    return moment.utc(this.dateCreated).local().format('D/MM/Y hh:mm:ss.SS A');
  }

  get createdFromNow(): string {
    return moment.utc(this.dateCreated).local().fromNow();
  }

  static isPrivateIP(ip: string) {
    const parts = ip.split('.').map(Number);

    // Check if the IP falls within private ranges
    if (
      parts[0] === 10 ||
      (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31) ||
      (parts[0] === 192 && parts[1] === 168)
    ) {
      return true; // Private IP
    }

    return false; // Public IP
  }
}
