import FieldType from '@/utils/models/FieldType';
import { Form, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';
import { getFieldSize } from './const/functions';
import { useParams } from 'react-router-dom';

/* eslint-disable no-template-curly-in-string */
const InputNumberField = ({ field }: { field: FieldType }) => {
  const { myIntegrationID } = useParams<{ myIntegrationID: string }>();
  const isEdit = !!myIntegrationID;

  const rules: Rule[] = [];
  let isRequired = field?.validation?.required || false;
  if (isEdit && field?.validation?.required_on_update !== undefined) {
    isRequired = field?.validation?.required_on_update;
  }
  rules.push({
    required: isRequired,
    message: '${label} is required',
  });
  if (field?.validation?.min) {
    rules.push({
      type: 'number',
      min: field.validation.min,
      message: '${label} must be greater than ${min}',
    });
  }
  if (field?.validation?.max) {
    rules.push({
      type: 'number',
      max: field.validation.max,
      message: '${label} must be less than ${max}',
    });
  }
  return (
    <Form.Item
      name={field.attribute}
      label={field.name}
      rules={rules}
      tooltip={field.description}
      className={getFieldSize(field.fieldSize || 'small')}
    >
      <InputNumber
        style={{ width: '100%' }}
        placeholder={field.placeholder}
        disabled={field.disabled}
      />
    </Form.Item>
  );
};

export default InputNumberField;
