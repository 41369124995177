import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MdAdd, MdSave, MdUpload, MdWarning } from 'react-icons/md';
import {
  filterSetAll,
  dashboardFiltersSelectors,
} from '@/components/Dashboard/DashboardFilters/dashboardFiltersSlice';
import { Button, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import DashboardFiltersSaveLoadModal from '../DashboardFiltersSaveLoadModal';
import useRole from '@/utils/hooks/useRole';
import { selectDashboardFiltersGroups } from '../dashboardFiltersGroupsSlice';
import { useParams } from 'react-router-dom';
import List from '@/components/List';
import Billing from '@/components/Billing';
import { useGetSavedFiltersQuotasQuery } from '@/utils/services/quota/quota-endpoints';

interface DashboardFiltersGroupsFooterProps {
  disable?: boolean;
  dark?: boolean;
  handleAddFilterGroup?: () => void;
  showSaveLoad?: boolean;
}

const DashboardFiltersGroupsFooter = ({
  disable,
  dark = false,
  handleAddFilterGroup,
  showSaveLoad = true,
}: DashboardFiltersGroupsFooterProps) => {
  const { orgID } = useParams<{
    orgID: string;
  }>();
  const {
    hasPermissionToCreateFilterConfiguration,
    hasPermissionToDeleteFilterConfiguration,
  } = useRole();
  const dispatch = useAppDispatch();
  const filtersGroups = useAppSelector(selectDashboardFiltersGroups);
  const myFilters = useAppSelector(dashboardFiltersSelectors.selectAll);
  const filtersDisabled = myFilters.filter((f) => f.disabled);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formToShow, setFormToShow] = useState<'save' | 'load'>();
  const [isPlanModalOpen, setIsPlanModalOpen] = useState<boolean>(false);
  const { data: savedFiltersQuota, isLoading: isSavedFiltersQuotaLoading } =
    useGetSavedFiltersQuotasQuery({
      orgID,
    });

  const upgradeRequired =
    savedFiltersQuota &&
    (savedFiltersQuota.current as number) >= (savedFiltersQuota?.max as number);

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete all filters?',
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteAllFilters();
        return Promise.resolve();
      },
    });
  };

  const handleDeleteAllFilters = () => {
    dispatch(filterSetAll(filtersDisabled));
  };

  const handleSaveClick = () => {
    if (savedFiltersQuota) {
      if (upgradeRequired) {
        setIsPlanModalOpen(true);
        return;
      }
    }

    setIsModalOpen(true);
    setFormToShow('save');
  };

  return (
    <>
      <Billing.Modal
        titleText='You have reached your maximum amount of saved filters.'
        open={isPlanModalOpen}
        setIsOpen={setIsPlanModalOpen}
      />

      <DashboardFiltersSaveLoadModal
        onCloseModal={() => setIsModalOpen(false)}
        open={isModalOpen}
        formToShow={formToShow}
      />
      <div className='flex items-center mt-2 mx-1 justify-between'>
        <div className='flex flex-row'>
          <button
            onClick={(evt) => {
              evt.preventDefault();
              if (handleAddFilterGroup) handleAddFilterGroup();
            }}
            className={`flex mr-2 ${
              dark ? 'text-anti-flash' : 'text-first'
            } items-center focus:outline-none ${
              myFilters.length < 10 && !disable ? 'visible' : 'invisible'
            }`}
            disabled={disable}
          >
            <MdAdd size={16} className='mr-1' /> Add Filter Group
          </button>
          {myFilters.length > filtersDisabled.length && (
            <button
              onClick={(evt) => {
                evt.preventDefault();
                showDeleteConfirm();
              }}
              className={`flex ${
                dark ? 'text-anti-flash' : 'text-first'
              } items-center focus:outline-none ${disable ? 'invisible' : ''}`}
              disabled={disable}
            >
              <span
                className='material-symbols-outlined mr-1'
                style={{ fontSize: 16 }}
              >
                delete
              </span>
              Reset
            </button>
          )}
        </div>
        {showSaveLoad && (
          <div className='flex flex-row'>
            {hasPermissionToCreateFilterConfiguration &&
              filtersGroups &&
              savedFiltersQuota &&
              filtersGroups?.length > 0 && (
                <>
                  <List.QuotaIndicator
                    quota={savedFiltersQuota}
                    tooltipText={`You are currently using ${Number(
                      savedFiltersQuota.current
                    )} out of your ${Number(
                      savedFiltersQuota.max
                    )} allocated saved filters.`}
                    dark={dark}
                  />
                  <Button
                    data-testid='add-filter-group'
                    onClick={() => handleSaveClick()}
                    className={` ml-4 ${
                      dark ? 'text-anti-flash' : ''
                    } flex items-center focus:outline-none ${
                      myFilters.length < 10 ? 'visible' : 'invisible'
                    }`}
                    disabled={isSavedFiltersQuotaLoading}
                  >
                    <MdSave size={16} className='mr-1' />
                    <div className='flex flex-row'>
                      Save Filter
                      {upgradeRequired && (
                        <Tooltip title='Upgrade Required'>
                          <MdWarning
                            style={{ color: '#FFE25E' }}
                            size={16}
                            className='ml-2 self-center'
                          />
                        </Tooltip>
                      )}
                    </div>
                  </Button>
                </>
              )}
            {hasPermissionToDeleteFilterConfiguration && (
              <Button
                className={` ml-4 ${
                  dark ? 'text-anti-flash' : ''
                } flex items-center focus:outline-none ${
                  myFilters.length < 10 ? 'visible' : 'invisible'
                }`}
                onClick={() => {
                  setIsModalOpen(true);
                  setFormToShow('load');
                }}
                disabled={disable}
              >
                <MdUpload size={16} className='mr-1' /> Load/Delete Filter
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardFiltersGroupsFooter;
