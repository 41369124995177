import { IntegrationClass } from '@/utils/models/Integration';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import { Button, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form';
import DeleteButton from './IntegrationModalFooter/DeleteButton';
import SubmitButton from './IntegrationModalFooter/SubmitButton';
import { IntegrationModalTabType } from './IntegrationModal';

export type IntegrationFooterButtonProps = {
  isLoading?: boolean;
  onClick?: () => void;
  isVisible?: boolean;
  disabled?: boolean;
};

const IntegrationModalFooter = ({
  myIntegrationData,
  integrationAvailable,
  formInstance,
  page,
  onDelete,
  onClose,
}: {
  integrationAvailable?: IntegrationAvailableClass;
  myIntegrationData?: IntegrationClass;
  formInstance: FormInstance<any>;
  page: IntegrationModalTabType;
  onDelete: (
    myIntegrationID: string,
    myIntegrationData: IntegrationClass
  ) => void;
  onClose: () => void;
}) => {
  return (
    <div className='flex justify-between my-2'>
      <div className='flex gap-4 items-center'>
        <DeleteButton
          onDelete={onDelete}
          myIntegrationData={myIntegrationData}
          page={page}
        />

        {myIntegrationData && (
          <Tooltip title={myIntegrationData.created}>
            <span className='whitespace-nowrap text-sm opacity-80'>
              Created {myIntegrationData.createdFromNow}
            </span>
          </Tooltip>
        )}
      </div>

      <div className='w-full text-right '>
        <Button data-testid='close-button' onClick={onClose}>
          Close
        </Button>

        <SubmitButton
          integrationAvailable={integrationAvailable}
          page={page}
          formInstance={formInstance}
          myIntegrationData={myIntegrationData}
        />
      </div>
    </div>
  );
};

export default IntegrationModalFooter;
