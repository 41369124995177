import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import Item from '../List/Item';
import { useHistory, useParams } from 'react-router-dom';
import {
  useAddSlackIntegrationMutation,
  useLazyGetSlackCallbackParamsQuery,
} from '@/utils/services/integration/slack-integration-endpoints';
import {
  INTEGRATION_GITHUB_SETUP_COMPLETE,
  INTEGRATION_SLACK_SETUP_COMPLETE,
  INTEGRATION_SLACK_SETUP_ERROR,
} from '@/utils/constants';
import { Spin, message } from 'antd';

const IntegrationItemCard = ({
  integrationAvailable,
  showCategoryBadge = false,
  onClick,
  isUpgradeRequired,
}: {
  integrationAvailable: IntegrationAvailableClass;
  showCategoryBadge?: boolean;
  onClick: ({
    integrationAvailableID,
    myIntegrationID,
  }: {
    integrationAvailableID: string;
    myIntegrationID?: string;
  }) => void;
  isUpgradeRequired: boolean;
}) => {
  const history = useHistory();
  const { orgID } = useParams<{ orgID: string }>();

  const [getSlackCallbackUri, { isLoading: isGettingSlackCallback }] =
    useLazyGetSlackCallbackParamsQuery();

  const [addSlackIntegration, { isLoading: isAddingSlackIntegration }] =
    useAddSlackIntegrationMutation();

  const handleSelect = async () => {
    if (!isUpgradeRequired && integrationAvailable.custom_action_button) {
      let openURL = integrationAvailable.custom_action_button.openURL;

      if (integrationAvailable.custom_action_button.connectSlack) {
        const { callback_uri } = await getSlackCallbackUri({ orgID }).unwrap();
        openURL = callback_uri;
      }

      const newWindow = window.open(
        openURL,
        '_blank',
        'location=yes,height=720,width=1020,scrollbars=yes,status=yes'
      );

      if (integrationAvailable.custom_action_button.connectSlack) {
        // This is a workaround to test the window.open function
        // We can't stub window.open directly, so we need to return an object
        // with the cypressMessageData property. This way we can check if the
        // window.open function was called and if the cypressMessageData property
        // is present in the returned object.
        if (newWindow && 'cypressMessageData' in newWindow) {
          const evt: MessageEvent<any> = new MessageEvent('message', {
            data: newWindow.cypressMessageData,
          });
          handleWindowMessages(evt, null);
          return;
        }
      }

      window.addEventListener(
        'message',
        (event) => handleWindowMessages(event, newWindow),
        false
      );
    } else
      onClick({
        integrationAvailableID: integrationAvailable.UUID,
      });
  };

  const handleWindowMessages = async (
    event: MessageEvent<any>,
    newWindow?: Window | null
  ) => {
    if (event.source === newWindow) {
      newWindow?.close();

      if (event.data.key === INTEGRATION_SLACK_SETUP_COMPLETE) {
        const code = event.data.message;

        if (code) {
          const slackIntegration = await addSlackIntegration({
            orgID,
            code,
          }).unwrap();

          onClick({
            integrationAvailableID: integrationAvailable.UUID,
            myIntegrationID: slackIntegration.UUID,
          });
        }
      }

      if (event.data.key === INTEGRATION_SLACK_SETUP_ERROR) {
        message.error(event.data.message.error_description);
      }

      if (event.data.key === INTEGRATION_GITHUB_SETUP_COMPLETE) {
        const { code, installID } = JSON.parse(event.data.message);
        history.push(
          `/organisations/${orgID}/github-integration?code=${code}&installation_id=${installID}`
        );
      }
    }
  };

  const isLoading = isAddingSlackIntegration || isGettingSlackCallback;

  return (
    <Item.Root
      onClick={
        !integrationAvailable.isDisabled ? () => handleSelect() : undefined
      }
      id={integrationAvailable.UUID}
      disabled={integrationAvailable.isDisabled}
      badge={showCategoryBadge ? integrationAvailable.category : undefined}
      isUpgradeRequired={isUpgradeRequired}
    >
      <Item.Header>
        <Item.Title>{integrationAvailable.name}</Item.Title>
        <Item.Description>{integrationAvailable.description}</Item.Description>
      </Item.Header>

      <Item.Footer>
        <Spin spinning={isLoading}>
          <Item.Button
            label={integrationAvailable.custom_action_button?.text || 'Set Up'}
            isSlackButton={
              integrationAvailable.custom_action_button?.connectSlack
            }
            isVisible={!integrationAvailable.isDisabled}
            isUpgradeRequired={isUpgradeRequired}
          />
        </Spin>
        <Item.Logo
          logoUrl={integrationAvailable?.logoURL}
          name={integrationAvailable.name}
        />
      </Item.Footer>
    </Item.Root>
  );
};

export default IntegrationItemCard;
