import InputTextField from './InputTextField';
import MultiInputTextField from './MultiInputTextField';
import TextAreaField from './TextAreaField';
import InputNumberField from './InputNumberField';
import ToggleField from './ToggleField';
import ButtonField from './ButtonField';
import LabelField from './LabelField';
import CodeBlock from './CodeBlock';
import CodeEditorField from './CodeEditorField';
import MapField from './MapField';
import CreateSecretToken from './CreateSecretToken';
import TabsField from './TabsField';
import StepByStepField from './StepByStepField';
import HeaderField from './HeaderField';
import UploadFileField from './UploadFileField';
import SelectField from './SelectField';
import RadioGroupField from './RadioGroupField';
import DetailedRadioGroupField from './DetailedRadioGroup';
import ConnectSlackField from './ConnectSlackField';
import AutoCompleteField from './AutoCompleteField';
import SwitchField from './SwitchField';
import ConsentField from './ConsentField';
import AuthenticateGitLabField from './AuthenticateGitLabField';
import InputSecondsField from './InputSecondsField';

const Fields = {
  AutoComplete: AutoCompleteField,
  InputText: InputTextField,
  MultiInputText: MultiInputTextField,
  TextArea: TextAreaField,
  InputNumber: InputNumberField,
  Toggle: ToggleField,
  Switch: SwitchField,
  Button: ButtonField,
  Label: LabelField,
  CodeBlock: CodeBlock,
  CodeEditor: CodeEditorField,
  Map: MapField,
  CreateSecretToken: CreateSecretToken,
  Tabs: TabsField,
  StepByStep: StepByStepField,
  Header: HeaderField,
  UploadFile: UploadFileField,
  SelectField: SelectField,
  RadioGroup: RadioGroupField,
  DeatiledRadioGroup: DetailedRadioGroupField,
  ConnectSlack: ConnectSlackField,
  Consent: ConsentField,
  AuthenticateGitLab: AuthenticateGitLabField,
  InputSeconds: InputSecondsField,
};

export default Fields;
