import FieldType from '@/utils/models/FieldType';
import { FormInstance, Input } from 'antd';
import Form from 'antd/lib/form';

/* eslint-disable no-template-curly-in-string */
const AuthenticateGitLabField = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance: FormInstance<any>;
}) => {
  return (
    <Form.Item name={field.attribute} hidden>
      <Input />
    </Form.Item>
  );
};

export default AuthenticateGitLabField;
