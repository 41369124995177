import { Form, Button, Modal, Input, message, Spin, Empty } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { selectDashboardFiltersGroups } from './dashboardFiltersGroupsSlice';
import { useState } from 'react';
import { filterSetAll } from './dashboardFiltersSlice';
import { FilterClass } from '@/utils/models/Filter';
import {
  useAddFilterConfigMutation,
  useDeleteFilterConfigMutation,
  useListFilterConfigQuery,
} from '@/utils/services/filters/filters-configurations-endpoints';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DashboardFiltersGroupsConditionalsRows from './DashboardFiltersGroups/DashboardFiltersGroupsConditionalsRows';
import { useDebounceValue } from 'usehooks-ts';
import useAuth from '@/utils/lib/firetail-auth/hooks/useAuth';

interface DashboardFiltersSaveLoadModalProps {
  onCloseModal: () => void;
  open: boolean;
  formToShow?: 'save' | 'load';
}

const DashboardFiltersSaveLoadModal = ({
  onCloseModal,
  open,
  formToShow,
}: DashboardFiltersSaveLoadModalProps) => {
  let { orgID } = useParams<{ orgID: string }>();
  const [filterName, setFilterName] = useState('');
  const [userFilter, setUserFilter] = useState<'self' | 'all'>('self');
  const defaultSearchValue = '';
  const [debouncedValue, setSearchValue] = useDebounceValue(
    defaultSearchValue,
    500
  );
  const filtersGroups = useAppSelector(selectDashboardFiltersGroups);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [addFilter, { isLoading: isAdding }] = useAddFilterConfigMutation();
  const [deleteFilter, { isLoading: isDeleting }] =
    useDeleteFilterConfigMutation();
  const { user: profile } = useAuth();
  const {
    data: listedFilters,
    isLoading: isListing,
    isFetching: isListingFetching,
  } = useListFilterConfigQuery({
    orgID,
    data: {
      search_value: debouncedValue,
      filters:
        userFilter === 'self' && profile?.email
          ? [{ field: 'createdBy', values: [profile?.email] }]
          : [],
    },
  });
  const [selectedFilter, setSelectedFilter] = useState<FilterClass | null>(
    null
  );

  const handleCloseModal = () => {
    form.resetFields();
    setFilterName('');
    setSelectedFilter(null);
    onCloseModal();
  };

  const handleSaveFilter = () => {
    addFilter({
      data: {
        name: filterName,
        filters: filtersGroups || [],
        resource: 'log',
      },
      orgID,
    });
    message.success('Filter configuration successfully created.');
    handleCloseModal();
  };

  const showDeleteConfirm = (configuration: FilterClass) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this filter configuration?',
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: `Name: ${configuration.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteFilter({
            orgID,
            filterID: configuration.UUID,
          });
          message.success('Filter configuration successfully deleted.');
        } catch (err) {}
        return Promise.resolve();
      },
    });
  };

  const manuallyEdited = filtersGroups
    ? filtersGroups.map((filter: any) => ({
        ...filter,
        disabled: true,
      }))
    : [];

  const saveState = (
    <>
      <span>Filter Configuration:</span>
      <div className='pointer-events-none'>
        <DashboardFiltersGroupsConditionalsRows
          manuallyAddedFilters={manuallyEdited}
        />
      </div>
      <Form
        layout='vertical'
        form={form}
        name='filters-config-form'
        onFinish={handleSaveFilter}
        scrollToFirstError
        className='mt-4'
      >
        <Form.Item
          label='Filter name'
          name={'name'}
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input onChange={(e) => setFilterName(e.target.value)} />
        </Form.Item>
      </Form>
    </>
  );

  const loadState = (
    <>
      <div className='sticky top-0 start-0 z-50 bg-white w-full pt-2 pb-2 flex flex-row justify-between'>
        <Input
          placeholder='Search'
          className='w-1/4'
          defaultValue={defaultSearchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <div>
          <Button
            className={`mr-2 rounded-md ${
              userFilter === 'self'
                ? 'disabled:pointer-events-none disabled:border-gray-100 disabled:bg-gray-200 disabled:cursor-none'
                : ''
            }`}
            disabled={userFilter === 'self'}
            onClick={() => setUserFilter('self')}
          >
            My Filters
          </Button>
          <Button
            className={`rounded-md ${
              userFilter === 'all'
                ? 'disabled:pointer-events-none disabled:border-gray-100 disabled:bg-gray-200 disabled:cursor-none'
                : ''
            }`}
            disabled={userFilter === 'all'}
            onClick={() => setUserFilter('all')}
          >
            All Filters
          </Button>
        </div>
      </div>
      <Spin spinning={isAdding || isDeleting || isListing || isListingFetching}>
        {listedFilters && listedFilters.length > 0 ? (
          listedFilters
            .filter((filter: FilterClass) => filter.resource === 'log')
            .map((filter: FilterClass) => {
              return (
                <div
                  key={filter.UUID}
                  onClick={() => setSelectedFilter(filter)}
                  className='cursor-pointer'
                >
                  <div
                    className={`mb-2 mt-4 px-2 py-1 border rounded-md  ${
                      selectedFilter?.UUID === filter.UUID
                        ? ' bg-gray-50 shadow-md border-gray-400'
                        : ''
                    } pointer-events-none`}
                  >
                    <div className='mt-2 mb-2 ml-1'>
                      <span className='font-semibold text-xl'>
                        {filter.name}
                      </span>
                    </div>
                    <DashboardFiltersGroupsConditionalsRows
                      manuallyAddedFilters={filter.filters.map((filter) => ({
                        ...filter,
                        disabled: true,
                      }))}
                    />
                    <div className='mt-2 mb-2 ml-1 flex flex-col'>
                      <div className='flex flex-row'>
                        <span className='font-semibold mr-1'>Created: </span>
                        <span>{filter.createdFromNow}</span>
                      </div>

                      <div className='flex flex-row'>
                        <span className='font-semibold mr-1'>Created by:</span>
                        <span>{filter.createdBy}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No data</span>}
          />
        )}
      </Spin>
    </>
  );

  return (
    <Modal
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      style={{ top: 50 }}
      width={'906px'}
      bodyStyle={{
        height: '60vh',
        overflow: 'scroll',
        paddingTop: formToShow === 'load' ? 0 : '',
      }}
      open={open}
      onCancel={handleCloseModal}
      forceRender
      title={
        <div className='flex items-center'>
          <span className='pl-2 text-first'>
            {formToShow === 'save' ? 'Save' : 'Load/Delete'} Filter
            Configuration
          </span>
        </div>
      }
      footer={
        <div className='flex justify-between h-8'>
          <div>
            {formToShow === 'load' && (
              <Button
                onClick={
                  selectedFilter
                    ? () => showDeleteConfirm(selectedFilter)
                    : () => {}
                }
                danger
                disabled={!selectedFilter}
              >
                Delete
              </Button>
            )}
          </div>
          <div>
            {formToShow === 'save' && (
              <Button
                onClick={() => {
                  form.submit();
                }}
                disabled={isAdding || filtersGroups?.length === 0}
                type='primary'
              >
                Save
              </Button>
            )}
            {formToShow === 'load' && (
              <Button
                onClick={() => {
                  dispatch(filterSetAll(selectedFilter?.filtersGroups || []));
                  handleCloseModal();
                }}
                type='primary'
                disabled={!selectedFilter}
              >
                Load
              </Button>
            )}
          </div>
        </div>
      }
    >
      {formToShow === 'save' && saveState}
      {formToShow === 'load' && loadState}
    </Modal>
  );
};

export default DashboardFiltersSaveLoadModal;
