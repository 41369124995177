export default function downloadJSON(data: any, filename = 'data.json') {
  if (!data) throw new Error('Data is required to download');

  const jsonString = typeof data === 'object' ? JSON.stringify(data) : data;

  const blob = new Blob([jsonString], { type: 'application/json' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}
