import { Form, Radio } from 'antd';
import { Rule } from 'antd/lib/form';
import FieldType from '@/utils/models/FieldType';
import { getColSize, getFieldSize } from './const/functions';
import { ExperimentOutlined } from '@ant-design/icons';

/* eslint-disable no-template-curly-in-string */
const RadioGroupField = ({ field }: { field: FieldType }) => {
  const rules: Rule[] = [];

  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: '${label} is required',
    });
  }

  const options = field.values || [];

  const handleValueChange = (e: any) => {
    if (field.onValueChange) {
      field.onValueChange(e.target.value);
    }
  };

  return (
    <div className={getColSize(field.colSize || 'extralarge')}>
      <Form.Item
        key={field.attribute}
        label={field.name}
        tooltip={field.description}
        name={field.attribute}
        rules={rules}
        className={getFieldSize(field.fieldSize || 'middle')}
      >
        <Radio.Group
          disabled={field.disabled}
          onChange={handleValueChange}
          className='w-full'
        >
          <div
            className='grid gap-1'
            style={{
              gridTemplateColumns: `repeat(${
                field.columns || 1
              }, minmax(0, 1fr))`,
            }}
          >
            {options.map((opt, index: number) => (
              <Radio
                key={index.toString()}
                disabled={opt.disabled}
                value={opt.value}
                id={opt.value}
                className='border rounded-md p-2 m-0 text-sm'
              >
                <span className='flex items-center'>
                  {opt.label}
                  {opt.disabled && (
                    <ExperimentOutlined
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                      className='ml-2'
                    />
                  )}
                </span>
                <span
                  className={`text-xs ${
                    opt.disabled ? 'text-gray-300' : 'text-gray-500'
                  }`}
                >
                  {opt.description}
                </span>
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default RadioGroupField;
