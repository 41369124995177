import './integrations.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import List from '@/components/List';
import {
  useListIntegrationsAvailableQuery,
  useListMyIntegrationsQuery,
} from '@/utils/services/integration/integration-endpoints';
import { IntegrationAvailableClass } from '@/utils/models/IntegrationAvailableInterface';
import IntegrationModal from '@/components/Integration/IntegrationModal';
import IntegrationItemCard from '@/components/Integration/IntegrationItemCard';
import { useGetIntegrationsQuotasQuery } from '@/utils/services/quota/quota-endpoints';
import useCategoryFilterQueryParam from '@/utils/hooks/QueryParam/useCategoryFilterQueryParam';
import { PAGINATION } from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import useSearchQueryParam from '@/utils/hooks/QueryParam/useSearchQueryParam';

const Available = () => {
  const history = useHistory();
  const { orgID } = useParams<{ orgID: string }>();
  const location = useLocation();
  const {
    data: availableIntegrationsData,
    isLoading: isListingIntegrationsAvailable,
  } = useListIntegrationsAvailableQuery({
    orgID,
  });
  const { data: integrationsQuota } = useGetIntegrationsQuotasQuery({
    orgID,
  });

  const { category } = useCategoryFilterQueryParam();
  const { query } = useSearchQueryParam();

  // Needed to be able to mock the data when creating a new integration
  useListMyIntegrationsQuery({
    orgID,
    pageSize: PAGINATION.pageSize,
    marker: PAGINATION.marker,
  });

  const handleClick = ({
    integrationAvailableID,
    myIntegrationID,
  }: {
    integrationAvailableID: string;
    myIntegrationID?: string;
  }) => {
    if (myIntegrationID) {
      history.replace({
        ...location,
        pathname: `/organisations/${orgID}/integrations/existing/${myIntegrationID}`,
      });
    } else if (integrationAvailableID) {
      history.replace({
        ...location,
        pathname: `/organisations/${orgID}/integrations/available/${integrationAvailableID}`,
      });
    }
  };

  let integrations: IntegrationAvailableClass[] =
    filterAvailableIntegrationsBySearchQuery(query, availableIntegrationsData);

  integrations = filterAvailableIntegrationsByCategory(category, integrations);

  return (
    <>
      <IntegrationModal
        handleSubmit={(myIntegrationID, myIntegrationData) => {
          if (
            IntegrationAvailableClass.isGitLabIntegration(
              myIntegrationData.available?.UUID
            ) ||
            IntegrationAvailableClass.isGitHubIntegration(
              myIntegrationData.available?.UUID
            )
          ) {
            const appUUID = myIntegrationData.inputs?.app_uuid;
            history.push(`/organisations/${orgID}/applications/${appUUID}`);
            return;
          }

          history.push(`/organisations/${orgID}/integrations/existing`);
        }}
      />

      <List.Root>
        <List.Header>
          <List.Search placeholder='Search available integrations' />
          <List.Settings>
            <List.QuotaIndicator
              quota={integrationsQuota}
              tooltipText={
                integrationsQuota?.max === 0
                  ? 'Your current plan does not support integrations.'
                  : `You are currently using ${
                      integrationsQuota?.current as number
                    } out of your ${
                      integrationsQuota?.max as number
                    } allocated integrations.`
              }
            />
          </List.Settings>
        </List.Header>

        <List.CategoryFilter dataSource={availableIntegrationsData || []} />

        <List.Pagination dataSource={integrations}>
          <List.Grid
            item={{
              render: (integration: IntegrationAvailableClass) => (
                <IntegrationItemCard
                  key={integration.UUID}
                  integrationAvailable={integration}
                  showCategoryBadge
                  onClick={handleClick}
                  isUpgradeRequired={integration.plan_upgrade_required}
                />
              ),
            }}
            loading={isListingIntegrationsAvailable}
            ignoreMode
          />
        </List.Pagination>
      </List.Root>
    </>
  );
};

const filterAvailableIntegrationsBySearchQuery = (
  searchQuery: string,
  integrations?: IntegrationAvailableClass[]
): IntegrationAvailableClass[] => {
  if (!integrations) return [];

  const sq = searchQuery.toLowerCase();
  return integrations.filter((integration) => {
    const title = integration.name.toLowerCase();
    const description = integration?.description.toLowerCase();
    return title?.includes(sq) || description?.includes(sq);
  });
};

const filterAvailableIntegrationsByCategory = (
  category: string,
  integrations?: IntegrationAvailableClass[]
): IntegrationAvailableClass[] => {
  if (!integrations) return [];

  if (category === 'all') return integrations;

  return integrations.filter(
    (integration) =>
      integration.category.toLocaleLowerCase() === category.toLocaleLowerCase()
  );
};

export default Available;
