import useAlertDecisionItems from '@/utils/hooks/useAlertDecisionItems';
import { AlertClass, AlertType } from '@/utils/models/Alert';
import { ArrowLeftOutlined } from '@ant-design/icons';

const AlertHeader = ({
  alert,
  handleClose,
  forceType,
}: {
  alert?: AlertClass;
  handleClose: () => void;
  forceType?: AlertType;
}) => {
  const alertType = forceType || alert?.type;

  const alertDecisionItem = useAlertDecisionItems({
    key: alertType,
    iconSize: 22,
  })[0];

  return (
    <div
      className='w-full fixed top-0 z-50 -mx-6 px-6 flex items-center justify-between bg-white  
      border-b border-gray-300'
    >
      <div className='flex items-center justify-start gap-4 divide-x divide-gray-300'>
        <ArrowLeftOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className='text-xl'
          onClick={handleClose}
        />

        <div className='text-gray-800 flex items-center gap-1 h-12 pl-4'>
          {alert || forceType ? (
            <>
              {alertDecisionItem.icon}
              <span className='text-lg'>{alertDecisionItem.title} Alert</span>
            </>
          ) : (
            <span className='text-lg'>Alert</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertHeader;
