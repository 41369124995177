import FieldType from '@/utils/models/FieldType';
import { FormInstance } from 'antd/lib/form';
import { Form, Select, Tag } from 'antd';
import { getColSize, getFieldSize } from './const/functions';
import { useEffect, useState } from 'react';

const MultiInputTextField = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance: FormInstance;
}) => {
  const [tags, setTags] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const validateInput = (value: string): boolean => {
    if (
      field.validation?.regex &&
      !new RegExp(field.validation.regex).test(value)
    ) {
      setError(field.validation.regex_description || 'Invalid input');
      return false;
    }

    if (field.validation?.maxSize && tags.length >= field.validation.maxSize) {
      setError(`You can only add up to ${field.validation.maxSize} items`);
      return false;
    }

    setError(null);
    return true;
  };

  const handleChange = (newTags: string[]) => {
    if (newTags.every((tag) => validateInput(tag))) {
      setTags(newTags);
      formInstance.setFieldsValue({ [field.attribute]: newTags });
    } else {
      formInstance.setFieldsValue({ [field.attribute]: tags });
    }
  };

  useEffect(() => {
    const formPresetValues = formInstance.getFieldValue(field.attribute);

    if (field.prefilledValues && formPresetValues) {
      setTags(
        [...new Set([...field.prefilledValues, ...formPresetValues])] || []
      );
    }
  }, [formInstance, field]);

  const tagRender = (props: any) => {
    const { label, value, onClose } = props;
    const isRemovable = !field.prefilledValues?.includes(value);
    return (
      <Tag closable={isRemovable} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  return (
    <div className={`${getColSize(field.colSize || 'extralarge')}`}>
      <Form.Item
        key={field.attribute}
        name={field.attribute}
        label={field.name}
        tooltip={field.description}
        className={getFieldSize(field.fieldSize || 'extralarge')}
        validateStatus={error ? 'error' : ''}
        help={error}
      >
        <Select
          mode='tags'
          style={{ width: '100%' }}
          placeholder={field.placeholder}
          maxTagTextLength={field.validation?.max || 256}
          open={false}
          disabled={field.disabled}
          value={tags}
          onChange={handleChange}
          tagRender={tagRender}
        />
      </Form.Item>
    </div>
  );
};

export default MultiInputTextField;
