import { ActionClass } from '@/utils/models/Action';
import {
  useDeleteActionMutation,
  useListActionsQuery,
} from '@/utils/services/actions/actions-endpoints';
import { Button, Modal } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ApisItemCard from '../Apis/ApisItemCard';
import Item from '@/components/List/Item';
import useRole from '@/utils/hooks/useRole';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './QuotasResourceModal.css';
import List from '@/components/List';
import FullScreenLoader from '../Loader/FullScreenLoader';
import usePaginationQueryParam from '@/utils/hooks/QueryParam/usePaginationQueryParam';
import { useEffect } from 'react';

export type resourceName =
  | 'Active APIs'
  | 'Active APPs'
  | 'Active Anomaly Alerts'
  | 'Active Static Alerts'
  | 'integrations'
  | 'Saved filters'
  | 'Active Actions'
  | 'Policies';

const QuotasResourceModal = ({
  showModal,
  handleCloseModal,
  title,
  resource,
}: {
  showModal: boolean;
  title: string;
  handleCloseModal: () => void;
  resource: resourceName;
}) => {
  const { orgID } = useParams<{ orgID: string }>();
  const { hasPermissionToGetActions, hasPermissionToDeleteActions } = useRole();
  const history = useHistory();
  const location = useLocation();
  const { pagination, setPagination } = usePaginationQueryParam();

  // const [resources, setResources] = useState<any>([]);
  const { data: actionsData, isLoading: isActionsListLoading } =
    useListActionsQuery(
      { orgID, fetchApis: true, ...pagination },
      { skip: !showModal }
    );
  const [deleteAction, { isLoading: isActionDeleting }] =
    useDeleteActionMutation();

  useEffect(() => {
    // Reset pagination when modal is closed
    if (!showModal) setPagination();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  // INFO: These are all unused for now (except for actions), but this component might be expanded in the future and these will be needed.
  // const [listApis] = useLazyListApisQuery();
  // const [listApps] = useLazyListApplicationsQuery();
  // const [listFilters] = useLazyListFilterConfigQuery();
  // const [listIntegrations] = useLazyListMyIntegrationsQuery();
  // const [listAlerts] = useLazyListAlertsQuery();
  // const [listPolicies] = useLazyListIncidentPoliciesQuery();

  // useEffect(() => {
  //   if (showModal) {
  //     listActions({ orgID, pagination });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showModal, orgID, pagination]);

  // const filterAlertItems = (alerts: AlertClass[]) => {
  //   if (resource === 'Active Anomaly Alerts') {
  //     return alerts.filter((resource) => {
  //       return resource.config.type === 'anomaly';
  //     });
  //   }

  //   if (resource === 'Active Static Alerts') {
  //     return alerts.filter((resource) => {
  //       return resource.config.type === 'static';
  //     });
  //   }
  // };

  // INFO: Unused for now, but needed when using other resources.
  // eslint-disable-next-line
  // const fetchResourceData = async () => {
  //   try {
  //     let response: any;

  //     switch (resource) {
  //       case 'Active Actions':
  //         // response = await listActions({ orgID }).unwrap();
  //         break;

  //       // INFO: These are all unused for now (except for actions), but this component might be expanded in the future and these will be needed.
  //       case 'Active APIs':
  //         response = (await listApis({ orgID }).unwrap()).apis;
  //         break;
  //       case 'Active APPs':
  //         response = await listApps({ orgID }).unwrap();
  //         break;
  //       case 'Saved filters':
  //         response = await listFilters({ orgID }).unwrap();
  //         break;
  //       case 'integrations':
  //         response = (await listIntegrations({ orgID }).unwrap()).integrations;
  //         break;
  //       case 'Policies':
  //         response = await listPolicies({ orgID }).unwrap();
  //         break;
  //       case 'Active Anomaly Alerts':
  //       case 'Active Static Alerts':
  //         response = await listAlerts({ orgID }).unwrap();
  //         return filterAlertItems(response);
  //       default:
  //         response = [];
  //     }

  //     return response;
  //   } catch (error) {
  //     console.error('Failed to fetch resources: ', error);
  //     return [];
  //   }
  // };

  // INFO: Will need re-enabled when other resources are used.
  // useEffect(() => {
  //   const updateResources = async () => {
  //     setIsLoading(true);
  //     const fetchedResources = await fetchResourceData();
  //     setResources(fetchedResources || []);
  //     setIsLoading(false);
  //   };

  //   if (shouldRefresh) {
  //     setIsLoading(true);
  //     const timer = setTimeout(() => {
  //       updateResources().then(() => setShouldRefresh(false));
  //     }, 3000);

  //     return () => clearTimeout(timer);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [resource, shouldRefresh]);

  const handleClose = () => {
    handleCloseModal();
  };

  const handleDeleteAction = (action: ActionClass) => {
    Modal.confirm({
      title: `Are you sure you want to delete this action?`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: `Name: ${action?.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await deleteAction({ orgID, actionID: action.UUID }).unwrap();
      },
    });
  };

  const handleActionClicked = (action: ActionClass) => {
    if (hasPermissionToGetActions) {
      history.push({
        ...location,
        pathname: `/organisations/${orgID}/applications/${action.action_appUUID}/apis/${action.action_apiUUID}/actions/${action.UUID}`,
      });
      handleCloseModal();
    }
  };

  const renderItem = (item: any) => {
    switch (resource) {
      case 'Active Actions':
        return (
          <Item.Root
            onClick={
              hasPermissionToGetActions
                ? () => handleActionClicked(item)
                : undefined
            }
            key={item.key}
            id={item.key}
            badge={item.category}
          >
            <Item.Header>
              <Item.Title>{item.name}</Item.Title>
            </Item.Header>
            <Item.LogoAndName logo={item.api?.avatar} name={item.api?.name} />
            <Item.Footer>
              <Item.Date
                created={item.created}
                createdFromNow={item.createdFromNow}
              />
              {hasPermissionToDeleteActions && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteAction(item);
                  }}
                  danger
                >
                  Delete
                </Button>
              )}
            </Item.Footer>
          </Item.Root>
        );

      // INFO: These are all unused but might be needed later.
      case 'Active APIs':
        return <ApisItemCard key={item.key} api={item} />;
      case 'Active APPs':
      case 'integrations':
      case 'Policies':
      case 'Active Anomaly Alerts':
      case 'Active Static Alerts':
      case 'Saved filters':
        return <div key={item.key}>{item.name}</div>;
      default:
        return <div>No items found.</div>;
    }
  };

  const isComponentLoading = isActionDeleting || isActionsListLoading;

  return (
    <>
      <FullScreenLoader loading={isComponentLoading} />

      <Modal
        maskStyle={{ backdropFilter: 'blur(5px)' }}
        title={title}
        open={showModal}
        width={'80%'}
        onCancel={handleClose}
        forceRender
        footer={<div style={{ visibility: 'hidden' }}></div>}
        style={{ overflow: 'auto' }}
        wrapClassName='customResourceModal'
        bodyStyle={{
          padding: 0,
          height: '70vh',
          overflow: 'auto',
          marginBottom: '40px',
        }}
      >
        <div className='bg-gray-100 py-4 px-4 h-full overflow-auto'>
          <List.Pagination
            dataSource={actionsData?.action}
            useURI
            total={actionsData?.total}
            staticPosition
          >
            <List.Grid
              item={{ render: renderItem }}
              loading={isComponentLoading}
            />
          </List.Pagination>
        </div>
      </Modal>
    </>
  );
};

export default QuotasResourceModal;
