import { IntegrationClass } from '@/utils/models/Integration';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { IntegrationFooterButtonProps } from '../IntegrationModalFooter';
import { IntegrationModalTabType } from '../IntegrationModal';
import { useParams } from 'react-router-dom';
import { useDeleteMyIntegrationMutation } from '@/utils/services/integration/integration-endpoints';
import useRole from '@/utils/hooks/useRole';

const DeleteButton = ({
  page,
  myIntegrationData,
  onDelete,
}: {
  page: IntegrationModalTabType;
  myIntegrationData?: IntegrationClass;
  onDelete: (
    myIntegrationID: string,
    myIntegrationData: IntegrationClass
  ) => void;
}) => {
  const { orgID } = useParams<{ orgID: string }>();

  const { hasPermissionToDeleteIntegration } = useRole();

  const [deleteIntegration, { isLoading: deleteIntegrationIsLoading }] =
    useDeleteMyIntegrationMutation();

  const showDeleteConfirm = (integration: IntegrationClass) => {
    Modal.confirm({
      title: `Are you sure you want to delete this integration?`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: `Name: ${integration.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteIntegration({
            orgID,
            myIntegrationID: integration.UUID,
          });

          onDelete(integration.UUID, integration);
        } catch (err) {}
        return Promise.resolve();
      },
    });
  };

  const deleteButton: IntegrationFooterButtonProps = {
    isVisible: page === 'edit',
    onClick: () => myIntegrationData && showDeleteConfirm(myIntegrationData),
    disabled: !hasPermissionToDeleteIntegration,
  };

  if (deleteButton.isVisible) {
    return (
      <Button
        data-testid='integration-delete-button'
        disabled={deleteButton.disabled}
        onClick={deleteButton.onClick}
        danger
        loading={deleteIntegrationIsLoading}
      >
        Delete
      </Button>
    );
  }

  return <></>;
};

export default DeleteButton;
