import FieldType from '@/utils/models/FieldType';
import { Dropdown, Form, InputNumber, Switch } from 'antd';
import { FormInstance } from 'rc-field-form';
import { Rule } from 'antd/lib/form';
import { useEffect, useMemo, useState } from 'react';
import { MdOutlineExpandMore } from 'react-icons/md';
import { getFieldSize } from './const/functions';

type PeriodUnitType = 'minutes' | 'hours' | 'days';

type SecondsLimitsType = { min?: number; max?: number };

const InputSecondsField = ({
  formInstance,
  inputField,
  switchField,
  isReadOnly,
}: {
  formInstance: FormInstance<any>;
  inputField: FieldType;
  switchField?: FieldType;
  isReadOnly?: boolean;
}) => {
  const inputFieldValue: number | undefined = Form.useWatch(
    inputField.attribute,
    formInstance
  );

  const switchValue: boolean | undefined = Form.useWatch(
    switchField?.attribute,
    formInstance
  );

  const [validateStatus, setValidateStatus] = useState<{
    isError: boolean;
    helpMessage: string;
  }>();

  const key = Array.isArray(inputField.attribute)
    ? inputField.attribute.join('_')
    : inputField.attribute;

  const inMinutes = inputField.value ? inputField.value / 60 : 0;
  let unit: PeriodUnitType = 'minutes';

  let inHours: number | null = null;
  if (inMinutes && inMinutes > 60) {
    inHours = inMinutes / 60;
    unit = 'hours';
  }

  let inDays: number | null = null;
  if (inHours && inHours > 24) {
    inDays = inHours / 24;
    unit = 'days';
  }

  const [periodValue, setPeriodValue] = useState<number | undefined>(
    inDays || inHours || inMinutes
  );
  const [periodUnit, setPeriodUnit] = useState<PeriodUnitType>(unit);

  const rangeLimits: SecondsLimitsType = useMemo(() => {
    if (switchValue === false) return {};

    const min = inputField.validation?.min;
    const max = inputField.validation?.max;

    return {
      min,
      max,
    };
  }, [inputField.validation, switchValue]);

  const periodLimits = useMemo(() => {
    const seconds = rangeLimits;

    const minutes: SecondsLimitsType = {};
    const hours: SecondsLimitsType = {};
    const days: SecondsLimitsType = {};

    if (rangeLimits.min !== undefined) {
      minutes.min = Math.ceil(rangeLimits.min / 60);
      hours.min = Math.ceil(rangeLimits.min / 60 / 60);
      days.min = Math.ceil(rangeLimits.min / 60 / 60 / 24);
    }

    if (rangeLimits.max !== undefined) {
      minutes.max = Math.floor(rangeLimits.max / 60);
      hours.max = Math.floor(rangeLimits.max / 60 / 60);
      days.max = Math.floor(rangeLimits.max / 60 / 60 / 24);
    }

    let limits = seconds;
    if (periodUnit === 'minutes') limits = minutes;
    if (periodUnit === 'hours') limits = hours;
    if (periodUnit === 'days') limits = days;

    return limits;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodUnit, rangeLimits]);

  useEffect(() => {
    let periodTemp = periodValue;

    if (periodValue !== undefined) {
      if (periodUnit === 'minutes') {
        periodTemp = periodValue * 60;
      }

      if (periodUnit === 'hours') {
        periodTemp = periodValue * 60 * 60;
      }

      if (periodUnit === 'days') {
        periodTemp = periodValue * 60 * 60 * 24;
      }
    }

    formInstance.setFieldsValue(
      formatFieldValue(inputField.attribute, periodTemp)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodValue, periodUnit]);

  useEffect(() => {
    if (switchValue === false) {
      setValidateStatus(undefined);
      return;
    }

    formInstance
      .validateFields([inputField.attribute])
      .then(() => setValidateStatus(undefined))
      .catch((errorInfo) => {
        let validateStatusObj = undefined;

        if (
          errorInfo.errorFields.length > 0 &&
          errorInfo.errorFields[0].errors.length > 0
        ) {
          let helpMessage;
          helpMessage = errorInfo.errorFields[0].errors[0];

          if (periodLimits.min !== undefined) {
            helpMessage = helpMessage.replace(
              '{min}',
              periodLimits.min.toString()
            );
          }

          if (periodLimits.max !== undefined) {
            helpMessage = helpMessage.replace(
              '{max}',
              `${periodLimits.max.toString()} ${periodUnit}`
            );
          }

          validateStatusObj = {
            isError: true,
            helpMessage,
          };
        }

        setValidateStatus(validateStatusObj);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputFieldValue, switchValue]);

  const rules: Rule[] = [
    {
      type: 'number',
      min: rangeLimits.min,
      message: 'Must be equal or greater than {min} milliseconds',
    },
    {
      type: 'number',
      max: rangeLimits.max,
      message: 'Must be equal or less than {max}',
    },
  ];

  if (inputField.validation?.required) {
    rules.push({
      required: true,
      message: 'Is required',
    });
  }

  const AddonBefore = () => {
    if (!switchField) return;

    return (
      <Switch
        key={`${key}-switch`}
        size='small'
        defaultChecked={switchValue}
        onChange={(checked) => {
          formInstance.setFieldsValue(
            formatFieldValue(switchField.attribute, checked)
          );
        }}
        disabled={isReadOnly}
      />
    );
  };

  return (
    <>
      <Form.Item
        key={`${key}-form-item`}
        label={inputField.name}
        className={`${getFieldSize(inputField.fieldSize || 'small')}`}
        tooltip={inputField.description}
        validateStatus={validateStatus?.isError ? 'error' : undefined}
        help={validateStatus?.helpMessage}
        required={inputField.validation?.required}
      >
        <InputNumber
          key={`${key}-input-number`}
          style={{ width: '100%' }}
          value={periodValue}
          onChange={(value) =>
            setPeriodValue(value !== null ? value : undefined)
          }
          addonBefore={switchField ? <AddonBefore /> : undefined}
          addonAfter={
            <TimeSelectorDropdown
              key={`${key}-dropdown`}
              selected={periodUnit}
              disabled={switchValue === false || isReadOnly}
              limitMax={rangeLimits.max}
              onClick={(item) => setPeriodUnit(item.key)}
            />
          }
          disabled={switchValue === false || isReadOnly}
          placeholder={periodLimits.min?.toString()}
          required={inputField.validation?.required}
          onPressEnter={(e) => e.preventDefault()}
        />
      </Form.Item>

      <Form.Item rules={rules} hidden name={inputField.attribute}>
        <InputNumber disabled={isReadOnly} />
      </Form.Item>

      {switchField && (
        <Form.Item hidden name={switchField.attribute}>
          <Switch checked={switchField.value} disabled={isReadOnly} />
        </Form.Item>
      )}
    </>
  );
};

const formatFieldValue = (attribute: string | string[], value: any): any => {
  if (Array.isArray(attribute)) {
    const [first, ...rest] = attribute;

    if (rest.length === 0) {
      return {
        [first]: value,
      };
    }

    return {
      [first]: formatFieldValue(rest, value),
    };
  }

  return {
    [attribute]: value,
  };
};

const TimeSelectorDropdown = ({
  disabled,
  selected,
  onClick,
  limitMax,
}: {
  disabled?: boolean;
  selected: string;
  onClick: (item: any) => void;
  limitMax?: number;
}) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

  let menuItems = [];
  if (limitMax !== undefined) {
    if (limitMax > 60) menuItems.push({ key: 'minutes', label: 'minutes' });
    if (limitMax > 3600) menuItems.push({ key: 'hours', label: 'hours' });
    if (limitMax > 86400) menuItems.push({ key: 'days', label: 'days' });
  } else {
    menuItems = [
      { key: 'minutes', label: 'minutes' },
      { key: 'hours', label: 'hours' },
      { key: 'days', label: 'days' },
    ];
  }

  const menu = {
    items: menuItems.filter((item) => item.key !== selected),
    onClick,
  };

  return (
    <Dropdown
      menu={{
        ...menu,
        onClick: (item) => {
          setDropdownIsOpen(false);
          menu.onClick(item);
        },
      }}
      trigger={['click']}
      onOpenChange={(isOpen) => setDropdownIsOpen(isOpen)}
      disabled={disabled}
    >
      <button
        onClick={(e) => e.preventDefault()}
        className={`focus:outline-none ${disabled ? 'opacity-40' : ''}`}
        disabled={disabled}
      >
        <div className={`text-first flex gap-x-2 justify-between items-center`}>
          <span>{selected}</span>

          <MdOutlineExpandMore
            className={`text-first transition-all focus:outline-none ${
              dropdownIsOpen ? '-rotate-180' : ''
            }`}
            size={20}
          />
        </div>
      </button>
    </Dropdown>
  );
};

export default InputSecondsField;
