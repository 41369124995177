import { FC, useState } from 'react';
import { Form, Input, Space, Tooltip } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import FieldType from '@/utils/models/FieldType';
import { getFieldSize } from './const/functions';
import AutoCompleteField from './AutoCompleteField';

interface ListItem {
  key: string;
  value: string;
}

interface MapFieldProps {
  field: FieldType;
  formInstance: FormInstance;
}

const MapField: FC<MapFieldProps> = ({ field, formInstance }) => {
  const [isEmptyError, setIsEmptyError] = useState<boolean>(false);

  const isDisabled = (key: string): boolean => {
    return field.prefilledValues?.some((item) => item === key) || false;
  };

  const validateUniqueKeyValuePair = async (
    value: string,
    name: number
  ): Promise<void> => {
    try {
      if (!value) {
        throw new Error('Key and value are required');
      }

      const list = formInstance.getFieldValue(field.attribute) as ListItem[];
      const currentKey = formInstance.getFieldValue([
        field.attribute,
        name,
        'key',
      ]);
      const currentValue = formInstance.getFieldValue([
        field.attribute,
        name,
        'value',
      ]);

      const exactMatch = list.filter(
        (item, index) =>
          item.key === currentKey &&
          item.value === currentValue &&
          index !== name
      );

      if (exactMatch.length > 0) {
        throw new Error('Key and value pair must be unique');
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.startsWith('Cannot read properties of undefined')) {
          return Promise.resolve();
        }
        return Promise.reject(error);
      } else {
        return Promise.reject(new Error('Unknown error occurred'));
      }
    }
  };

  const handleRemove = (name: number) => {
    const currentValues = formInstance.getFieldsValue(true);
    formInstance.resetFields();
    formInstance.setFieldsValue(currentValues);
    formInstance.validateFields([field.attribute]);
  };

  let defaultListValues;
  if (field?.validation?.min && field.validation.min > 0) {
    defaultListValues = [];
    for (let i = 0; i < field.validation.min; i++) {
      defaultListValues.push({ key: '', value: '' });
    }
  }

  return (
    <Form.Item
      key={field.attribute}
      label={field.name}
      tooltip={field.description}
      className={`w-full ${getFieldSize(field.fieldSize || 'middle')}`}
    >
      <Form.List name={field.attribute} initialValue={defaultListValues}>
        {(fields, { add, remove }) => {
          let showRemoveButton = true;
          if (field?.validation?.min && field.validation.min > 0) {
            showRemoveButton = fields.length > field.validation.min;
          }

          return (
            <>
              {fields.map(({ key, name, ...restField }) => {
                const keyValue = formInstance.getFieldValue([
                  field.attribute,
                  name,
                  'key',
                ]);
                const isItemDisabled = isDisabled(keyValue);

                return (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align='baseline'
                  >
                    <Tooltip
                      title={
                        isItemDisabled
                          ? 'This key is pre-filled by the application selected.'
                          : ''
                      }
                    >
                      {field.autocomplete ? (
                        <div style={{ marginBottom: 0, width: '170px' }}>
                          <AutoCompleteField
                            field={{
                              attribute: [name, 'key'],
                              sourceFrom: { resource: 'api-tag-keys' },
                              value_attr: field.key_attr,
                              fieldSize: 'extralarge',
                              colSize: 'extralarge',
                              placeholder: 'Key',
                              rules: [
                                {
                                  pattern: /^\S*$/,
                                  message: 'Key does not match pattern',
                                },
                                {
                                  validator: async (_: any, value: any) =>
                                    validateUniqueKeyValuePair(value, name),
                                },
                              ],
                            }}
                          />
                        </div>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, 'key']}
                          rules={[
                            {
                              pattern: /^\S*$/,
                              message: 'Key does not match pattern',
                            },
                            {
                              validator: async (_, value) =>
                                validateUniqueKeyValuePair(value, name),
                            },
                          ]}
                          style={{ marginBottom: 0, width: '170px' }}
                        >
                          <Input
                            placeholder='Key'
                            disabled={isItemDisabled}
                            className={`bg-white ${isItemDisabled ? 'cursor-not-allowed text-black' : ''}`}
                            style={{ color: 'black' }}
                            value={isItemDisabled ? keyValue : undefined}
                          />
                        </Form.Item>
                      )}
                    </Tooltip>

                    <Tooltip
                      title={
                        isItemDisabled
                          ? 'This value is set from the application settings.'
                          : ''
                      }
                    >
                      {field.autocomplete ? (
                        <div style={{ marginBottom: 0, width: '170px' }}>
                          <AutoCompleteField
                            field={{
                              attribute: [name, 'value'],
                              sourceFrom: { resource: 'api-tag-values' },
                              value_attr: field.value_attr,
                              fieldSize: 'extralarge',
                              colSize: 'extralarge',
                              placeholder: 'Value',
                              rules: [
                                {
                                  pattern: /^\S*$/,
                                  message: 'Value does not match pattern',
                                },
                                {
                                  validator: async (_: any, value: any) =>
                                    validateUniqueKeyValuePair(value, name),
                                },
                              ],
                            }}
                          />
                        </div>
                      ) : (
                        <Form.Item
                          {...restField}
                          name={[name, 'value']}
                          rules={[
                            {
                              pattern: /^\S*$/,
                              message: 'Value does not match pattern',
                            },
                            {
                              validator: async (_, value) =>
                                validateUniqueKeyValuePair(value, name),
                            },
                          ]}
                          style={{ marginBottom: 4, width: '170px' }}
                        >
                          <Input
                            placeholder='Value'
                            disabled={isItemDisabled}
                            style={{ color: 'black' }}
                            className={`bg-white mb-1 ${isItemDisabled ? 'cursor-not-allowed' : ''}`}
                            value={
                              isItemDisabled
                                ? formInstance.getFieldValue([
                                    field.attribute,
                                    name,
                                    'value',
                                  ])
                                : undefined
                            }
                          />
                        </Form.Item>
                      )}
                    </Tooltip>

                    {!isItemDisabled && showRemoveButton && (
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(name);
                          handleRemove(name);
                        }}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      />
                    )}
                  </Space>
                );
              })}

              <Form.Item>
                {fields.length !== field.validation?.max && (
                  <>
                    {isEmptyError && (
                      <div className='text-red-600 mt-1 mb-2 self-center'>
                        At least one key/value pair is required.
                      </div>
                    )}

                    <button
                      className={`w-24 h-8 px-3 py-2 rounded-sm border border-gray-800 justify-center items-center gap-2 inline-flex cursor-pointer`}
                      disabled={field.disabled}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEmptyError(false);
                        add();
                      }}
                    >
                      <div className='justify-end items-start gap-1 flex'>
                        <PlusOutlined
                          onPointerEnterCapture={undefined}
                          onPointerLeaveCapture={undefined}
                        />
                        <div className='text-right text-sm font-normal leading-none tracking-tight'>
                          {`Add ${field.addString || 'key'}`}
                        </div>
                      </div>
                    </button>
                  </>
                )}
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

export default MapField;
