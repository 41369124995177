import { getLogoPathByName } from '../constants/listOfImagesPaths';
import { FindingsOverviewResponse } from '../services/finding/finding-endpoints';
import { ApplicationClass } from './Application';
import BaseEntityClass from './BaseEntity';
import { SeveritiesFindingType } from './Finding';
import { RequestTagInterface } from './RequestTag';

type apiResourceType =
  | 'aws'
  | 'aws_api_gateway'
  | 'aws_lb_application'
  | 'aws_lb_network'
  | 'gcloud_api_gateway_service';

type ftIntegration = {
  account_id: string;
  api_gw_version: string;
  api_id: string;
  arn: string;
  discovered_endpoints: string[];
  ep_types?: string[];
  integration_uuid: string;
  missing_access_logging?: string[];
  name: string;
  region: string;
  resource_type: apiResourceType;
  source: string;
  stages?: any;
  tags?: object;
  authenticationType?: string;
  schema_hash?: string;
  publicIPAddresses?: string[];
  identifier?: string;
  dateCreated?: string;
  platformVersion?: string;
  publicNetworkAccess?: string;
  resourceGroup?: string;
  subscriptionID?: string;
  type?: string;
  defaultHostname?: string;
  projectNumber?: string;
  etag?: string;
  apiType?: string;
  apiManagementName?: string;
  apiManagementID?: string;
  apiManagementRegionName?: string;
  description?: string;
  apiPath?: string;
  protocols?: string[];
  serviceUrl?: string;
  displayName?: string;
  itemType?: string;
  scan_source?: string;
  platform?: string;
  repository_identifier?: string;
  repository_last_analysed_at?: string;
  scanned_via_wiz?: boolean;
};

type Metadata = {
  html_url?: string;
  visibility?: string;
};

export interface ApiInterface {
  UUID: string;
  api_appUUID: string;
  api_orgUUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  itemType: string;
  name: string;
  region_name?: string;
  api_endpoints?: string[];
  owners?: string[];
  customer_tags?: { k: string; v: string }[];
  status: string;
  g_apiUUID?: string;
  g_appUUID?: string;
  g_orgUUID?: string;
  api_type: string;
  ft_integration?: ftIntegration;
  metadata?: Metadata;
  observedTags?: string[];
}

export type ApiExtraDataType = {
  severities?: Partial<FindingsOverviewResponse>;
  application?: ApplicationClass;
  requestTags?: RequestTagInterface[];
};

export class ApiClass extends BaseEntityClass implements ApiInterface {
  UUID: string;
  api_appUUID: string;
  api_orgUUID: string;
  createdBy: string;
  dateAddedInMicroSeconds: number;
  itemType: string;
  name: string;
  region_name?: string;
  api_endpoints?: string[];
  owners?: string[];
  customer_tags?: { k: string; v: string }[];
  status: string;
  g_apiUUID?: string;
  g_appUUID?: string;
  g_orgUUID?: string;
  api_type: string;
  ft_integration?: ftIntegration;
  metadata?: Metadata;
  observedTags?: string[];

  originalData?: ApiInterface;
  severities: SeveritiesFindingType;
  application?: ApplicationClass;
  requestTags?: RequestTagInterface[];

  constructor(data: ApiInterface, extraData?: ApiExtraDataType) {
    super({
      UUID: data.UUID,
      dateAddedInMicroSeconds: data.dateAddedInMicroSeconds,
    });

    this.originalData = data;
    this.UUID = data.UUID;
    this.key = data.UUID;
    this.api_appUUID = data.api_appUUID;
    this.api_orgUUID = data.api_orgUUID;
    this.createdBy = data.createdBy;
    this.dateAddedInMicroSeconds = data.dateAddedInMicroSeconds;
    this.itemType = data.itemType;
    this.name = data.name;
    this.region_name = data.region_name;
    this.api_endpoints = data.api_endpoints;
    this.owners = data.owners;
    this.customer_tags = data.customer_tags;
    this.status = data.status;
    this.g_apiUUID = data.g_apiUUID;
    this.g_appUUID = data.g_appUUID;
    this.g_orgUUID = data.g_orgUUID;
    this.api_type = data.api_type;
    this.ft_integration = data.ft_integration;
    this.metadata = data.metadata;
    this.observedTags = data.observedTags;
    this.application = extraData?.application;
    this.requestTags = extraData?.requestTags;
    this.severities = {
      CRITICAL: extraData?.severities?.critical,
      HIGH: extraData?.severities?.high,
      MEDIUM: extraData?.severities?.medium,
      LOW: extraData?.severities?.low,
      INFORMATIONAL: extraData?.severities?.information,
    };
  }

  get mapDataToDownload() {
    return {
      ID: this.UUID,
      Name: this.name,
      'Date Added in Microseconds': this.dateAddedInMicroSeconds,
      'Creation Time': this.created,
      'API Type': this.type,
      'API Type Label': this.typeLabel,
      Status: this.status,
      'Created By': this.createdBy,
      'Region Name': this.region_name,
      'Organization ID': this.api_orgUUID,
      'Application ID': this.api_appUUID,
      'Integration Data': this.ft_integration,
    };
  }

  get type(): string {
    return this?.ft_integration?.resource_type || this.api_type || 'firetail';
  }

  get avatar(): string {
    return getLogoPathByName(
      this.ft_integration?.platform?.toLowerCase() === 'github'
        ? 'github_repository'
        : this.ft_integration?.platform?.toLowerCase() === 'gitlab'
          ? 'gitlab_repository'
          : this.type
    );
  }

  static getTypeLabel(type: string): string {
    switch (type) {
      case 'aws_api_gateway':
        return 'AWS API Gateway';
      case 'aws':
        return 'AWS';
      case 'library':
      case 'api_log_request':
        return 'FireTail';
      case 'aws_alb_log_request':
      case 'aws_lb_application':
        return 'AWS Application LB';
      case 'aws_lb_network':
        return 'AWS LB Network';
      case 'aws_app_sync':
      case 'appsync_log_request':
        return 'AWS App Sync';
      case 'aws_lambda':
        return 'AWS Lambda';
      case 'github_repository':
        return 'GitHub Repository';
      case 'github_action_upload':
        return 'GitHub Action Upload';
      case 'azure_api_management_service':
        return 'Azure API Management Service';
      case 'azure_api_management_service_api':
      case 'azure_apim_log_request':
        return 'Azure APP Service';
      case 'rpc':
        return 'RPC';
      case 'grpc':
        return 'gRPC';
      case 'websocket':
        return 'WebSocket';
      case 'graphql':
        return 'GraphQL';
      case 'rest':
        return 'Rest';
      case 'gcloud_api_gateway_service':
      case 'gcp_apigw_log_request':
        return 'GCP API Gateway';
      default:
        return type;
    }
  }

  get typeLabel(): string {
    return ApiClass.getTypeLabel(this.type);
  }

  get isMissingAccessLogging(): boolean {
    return (
      this.ft_integration?.missing_access_logging !== undefined &&
      this.ft_integration?.missing_access_logging?.length > 0
    );
  }

  get isAwsResource(): boolean {
    return this.ft_integration?.source === 'aws';
  }

  get isActive(): boolean {
    return this.status === 'active';
  }

  get isLoggingAvailable(): boolean {
    return !!this.ft_integration?.stages;
  }

  get scannedViaWiz(): boolean {
    return (
      this.ft_integration?.scan_source === 'wiz' ||
      this.ft_integration?.scanned_via_wiz === true
    );
  }

  get scannedViaGitlab(): boolean {
    return this.ft_integration?.platform === 'Gitlab';
  }

  get scannedViaGitHub(): boolean {
    return this.ft_integration?.platform === 'GitHub';
  }

  get scannedViaAWS(): boolean {
    return (
      !this.scannedViaWiz &&
      !this.scannedViaGitHub &&
      !this.scannedViaGitlab &&
      this.ft_integration?.source === 'aws'
    );
  }

  get scannedViaGCloud(): boolean {
    return (
      !this.scannedViaWiz &&
      !this.scannedViaGitHub &&
      !this.scannedViaGitlab &&
      this.ft_integration?.source === 'gcloud'
    );
  }

  get scannedViaAzure(): boolean {
    return (
      !this.scannedViaWiz &&
      !this.scannedViaGitHub &&
      !this.scannedViaGitlab &&
      this.ft_integration?.source === 'azure'
    );
  }

  get scanSource() {
    if (this.scannedViaWiz) {
      return {
        logo: getLogoPathByName('wiz'),
        label: 'Wiz',
      };
    }

    if (this.scannedViaGitlab) {
      return {
        logo: getLogoPathByName('gitlab_repository'),
        label: 'GitLab',
      };
    }

    if (this.scannedViaGitHub) {
      return {
        logo: getLogoPathByName('github_repository'),
        label: 'GitHub',
      };
    }

    if (this.scannedViaAWS) {
      return {
        logo: getLogoPathByName('aws'),
        label: 'AWS',
      };
    }

    if (this.scannedViaGCloud) {
      return {
        logo: getLogoPathByName('gcloud'),
        label: 'Google Cloud',
      };
    }

    if (this.scannedViaAzure) {
      return {
        logo: getLogoPathByName('azure'),
        label: 'Azure',
      };
    }

    return {};
  }

  get tagsGroup() {
    const group = new Set<string>();

    this.requestTags?.forEach((t) => {
      if (t.group && t.group_display_name) {
        group.add(
          JSON.stringify({
            display_name: t.group_display_name,
            key: t.group,
          })
        );
      }
    });

    return Array.from(group).map((g) => {
      const { display_name, key } = JSON.parse(g);
      return {
        display_name,
        key,
        tags: this.#getRequestTagsByGroupKey(key),
      };
    });
  }

  #getRequestTagsByGroupKey(groupKey: string) {
    return this.requestTags?.filter((t) => t.group === groupKey) || [];
  }
}
