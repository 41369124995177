import { Button, Modal, Spin } from 'antd';
import {
  useGetApiQuery,
  useDeleteApiMutation,
  ListApisParams,
  useUpdateApiMutation,
} from '@/utils/services/api/api-endpoints';
import Apis from '@/components/Apis';
import { useHistory, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useRole from '@/utils/hooks/useRole';
import { useState } from 'react';
import FormsModal from '@/components/Modal/FormsModal';
import FieldType from '@/utils/models/FieldType';
import LIST_OF_API_TYPES from '@/utils/constants/listOfApiTypes';
import { useGetApplicationQuery } from '@/utils/services/application/application-endpoints';

const Details = () => {
  const { hasPermissionToDeleteApi, hasPermissionToUpdateApi } = useRole();
  const { appID, orgID, apiID } = useParams<{
    orgID: string;
    appID: string;
    apiID: string;
  }>();
  const { data: api, isLoading: apiIsLoading } = useGetApiQuery({
    orgID,
    apiID,
  });
  const history = useHistory();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [deleteApi] = useDeleteApiMutation();
  const [updateApi] = useUpdateApiMutation();
  const { data: application } = useGetApplicationQuery({ orgID, appID });

  const handleDeleteApi = async () => {
    await deleteApi({ orgID, appID, apiID }).unwrap();
    const params = sessionStorage.getItem('PARAMS_LIST');
    let parsed = {} as ListApisParams;
    if (params) {
      parsed = JSON.parse(params) as ListApisParams;

      if (parsed.filters?.some((f) => f.field === 'appUUID')) {
        history.push(`/organisations/${orgID}/applications/${appID}`);
        return;
      }
    }
    history.push(`/organisations/${orgID}/apis`);
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this API?`,
      icon: (
        <ExclamationCircleOutlined
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      ),
      content: `Name: ${api?.name}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return handleDeleteApi();
      },
    });
  };

  const handleSubmit = (data: any) => {
    let dataToSubmit = { ...data };

    if (data.customer_tags && data.customer_tags.length > 0) {
      const tagsKV: { k: string; v: string }[] = [];

      data.customer_tags.forEach((obj: { key: string; value: string }) => {
        tagsKV.push({ k: obj.key, v: obj.value });
      });

      dataToSubmit.customer_tags = tagsKV;
    }

    updateApi({ appID, apiID, orgID, data: dataToSubmit }).unwrap();
    setIsEditOpen(false);
  };

  const editAPIFormFields: FieldType[] = [
    {
      attribute: 'name',
      name: 'API Name',
      placeholder: 'my-api',
      validation: {
        required: true,
        regex: `^[a-zA-Z0-9][a-zA-Z0-9-\\s/]+[a-zA-Z0-9]$`,
        regex_description:
          'The name must be alphanumeric and between 3 and 128 characters.',
      },
      inputType: 'text',
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'api_type',
      name: 'API Type',
      validation: { required: true },
      inputType: 'select',
      values: LIST_OF_API_TYPES.map((t) => ({ name: t.type, value: t.value })),
      fieldSize: 'extralarge',
      colSize: 'extralarge',
    },
    {
      attribute: 'api_endpoints',
      name: 'API Endpoints',
      inputType: 'multitext',
      validation: {
        regex: `^https?://.*`,
        regex_description:
          'Endpoints must be a valid https addresses, starting with https://',
        maxSize: 10,
      },
    },
    {
      attribute: 'owners',
      name: 'Owners',
      inputType: 'multitext',
      validation: { maxSize: 20 },
      prefilledValues: application?.propagate_owners ? application.owners : [],
    },
    {
      attribute: 'customer_tags',
      name: 'Custom Tags',
      inputType: 'map',
      validation: { max: 10 },
      update: true,
      value: {},
      addString: 'Tag',
      prefilledValues: application?.propagate_tags
        ? application.customer_tags?.map((tag) => tag.k)
        : [],
    },
  ];

  const extra = [];
  if (hasPermissionToUpdateApi) {
    extra.push(
      <Button
        key={(extra.length + 1).toString()}
        onClick={() => setIsEditOpen(true)}
      >
        Edit API
      </Button>
    );
  }

  if (hasPermissionToDeleteApi) {
    extra.push(
      <Button
        key={(extra.length + 1).toString()}
        onClick={() => showDeleteConfirm()}
        danger
      >
        Delete API
      </Button>
    );
  }

  return (
    <>
      <Spin spinning={apiIsLoading}>
        <div className='flex flex-col gap-6 bg-white'>
          <FormsModal
            title='Edit API'
            handleCloseModal={() => setIsEditOpen(false)}
            showModal={isEditOpen}
            handleSubmit={handleSubmit}
            fields={editAPIFormFields}
            initValues={{
              name: api?.name,
              api_type: api?.api_type,
              tags: api?.tagsGroup,
              api_endpoints: api?.api_endpoints,
              owners: api?.owners,
              customer_tags: api?.customer_tags,
            }}
          />
          <Apis.Details api={api} extra={extra} />
        </div>
      </Spin>
    </>
  );
};

export default Details;
