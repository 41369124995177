import FieldType from '@/utils/models/FieldType';
import { Rule } from 'antd/lib/form';
import { Form, Input } from 'antd';
import { getColSize, getFieldSize } from './const/functions';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormInstance } from 'antd/es/form';

/* eslint-disable no-template-curly-in-string */
const InputTextField = ({
  field,
  formInstance,
}: {
  field: FieldType;
  formInstance?: FormInstance<any>;
}) => {
  const { myIntegrationID } = useParams<{ myIntegrationID: string }>();
  const isEdit = !!myIntegrationID;

  const [initialValues, setInitialValues] = useState<any>({});

  useEffect(() => {
    if (!isEdit) return;

    // Wait for values to be set
    setTimeout(() => {
      if (!field?.validation?.depends_on_fields || !formInstance) return;

      setInitialValues({
        currentFieldValue: formInstance.getFieldValue(field.attribute),
        dependsFieldValues: formInstance.getFieldsValue(
          field?.validation?.depends_on_fields
        ),
      });
    }, 500);
  }, [field, formInstance, isEdit]);

  const rules: Rule[] = [];
  let isRequired = field?.validation?.required || false;
  if (isEdit && field?.validation?.required_on_update !== undefined) {
    isRequired = field?.validation?.required_on_update;
  }

  rules.push({
    required: isRequired,
    message: '${label} is required',
  });
  if (field?.validation?.regex) {
    rules.push({
      pattern:
        typeof field.validation.regex === 'string'
          ? new RegExp(`${field.validation.regex}`, 'g')
          : field.validation.regex,
      message: field?.validation?.regex_description || '${label} is invalid',
    });
  }
  if (isEdit && field?.validation?.depends_on_fields) {
    rules.push(({ getFieldValue }) => ({
      validator(_, value) {
        const hasDependsFieldValueChanged =
          field?.validation?.depends_on_fields?.some((dependsField: string) => {
            return (
              getFieldValue(dependsField) !==
              initialValues?.dependsFieldValues?.[dependsField]
            );
          });

        if (hasDependsFieldValueChanged && !value) {
          return Promise.reject(new Error(`${field.name} is required`));
        }

        return Promise.resolve();
      },
    }));
  }

  let input = (
    <Input
      type={field.htmlType || 'text'}
      maxLength={field.validation?.max || 128}
      minLength={field.validation?.min || 0}
      placeholder={field.placeholder}
      disabled={field.disabled}
    />
  );

  if (field?.sensitive) {
    input = (
      <Input.Password
        maxLength={field.validation?.max || 128}
        minLength={field.validation?.min || 0}
        placeholder={field.placeholder}
        disabled={field.disabled}
      />
    );
  }

  return (
    <div className={`${getColSize(field.colSize || 'extralarge')}`}>
      <Form.Item
        name={field.attribute}
        label={field.name}
        rules={rules}
        tooltip={field.description}
        className={getFieldSize(field.fieldSize || 'middle')}
        dependencies={field.validation?.depends_on_fields}
      >
        {input}
      </Form.Item>
    </div>
  );
};

export default InputTextField;
