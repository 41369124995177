/* eslint-disable no-template-curly-in-string */
import { Form, Select, FormInstance, Alert, FormRule } from 'antd';
import { useParams } from 'react-router-dom';
import { useListApisQuery } from '@/utils/services/api/api-endpoints';
import FieldType, { SelectFieldMode } from '@/utils/models/FieldType';
import { getColSize, getFieldSize } from './const/functions';
import { useListApplicationsQuery } from '@/utils/services/application/application-endpoints';
import { getRegionFlagIcon } from '@/utils/regionFlags';
import CreateApplicationButton from './CreateApplicationButton';
import CreateIntegrationButton from './CreateIntegrationButton';
import { useEffect, useState } from 'react';
import useFormsVariables, {
  StateVariableType,
} from '@/utils/hooks/useFormsVariables';
import {
  useLazyListMyIntegrationsQuery,
  useListMyIntegrationsQuery,
} from '@/utils/services/integration/integration-endpoints';
import { useListActionEventCodesQuery } from '@/utils/services/actions/actions-endpoints';
import { IntegrationClass } from '@/utils/models/Integration';
import { useListSpecificationsQuery } from '@/utils/services/specification/specification-endpoints';

const SelectField = ({
  field,
  formInstance,
  mode,
}: {
  field: FieldType;
  formInstance: FormInstance<any>;
  mode?: SelectFieldMode;
}) => {
  const { orgID, apiID, appID } = useParams<{
    orgID: string;
    apiID?: string;
    appID?: string;
  }>();
  const { setStateVariables } = useFormsVariables();

  const [validateStatus, setValidateStatus] = useState<{
    isError: boolean;
    message: string;
  }>();

  const fieldValue: string | string[] | undefined = Form.useWatch(
    field.attribute,
    formInstance
  );

  const { data: listApisData } = useListApisQuery(
    {
      orgID,
      filters: field.sourceFrom?.filters,
    },
    { skip: field.sourceFrom?.resource !== 'api' }
  );
  const apis = listApisData?.apis;

  const { data: listAppsData } = useListApplicationsQuery(
    { orgID },
    { skip: field.sourceFrom?.resource !== 'app' }
  );
  const applications = listAppsData?.apps;

  const { data: specData } = useListSpecificationsQuery(
    { orgID, appID: appID || '', apiID, fetchApis: false },
    { skip: field.sourceFrom?.resource !== 'specifications' || !appID }
  );

  const { data: myIntegrationsData } = useListMyIntegrationsQuery(
    { orgID, filters: field.sourceFrom?.filters },
    { skip: field.sourceFrom?.resource !== 'integration' }
  );

  const [listMyIntegrations] = useLazyListMyIntegrationsQuery();

  const [myIntegrationsState, setMyIntegrationsState] = useState<
    IntegrationClass[]
  >([]);

  const { data: actionEventCodes } = useListActionEventCodesQuery(
    { orgID },
    { skip: field.sourceFrom?.resource !== 'action-event-codes' }
  );

  let options: {
    label: JSX.Element | string;
    value: string;
    logoURL?: string;
  }[] = [];

  if (Array.isArray(field.values)) {
    options = field.values.map((option) => ({
      label: option.name || option.value,
      value: option.value,
    }));
  }

  if (field.sourceFrom?.resource === 'api') {
    options =
      apis?.map((api) => ({
        label: api.name,
        value: api.UUID,
        logoURL: api.avatar,
      })) || [];
  }

  if (field.sourceFrom?.resource === 'app') {
    options =
      applications?.map((application) => ({
        label: application.name,
        value: application.UUID,
      })) || [];
  }

  if (field.sourceFrom?.resource === 'integration') {
    options =
      myIntegrationsState?.map((myIntegration) => ({
        label: myIntegration.name,
        value: myIntegration.UUID,
        logoURL: myIntegration.available?.logoURL,
      })) || [];
  }

  if (field.sourceFrom?.resource === 'action-event-codes') {
    options =
      actionEventCodes?.map((eventCode) => ({
        label: eventCode.title,
        value: eventCode.code,
      })) || [];
  }

  if (field.sourceFrom?.resource === 'specifications') {
    options =
      specData?.map((spec) => ({
        label: spec.name,
        value: spec.UUID,
      })) || [];
  }

  const rules: FormRule[] = [];
  if (field?.validation?.required) {
    rules.push({
      required: true,
      message: field.validation.message || '${label} is required',
    });
  }
  if (field?.validation?.min) {
    rules.push({
      type: 'array',
      min: field.validation.min,
      message:
        field.validation.message ||
        '${label} cannot be less than ${min} in length',
    });
  }
  if (field?.validation?.max) {
    rules.push({
      type: 'array',
      max: field.validation.max,
      message:
        field.validation.message ||
        '${label} cannot be greater than ${max} in length',
    });
  }

  const handleStateVariables = (selectedValue?: string) => {
    if (!field.stateVariables) return;

    let selectedItem: any;

    if (selectedValue) {
      if (Array.isArray(field.values)) {
        selectedItem = field.values.find((o) => o.value === selectedValue);
      }

      if (field.sourceFrom?.resource === 'api') {
        selectedItem = apis?.find((api) => api.UUID === selectedValue);
      }

      if (field.sourceFrom?.resource === 'app') {
        selectedItem = applications?.find((app) => app.UUID === selectedValue);
      }
    }

    let stateVariables: StateVariableType[] = [];
    field.stateVariables?.forEach((variable) => {
      let value = selectedItem;

      if (value) {
        const keys = variable.value.split('.');
        for (const key of keys) value = value[key];
      }

      const stateVar = {
        key: variable.key,
        attribute: field.attribute,
        value,
      };

      stateVariables.push(stateVar);
    });

    setStateVariables(stateVariables);
  };

  useEffect(() => {
    handleStateVariables();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (field.sourceFrom?.resource !== 'integration') return;

    if (!myIntegrationsData) return;

    setMyIntegrationsState(myIntegrationsData.integrations);

    if (fieldValue === undefined) {
      setValidateStatus(undefined);
      return;
    }

    if (
      fieldValue === '' ||
      (Array.isArray(fieldValue) && fieldValue.length === 0)
    )
      return;

    const UUIDs = Array.isArray(fieldValue) ? fieldValue : [fieldValue];

    listMyIntegrations(
      {
        orgID,
        UUIDs,
      },
      true
    )
      .unwrap()
      .then((response) => {
        setValidateStatus(undefined);

        if (UUIDs.length !== response.integrations.length) {
          formInstance.setFieldsValue({
            [field.attribute]: Array.isArray(fieldValue)
              ? response.integrations.map((i) => i.UUID)
              : '',
          });

          setValidateStatus({
            isError: true,
            message: `Looks like your integration was deleted. Select or create a new integration to continue.`,
          });
        }

        const updatedIntegrationsData = [
          ...myIntegrationsData.integrations,
          ...response.integrations.filter(
            (integrationResponse) =>
              !myIntegrationsData?.integrations.find(
                (integrationData) =>
                  integrationData.UUID === integrationResponse.UUID
              )
          ),
        ];
        setMyIntegrationsState(updatedIntegrationsData);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgID, fieldValue, myIntegrationsData, field.sourceFrom?.resource]);

  const isRegionAttribute =
    field.attribute === 'region' || field.attribute === 'region_list';

  return (
    <div className={`${getColSize(field.colSize || 'extralarge')}`}>
      {field.messageCard?.isTotalEqual === options.length && (
        <Alert
          className={`${getFieldSize('extralarge')} mb-4`}
          description={
            <div
              dangerouslySetInnerHTML={{
                __html: field.messageCard.message,
              }}
            />
          }
          showIcon
          type={field.messageCard.type}
        />
      )}

      <div className={`flex ${getFieldSize(field.fieldSize || 'middle')}`}>
        <Form.Item
          name={field.attribute}
          label={field.name}
          rules={rules}
          tooltip={field.description}
          style={{ width: '100%' }}
          hidden={field.hidden}
          validateStatus={validateStatus?.isError ? 'error' : undefined}
          help={validateStatus?.message}
        >
          <Select
            mode={field.mode ?? mode}
            showSearch
            allowClear={!field?.validation?.required}
            placeholder={field.placeholder}
            onChange={(value) => {
              if (field.onValueChange) field.onValueChange(value);
              handleStateVariables(value);
            }}
            optionFilterProp='children'
            filterOption={(input, option) => {
              const isInValue = ((option!.value as unknown as string) ?? '')
                .toLowerCase()
                .includes(input.toLowerCase());

              const isInLabel = ((option!.label as unknown as string) ?? '')
                .toLowerCase()
                .includes(input.toLowerCase());

              return isInValue || isInLabel;
            }}
            disabled={field.disabled}
          >
            {options.map((option) => (
              <Select.Option
                label={option.label}
                key={option.value}
                value={option.value}
                id={option.value}
              >
                <div className='flex items-center gap-2'>
                  {isRegionAttribute && getRegionFlagIcon(option.value)}

                  {option.logoURL && (
                    <img
                      className='mr-1'
                      src={option.logoURL}
                      alt={option.label as string}
                      style={{ maxHeight: '20px', maxWidth: '20px' }}
                    />
                  )}

                  {option.label}

                  {isRegionAttribute && (
                    <span className='text-gray-500'>[{option.value}]</span>
                  )}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <CreateApplicationButton field={field} formInstance={formInstance} />

        <CreateIntegrationButton
          field={field}
          formInstance={formInstance}
          mode={mode}
        />
      </div>
    </div>
  );
};

export default SelectField;
